.benefits{
    padding-top: rem(124);
    margin-bottom: rem(132);
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px rem(-20);
        >*{
            width: calc(100% / 4 - 16px);
            margin: 0 8px rem(20);
            display: flex;
            flex-direction: column;
        }
        li{
            span{
                &:first-child{
                    display: block;
                    color: var(--blue);
                    text-align: center;
                    font-size: rem(74);
                    font-family: 'e-UkraineHead', sans-serif;
                    line-height: 150%;
                    font-weight: 500;
                    text-transform: capitalize;
                    margin-bottom: rem(9);
                }
                &:last-child{
                    color: var(--dark);
                    text-align: center;
                    font-size: rem(24);
                    line-height: 150%;
                }
            }
        }
    }

    @media(max-width: 768px){
        padding-top: 35px;
        margin-bottom: 60px;
    
        .benefits__container {
            flex-direction: column;
        }
        
        .benefits__container > * {
            width: 100%;
        }
        .benefits__container li span:first-child {
            font-size: 40px;
            line-height: 40px;
        }
        
        .benefits__container li span:last-child {
            font-size: 20px;
            line-height: 22px;
        }
        
        .benefits__container > * {
            margin: 0;
        }
        
        .benefits__container {
            margin: 0;
            li{
                &:not(:last-child){
                    margin-bottom: 20px;
                }
            }
        }
  
  
    }
}