.manager{
    margin-bottom: rem(90);
    & + .services{
        padding: rem(90) 0;
    }
    &__inner{
        display: flex;
        align-items: center;
        padding: rem(44) rem(52);
        border: 5px solid var(--gold);
        border-radius: rem(16);
        background-color: var(--white);
        .section-arrow {
            background-color: rgba(199, 184, 146);
        }
    }
    &__img{
        position: relative;
        width: rem(318);
        flex-shrink: 0;
        margin-right: rem(30);
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 100%;
        }
    }
    &__title{
        color: var(--blue);
        font-family: 'Cormorant Garamond', serif;
        font-size: rem(36);
        font-weight: 700;
        line-height: rem(80); /* 222.222% */
        text-transform: uppercase;
        margin-bottom: rem(16);
    }
    &__text{
        p{
            color: var(--gray);
            font-size: Max(rem(14),10px);
            font-weight: 400;
            line-height: rem(24);
        }
        &--big{
            p{
                color: var(--blue);
                font-size: rem(24);
                font-weight: 500;
                line-height: 120%;
            }
        }
        span{
            margin-bottom: rem(10);
            display: block;
        }
        .socials{
            margin: 6px 0;
        }
    }
    &__video{
        display: flex;
        align-items: center;
        margin: rem(16) 0 rem(40);
        span{
            color: var(--gray);
            font-size: Max(rem(14),10px);
            font-weight: 700;
            line-height: rem(24);
            margin-right: rem(34);
            display: block;
            text-transform: uppercase;
        }
        .btn{
            text-transform: none;
            min-width: rem(143);
            &:not(:last-child){
                margin-right: rem(16);
            }
        }
    }
    &__button{
        margin-top: rem(20);
        text-transform: none;
    }


    @media(max-width: 768px){
        margin-bottom: 70px;
        & + .services{
            padding: 50px 0;
        }
        .manager__inner {
            padding: 20px 10px;
            border: 3px solid var(--gold);
            flex-direction: column;
        }
        
        .manager__img {
            width: 230px;
            margin-right: 0;
            margin-bottom: 15px;
        }
        
        .manager__title {
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 10px;
        }
        
        .manager__video {
            align-items: flex-start;
            margin: 1rem 0 16px;
            flex-direction: column;
        }
        
        .manager__video span {
            margin-right: 0;
            margin-bottom: 6px;
        }
        
        .manager__video .btn:not(:last-child) {
            margin-right: 0;
            margin-bottom: 10px;
        }
        
        .manager__text{
            .socials {
                justify-content: flex-start;
            }
            &--big p {
                font-size: 16px;
                line-height: 125%;
            } 
        }       
        .manager__content {
            width: 100%;
        }
  
    }
}

