@charset "UTF-8";
/* stylelint-disable */ /* stylelint-disable */
@font-face {
  font-family: "e-Ukraine";
  src: url("../fonts/e-Ukraine-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "e-UkraineHead";
  src: url("../fonts/e-UkraineHead-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "e-UkraineHead";
  src: url("../fonts/e-UkraineHead-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "e-Ukraine";
  src: url("../fonts/e-Ukraine-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "e-Ukraine";
  src: url("../fonts/e-Ukraine-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "e-Ukraine";
  src: url("../fonts/e-Ukraine-Thin.woff2") format("woff2");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}
::-moz-selection {
  background-color: var(--gold);
  color: var(--white);
}

::selection {
  background-color: var(--gold);
  color: var(--white);
}

html {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 75rem;
  padding: 0 15px;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: max(1rem, 12px);
  font-family: "Geologica", sans-serif;
  font-weight: 400;
  color: var(--dark);
  line-height: 150%;
  background-color: #E5E5E5;
  transition: background-color 0.3s ease-in-out;
  will-change: background-color;
}
.page__body.menu-open {
  overflow: hidden;
}

.title-h1:not(:last-child) {
  margin-bottom: 2.5rem;
}
.title-h1 > * {
  color: var(--white);
  font-size: 3.75rem;
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  line-height: 5rem;
  text-transform: uppercase;
}
.title-h1 span {
  color: var(--gold);
}

.font-family {
  font-family: "Geologica", sans-serif;
  font-weight: 300;
}

.title-h3 {
  margin-bottom: 0.75rem;
}
.title-h3 > * {
  color: var(--white);
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 3rem;
  text-transform: uppercase;
}

.title-h4 {
  margin-bottom: 1.5rem;
}
.title-h4 > * {
  color: var(--white);
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.btn {
  min-width: 9.6875rem;
  display: inline-flex;
  outline: 0;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: var(--gold);
  color: var(--white);
  font-size: max(0.875rem, 10px);
  line-height: 1.125rem;
  padding: 0.75rem 1.5rem;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  cursor: pointer;
}
.btn:hover {
  background-color: var(--brown);
  color: var(--white);
}
.btn.btn--fill {
  background-color: transparent;
  border: 1px solid var(--gold);
  color: var(--gold);
}
.btn.btn--fill:hover {
  color: var(--white);
  background-color: var(--gold);
}
.btn:disabled, .btn.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.logo {
  height: 3.1875rem;
  width: 10.625rem;
  display: flex;
  position: relative;
  flex-shrink: 0;
}
.logo > * {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.logo-bg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 46.4%;
  height: 69.4%;
}
.logo-bg > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.input {
  position: relative;
  display: flex;
  flex-direction: column;
}
.input input, .input textarea {
  font-size: max(0.875rem, 12px);
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0.75rem;
  color: var(--light);
  font-weight: 400;
  line-height: 1.5rem;
  border: 1px solid var(--gold);
  background-color: #1A2A43;
}
.input input::placeholder, .input textarea::placeholder {
  color: var(--light) !important;
  opacity: 1;
}
.input--select {
  position: relative;
  display: flex;
}
.input--select > label {
  cursor: pointer;
  position: relative;
}
.input--select.is-open:first-child {
  border-bottom-left-radius: 0;
}
.input--select.is-open .input__inner::after {
  transform: rotate(180deg) translateY(50%);
}
.input--select.is-open .input__dropdown {
  box-shadow: 0px 1px 23px rgba(30, 33, 36, 0.13);
}
.input--select:not(.has-checkbox) .input__dropdown li::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"%3E%3Cpath d="M6 12L10.2426 16.2426L18.7275 7.75732" stroke="%23C7B892" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.input--select:not(.has-checkbox) .input__dropdown li:not(.is-selected):hover {
  background-color: var(--gold);
}
.input--select:not(.has-checkbox) .input__dropdown li.is-selected {
  color: var(--gold);
  cursor: default;
}
.input--select:not(.has-checkbox) .input__dropdown li.is-selected::after {
  opacity: 1;
}
.input__inner {
  position: relative;
  cursor: pointer;
}
.input__inner > * {
  cursor: pointer;
}
.input__inner::after {
  content: "";
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5625rem;
  height: 1.5625rem;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 8L7.5 5L10.5 8' stroke='white' stroke-linecap='square'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1;
}
.input__dropdown {
  display: none;
  position: absolute;
  left: 0;
  bottom: -0.625rem;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  right: 0;
  z-index: 9;
  transform: translateY(100%);
  text-align: left;
  max-height: 12.5rem;
  overflow-y: auto;
  border: 1px solid var(--gold);
  background-color: #1A2A43;
  cursor: pointer;
}
.input__dropdown li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  font-size: max(0.875rem, 10px);
  background-color: #1A2A43;
}
.input__error {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.125rem;
  transform: translateY(100%);
  font-size: max(0.75rem, 10px);
  line-height: 0.875rem;
  color: red;
}
.input label {
  display: block;
  font-size: max(0.875rem, 12px);
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 2px;
}
.input .output_text {
  padding-right: 2.3125rem;
}
.input.is-error:not(:last-child) {
  margin-bottom: 2.1875rem !important;
}
.input.is-error input, .input.is-error textarea {
  border-color: red;
}
.input.input--checkbox {
  width: 100%;
}
.input.input--checkbox input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}
.input.input--checkbox input:checked + span::before {
  opacity: 1;
}
.input.input--checkbox input:checked + span::after {
  opacity: 0;
}
.input.input--checkbox span {
  display: block;
}
.input.input--checkbox span::after, .input.input--checkbox span::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
  transition: opacity 0.3s ease-in-out;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.input.input--checkbox span::after {
  background-image: url(./../img/checkbox_unchecked.svg);
}
.input.input--checkbox span::before {
  background-image: url(./../img/checkbox_check.svg);
  opacity: 0;
}

textarea {
  width: 100% !important;
}

.socials {
  display: flex;
  align-items: center;
}
.socials__title {
  margin-bottom: 1.5rem;
  display: block;
}
.socials li {
  line-height: 0;
}
.socials li:not(:last-child) {
  margin-right: 0.75rem;
}
.socials li a {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.3s ease-in-out;
}
.socials li a > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.socials li a:hover {
  transform: scale(1.1);
}

.burger {
  display: block;
  width: 28px;
  height: 28px;
}
.burger > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.burger > * path {
  stroke: var(--white);
}

.scroll-appearance.animate-opacity {
  opacity: 0;
  transition: opacity 1.8s ease-in-out;
}
.scroll-appearance.animate-opacity.scroll-appearance-shown {
  opacity: 1;
}
.scroll-appearance.animate-top {
  transform: translateY(-90%);
  opacity: 0;
  transition: opacity 1.8s ease-in-out, transform 0.9s ease-in-out;
}
.scroll-appearance.animate-top.scroll-appearance-shown {
  opacity: 1;
  transform: translateY(0);
}
.scroll-appearance.animate-bottom {
  transform: translateY(90%);
  opacity: 0;
  transition: opacity 1.8s ease-in-out, transform 0.9s ease-in-out;
}
.scroll-appearance.animate-bottom.scroll-appearance-shown {
  opacity: 1;
  transform: translateY(0);
}
.scroll-appearance.animate-left {
  transform: translateX(-90%);
  opacity: 0;
  transition: opacity 1.8s ease-in-out, transform 0.8s ease-in-out;
}
.scroll-appearance.animate-left.scroll-appearance-shown {
  opacity: 1;
  transform: translateX(0);
}
.scroll-appearance.animate-right {
  transform: translateX(90%);
  opacity: 0;
  transition: opacity 1.8s ease-in-out, transform 0.8s ease-in-out;
}
.scroll-appearance.animate-right.scroll-appearance-shown {
  opacity: 1;
  transform: translateX(0);
}

.fancybox__container {
  --fancybox-bg: rgba(0, 0, 0, 0.8);
}

.btn-hover {
  position: absolute;
  border-radius: 50%;
  transition: transform 1s;
  transform: scale(0);
  background-color: var(--white-bg);
}

.tag {
  position: absolute;
  left: 0.625rem;
  top: 0.625rem;
  min-width: unset;
  z-index: 1;
  color: var(--white);
  font-size: max(1rem, 10px);
  font-style: normal;
  font-weight: 800;
  pointer-events: none;
}

.nav-links {
  margin: 2.5rem auto 0;
  padding-top: 2.5rem;
  border-top: 1px solid var(--light);
  max-width: 23.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-numbers {
  color: var(--light);
  font-size: max(0.875rem, 10px);
  font-weight: 400;
  line-height: 1.5rem;
  transition: color 0.3s ease-in-out;
}
.page-numbers[href]:hover {
  color: var(--gold);
}
.page-numbers.current {
  color: var(--gold) !important;
}
.page-numbers.next {
  display: flex;
}
.page-numbers.next svg {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
}
.page-numbers.next svg path {
  transition: stroke 0.3s ease-in-out;
}
.page-numbers.next svg:hover path {
  stroke: var(--gold);
}
.page-numbers:not(:last-child) {
  margin-right: 0.625rem;
}

.tooltip {
  position: relative;
  z-index: 1;
  width: 1.25rem;
  height: 1.25rem;
}
.tooltip:hover span {
  opacity: 1;
  visibility: visible;
}
.tooltip span {
  z-index: 10;
  max-width: 15.625rem;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  color: #CDD3DB;
  text-align: center;
  font-size: max(0.875rem, 12px);
  font-weight: 400;
  line-height: 1.3125rem;
  padding: 0.75rem;
  display: block;
  border: 2px solid var(--gold);
  background-color: var(--blue);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  right: 0;
  top: 0;
  z-index: 1;
  transform: translate(100%, -100%);
  width: max-content;
  text-transform: none;
}
.tooltip svg {
  margin-left: 0.5rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 768px) {
  .tooltip span {
    max-width: 177px;
  }
}

.popup {
  padding: 0;
  width: 50%;
}
.popup__inner {
  background-color: #13243D;
  padding: 2.5rem 3.5rem;
  color: var(--white);
}
.popup__title {
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.popup__title:not(:last-child) {
  margin-bottom: 1.25rem;
}
@media (max-width: 768px) {
  .popup {
    width: 100%;
  }
  .popup__inner {
    padding: 24px 12px;
  }
  .popup__title {
    font-size: 17px;
    line-height: 28px;
  }
}

@media (max-width: 768px) {
  .mobile-hide {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .mobile-show {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .title-h1:not(:last-child) {
    margin-bottom: 24px;
  }
  .title-h1 > * {
    font-size: 40px;
    line-height: 48px;
  }
  .title-h2 {
    margin-bottom: 15px;
  }
  .title-h2 > * {
    font-size: 30px;
    line-height: 48px;
  }
  .title-h3 > * {
    font-size: 24px;
    line-height: 35px;
  }
  .title-h4 > * {
    font-size: 20px;
    line-height: 24px;
  }
  .socials {
    justify-content: center;
  }
  .mobile-phone {
    color: #c9cfd7 !important;
  }
  .mobile-phone:link {
    color: #c9cfd7 !important;
  }
}
@media (max-width: 1280px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 1080px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 16px;
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

img, svg {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

/* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */
a, a:link, a:visited {
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

/* Common */
aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

address {
  font-style: normal;
}

ul {
  list-style: none;
}

/* Form */
input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

:root {
  --white: #fff;
  --black: #000;
  --gold: #C7B892;
  --blue: #0D1E37;
  --light: #CDD3DB;
  --gray: #686974;
  --brown: #A9996A;
  --light-opacity: #A1BEDB38;
  --container-margin: calc((100vw - 1170px) / 2);
}

@media (max-width: 1280px) {
  :root {
    --container-margin: calc((100vw - 1020px) / 2);
  }
}
@media (max-width: 1080px) {
  :root {
    --container-margin: calc((100vw - 945px) / 2);
  }
}
@media (max-width: 991px) {
  :root {
    --container-margin: calc((100vw - 720px) / 2);
  }
}
.header {
  position: relative;
  z-index: 999;
  padding: 2.0625rem 0;
  background-color: transparent;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__nav {
  margin-left: 0.625rem;
}
.header__nav-list {
  display: flex;
  align-items: center;
}
.header__nav-list li {
  position: relative;
}
.header__nav-list li:not(:last-child) {
  margin-right: 2rem;
}
.header__nav-list li.active > a {
  color: var(--gold) !important;
}
@media (min-width: 769px) {
  .header__nav-list li:hover > .header__dropdown {
    opacity: 1;
    top: unset;
    bottom: 0;
    visibility: visible;
  }
}
.header__nav-list li > a:not(.header__dropdown a) {
  overflow: hidden;
  position: relative;
  color: var(--white);
  text-transform: uppercase;
}
.header__nav-list li a {
  display: block;
  color: var(--white);
  font-size: max(0.8125rem, 10px);
  font-weight: 400;
  line-height: 1.125rem;
  white-space: nowrap;
  transition: color 0.3s ease-in-out;
}
@media (min-width: 769px) {
  .header__nav-list li a:hover {
    color: var(--gold) !important;
  }
}
.header__nav-list li.has-child {
  padding-bottom: 0.5rem;
  margin-bottom: -0.5rem;
}
.header__nav-list li.has-child > a {
  padding-right: 1.375rem !important;
  cursor: context-menu;
}
.header__nav-list li.has-child > a::after {
  content: "";
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  width: 0.9375rem;
  height: 0.9375rem;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 7L7.5 10L10.5 7' stroke='white' stroke-linecap='square'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
}
@media (min-width: 769px) {
  .header__nav-list li.has-child:hover > a::after {
    transform: rotate(180deg) translateY(50%);
  }
}
.header__dropdown {
  min-width: 8rem;
  text-align: left;
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0.75rem 0;
  border: 0.3125rem solid var(--gold);
  background: #E5E5E5;
  box-shadow: 0.25rem 0.25rem 1rem 0px rgba(46, 85, 146, 0.3);
  top: unset;
  transition: opacity 0.3s ease-in-out, bottom 0.3s ease-in-out, visibility 0.3s ease-in-out;
  bottom: 1.25rem;
  transform: translateY(100%);
}
.header__dropdown.header__dropdown--big {
  min-width: 18.4375rem;
}
.header__dropdown.header__dropdown--big .has-child a::after {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"%3E%3Cpath d="M4.5 7L7.5 10L10.5 7" stroke="%23686974" stroke-linecap="square"/%3E%3C/svg%3E');
}
.header__dropdown.header__dropdown--big .header__dropdown {
  border-top: 0;
  left: -0.3125rem;
  box-shadow: unset;
  padding-left: 1rem;
  padding-top: 0.5rem;
  min-width: 18.4375rem;
}
.header__dropdown.header__dropdown--big .header__dropdown a {
  color: var(--gray);
}
.header__dropdown li {
  padding: 0;
}
.header__dropdown li:not(:last-child) {
  margin-right: 0;
  margin-bottom: 1rem;
}
.header__dropdown li a {
  display: block;
  padding: 0 1rem;
  font-size: max(0.875rem, 10px);
  color: var(--blue);
  color: var(--gray);
  font-weight: 400;
}
@media (min-width: 769px) {
  .header__dropdown li:hover a {
    color: var(--gold);
  }
}
.header__dropdown .has-child a {
  width: max-content;
  position: relative;
  max-width: 100%;
}
.header__btns {
  display: flex;
  margin-left: 1.4375rem;
}
.header .logo {
  flex-shrink: 0;
}
.header.header--black {
  background-color: var(--blue);
}
@media (max-width: 768px) {
  .header__nav-list {
    padding: 0;
  }
  .header__nav-list li.has-child > a {
    padding-right: 10px;
  }
  .header__nav-list li.has-child > a::after {
    display: none;
  }
  .header__nav-list li a {
    color: var(--blue) !important;
  }
  .header__dropdown {
    opacity: 1;
    visibility: visible;
  }
  .header__nav-list li.has-child {
    margin-bottom: 0;
  }
}

.header__nav-list li.active > a::after {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 7L7.5 10L10.5 7' stroke='%23C7B892' stroke-linecap='square'/%3E%3C/svg%3E");
}

.benefits {
  padding-top: 7.75rem;
  margin-bottom: 8.25rem;
}
.benefits__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px -1.25rem;
}
.benefits__container > * {
  width: calc(25% - 16px);
  margin: 0 8px 1.25rem;
  display: flex;
  flex-direction: column;
}
.benefits__container li span:first-child {
  display: block;
  color: var(--blue);
  text-align: center;
  font-size: 4.625rem;
  font-family: "e-UkraineHead", sans-serif;
  line-height: 150%;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0.5625rem;
}
.benefits__container li span:last-child {
  color: var(--dark);
  text-align: center;
  font-size: 1.5rem;
  line-height: 150%;
}
@media (max-width: 768px) {
  .benefits {
    padding-top: 35px;
    margin-bottom: 60px;
  }
  .benefits .benefits__container {
    flex-direction: column;
  }
  .benefits .benefits__container > * {
    width: 100%;
  }
  .benefits .benefits__container li span:first-child {
    font-size: 40px;
    line-height: 40px;
  }
  .benefits .benefits__container li span:last-child {
    font-size: 20px;
    line-height: 22px;
  }
  .benefits .benefits__container > * {
    margin: 0;
  }
  .benefits .benefits__container {
    margin: 0;
  }
  .benefits .benefits__container li:not(:last-child) {
    margin-bottom: 20px;
  }
}

.contact-us {
  padding-bottom: 9rem;
  overflow: hidden;
}
.contact-us__container {
  display: flex;
}
.contact-us__map {
  max-width: 37.5rem;
  width: 100%;
}
.contact-us__map > * {
  height: 100%;
}
.contact-us__inner {
  background-color: #C9D2DB;
  padding: 5rem 1.875rem;
  width: 100%;
}
.contact-us__inner .title-h1 > * {
  color: var(--blue);
}
.contact-us__info {
  border-left: 1px dashed var(--gold);
  padding-left: 1.5rem;
}
.contact-us__block:not(:last-child) {
  margin-bottom: 2.5rem;
}
.contact-us__block span {
  color: var(--gray);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem; /* 120% */
  margin-bottom: 1.5rem;
  display: block;
}
.contact-us__block svg, .contact-us__block img {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-right: 0.75rem;
}
.contact-us__block .socials svg path {
  fill: var(--gray);
}
.contact-us__row {
  display: flex;
  align-items: center;
  color: var(--gray);
  font-size: max(0.875rem, 10px);
  font-weight: 400;
  line-height: 1.5rem;
}
.contact-us__row:not(:last-child) {
  margin-bottom: 0.9375rem;
}
.contact-us__row a {
  color: var(--gray);
}
@media (max-width: 768px) {
  .contact-us {
    padding-bottom: 50px;
  }
  .contact-us__container {
    flex-direction: column-reverse;
  }
  .contact-us__inner {
    padding: 50px 20px;
  }
  .contact-us__inner .socials {
    justify-content: left;
  }
  .contact-us__info {
    padding-left: 12px;
  }
  .contact-us__block span {
    margin-bottom: 12px;
  }
  .contact-us__block:not(:last-child) {
    margin-bottom: 20px;
  }
  .contact-us__map {
    margin-top: 40px;
    max-width: unset;
    height: 450px;
  }
  .contact-us__map > * {
    width: 100%;
  }
}

.category {
  background-color: var(--blue);
  padding-top: 9rem;
  overflow: hidden;
}
.category + .what-is .what-is__about .section-text {
  max-width: 41.25rem;
  margin: 0 auto;
}
.category__container {
  display: flex;
}
.category__wrapper {
  max-width: 41.875rem;
  margin-right: 1.875rem;
  flex-shrink: 0;
}
.category__info .section-text {
  margin-bottom: 3.5rem;
}
.category-card {
  border-radius: 0.375rem;
  background: #1A2A43;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.16);
  padding: 1.5rem;
  position: relative;
  counter-increment: number;
}
.category-card:not(:last-child) {
  margin-bottom: 1.375rem;
}
.category-card__title {
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5625rem;
  margin-bottom: 1rem;
  position: relative;
  padding-left: 2.5rem;
}
.category-card__title::after {
  position: absolute;
  left: 0;
  top: 0;
  content: counter(number);
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 400;
}
@media (max-width: 768px) {
  .category {
    padding-top: 40px;
  }
  .category .category__container {
    flex-direction: column;
  }
  .category .category__wrapper {
    max-width: unset;
    margin-right: 0;
    margin-bottom: 35px;
  }
  .category .category__info .section-text {
    margin-bottom: 30px;
  }
}

.tax {
  padding-top: 9rem;
  background-color: var(--blue);
  padding-bottom: 26.625rem;
  overflow: hidden;
}
.tax-section {
  overflow: hidden;
  padding-top: 9rem;
  padding-bottom: 9rem;
}
.tax__bottom {
  position: relative;
}
.tax__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 5.625rem;
}
.tax__bg > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tax__cards {
  background-color: #E5E5E5;
}
.tax__cards-container {
  top: -15rem;
  position: relative;
  display: flex;
  margin: 0 -15px;
}
.tax__cards-container > * {
  width: calc(50% - 30px);
  margin: 0 15px;
}
.tax-card {
  position: relative;
  padding: 3.75rem 2.5rem;
  background-color: #C9D2DB;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.tax-card__logo {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.tax-card__logo > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.tax-card__title {
  color: var(--blue);
  font-family: "Cormorant Garamond", serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem; /* 120% */
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.tax-card .section-text {
  margin-bottom: 2rem;
}
.tax-card .section-text p {
  color: var(--gray);
}
.tax-card.tax-card--white {
  background-color: var(--white);
}
@media (max-width: 768px) {
  .tax {
    padding-top: 40px;
    padding-bottom: 80px;
  }
  .tax__cards-container {
    padding: 40px 0;
    top: 0;
    margin: 0;
    flex-direction: column;
  }
  .tax__cards-container > * {
    width: 100%;
    margin: 0;
    margin-bottom: 25px;
  }
  .tax-card {
    padding: 20px;
  }
  .tax-card__title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 16px;
  }
  .tax-section {
    padding: 80px 0;
  }
}

.trust {
  background-color: var(--blue);
  padding: 9rem 0;
  overflow: hidden;
}
.trust.trust--color {
  background-color: transparent;
}
.trust.trust--color + .planning {
  padding-top: 0;
}
.trust-card__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -5.625rem;
}
.trust-card__container > * {
  width: calc(50% - 30px);
  margin: 0 15px 5.625rem;
}
.trust-card {
  border: 0.3125rem solid var(--gold);
  background-color: #1A2A43;
  position: relative;
  counter-increment: number;
}
.trust-card::after {
  content: "";
  position: absolute;
  left: 50%;
  top: -3.5rem;
  transform: translateX(-50%);
  width: 7rem;
  height: 7rem;
  border: 0.3125rem solid var(--gold);
  border-radius: 50%;
  background-color: #1A2A43;
  padding-bottom: 1.125rem;
  content: counter(number, decimal-leading-zero);
  color: var(--gold);
  font-family: "Cormorant Garamond", serif;
  font-size: 3.75rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trust-card__info {
  padding: 2.5rem;
}
.trust-card__title {
  margin-bottom: 1.5rem;
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5625rem;
}
.trust-card__img {
  position: relative;
  overflow: hidden;
}
.trust-card__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.trust-card__img::before {
  content: "";
  padding-top: 68.4%;
  display: block;
  width: 100%;
}
.trust-card__img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
}
.trust-card__btns {
  display: flex;
  flex-wrap: wrap;
  margin: 3.5rem -10px -0.625rem;
}
.trust-card__btns > * {
  margin: 0 10px 0.625rem;
}
.trust-answer {
  overflow: hidden;
  position: relative;
  background-color: var(--blue);
  margin: 9rem 0;
  padding: 9rem 0;
  z-index: 1;
}
.trust-answer__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  z-index: -1;
}
.trust-answer__bg > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.trust-answer .section-head:nth-child(even) {
  display: flex;
  flex-direction: row-reverse;
}
.trust-answer .section-head:nth-child(even) .title-h1 {
  margin-right: 0;
  margin-left: 1.875rem;
}
.trust-answer .section-head:not(:last-child) {
  margin-bottom: 9rem;
}
.trust-answer .section-head .title-h1 {
  max-width: 35.625rem;
}
.trust-answer.trust-answer--offset {
  margin: 0;
}
.trust-parties {
  padding-bottom: 9rem;
  overflow: hidden;
}
.trust-parties__title {
  text-align: center;
}
.trust-parties__title > * {
  color: var(--blue);
}
.trust-parties__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.trust-parties__container > * {
  width: calc(33.3333333333% - 30px);
  margin: 0 15px;
}
.trust-parties__card {
  background-color: #C9D2DB;
  padding: 3.75rem 2.5rem;
}
.trust__promo {
  display: flex;
  align-items: flex-start;
}
.trust__promo.trust__promo--color {
  align-items: unset;
}
.trust__promo.trust__promo--color .trust__descr {
  padding: 5rem 2.5rem;
  background-color: #C9D2DB;
}
.trust__promo.trust__promo--color .trust__descr .title-h1 > * {
  color: var(--blue);
}
.trust__promo.trust__promo--color .trust__descr .section-text p {
  color: var(--gray);
}
.trust__promo.trust__promo--color .trust__info {
  margin-left: 0;
}
.trust__promo.trust__promo--offset .trust__descr {
  padding: 3.5rem 1.875rem;
}
.trust__promo.trust__promo--offset .trust__info {
  display: flex;
  flex-direction: column;
}
.trust__promo.trust__promo--offset .trust__img {
  flex-grow: 1;
}
.trust__promo.trust__promo--offset + .trust-card__container {
  margin-top: 9rem;
}
.trust__info {
  max-width: 35.625rem;
  flex-shrink: 0;
  margin-left: 1.875rem;
  position: relative;
  background-color: #1A2A43;
  padding: 3.5rem 2.5rem;
  text-align: center;
}
.trust__info-text {
  color: var(--white);
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: "Crimson Pro", serif;
  font-weight: 300;
}
.trust__info-text > *:not(:last-child) {
  margin-bottom: 1.5rem;
}
.trust__info-text span {
  color: var(--gold);
}
.trust__img {
  position: relative;
  max-width: 26.875rem;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  margin: 0 auto 1.5rem;
  border: 0.3125rem solid #0D1E37;
  border-bottom: 0.3125rem solid #B8CEE2;
  overflow: hidden;
}
.trust__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.trust__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 86%;
}
.trust__img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.42);
  pointer-events: none;
}
.trust__img.trust__img--big::before {
  padding-top: 107%;
}
.trust__descr .btn {
  margin-top: 3.5rem;
}
.trust__box {
  width: 27.3125rem;
  padding: 4rem 3.875rem 4rem 2.5rem;
  border: 0.3125rem solid #0D1E37;
  background: rgba(255, 255, 255, 0.8);
  border-bottom: 0.3125rem solid #B8CEE2;
  flex-shrink: 0;
  z-index: 1;
}
.trust-main {
  position: relative;
  display: flex;
  align-items: flex-start;
  background-color: #C9D2DB;
  padding-top: 5rem;
  padding-left: 1.875rem;
}
.trust-main.trust-main--reverse {
  flex-direction: row-reverse;
  padding-left: 0;
  padding-right: 1.875rem;
}
.trust-main.trust-main--reverse .trust-main__block {
  margin-right: 0;
  margin-left: 1.875rem;
}
.trust-main.trust-main--reverse .trust-main__bg {
  left: 0;
  right: unset;
}
.trust-main.trust-main--reverse .trust__box {
  padding: 2.8125rem 2.5rem;
}
.trust-main__list {
  display: flex;
  padding-left: 3.375rem;
  margin-top: -5rem;
  margin-bottom: 9rem;
}
.trust-main__list .category-card:not(:last-child) {
  margin-bottom: 0;
}
.trust-main__list .category-card__title {
  padding-left: 0;
}
.trust-main__list .category-card__title::after {
  content: none;
}
.trust-main__list > * {
  max-width: 18.3125rem;
  width: 100%;
}
.trust-main__list > *:not(:last-child) {
  margin-right: 1.875rem;
}
.trust-main__bg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 23.125rem;
}
.trust-main__bg::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.trust-main__bg > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.trust-main__block {
  max-width: 39.6875rem;
  margin-right: 2.1875rem;
  padding-bottom: 7.5rem;
}
.trust-main__block .btn {
  margin-top: 1.875rem;
}
.trust-main .title-h1 > * {
  color: var(--blue);
}
.trust-main .section-text:not(.category-card .section-text) p {
  color: var(--gray);
}
.trust-category {
  margin-bottom: 9rem;
}
.trust-category__card {
  border: 0.3125rem solid var(--gold);
  background-color: #1A2A43;
}
.trust-category__card-inner {
  display: flex;
  align-items: flex-start;
}
.trust-category__card:not(:last-child) {
  margin-bottom: 1.875rem;
}
.trust-category__card-info {
  padding: 2.5rem 0;
}
.trust-category__card-img {
  position: relative;
  margin-right: 1.875rem;
  flex-shrink: 0;
  width: 29rem;
}
.trust-category__card-img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.trust-category__card-img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 83%;
}
.trust-category__card-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}
.trust-category__card-title {
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5625rem;
  margin-bottom: 1.5rem;
}
.trust-category__card .section-text-hide {
  padding: 1.5rem 2.5rem;
}
.trust-category__card .section-text-hide .section-link__close {
  margin: 0 auto;
}
.trust-category__card .section-text-hide > * {
  color: #CDD3DB;
  font-size: max(0.875rem, 10px);
  font-weight: 400;
  line-height: 1.5rem;
}
.trust-category__card .section-text-hide > *:not(:last-child) {
  margin-bottom: 1.875rem;
}
.trust-category__card .section-text {
  margin-bottom: 2.75rem;
}
.trust-category .section-head .title-h1 {
  max-width: 35.625rem;
}
.trust-law {
  margin-bottom: 9rem;
}
.trust-law .trust__info {
  max-width: unset;
  margin-left: 0;
}
.trust-law .trust__img {
  max-width: unset;
}
.trust-law .trust__img::before {
  padding-top: 34.2%;
}
.trust-tax .title-h1 {
  text-align: center;
}
.trust-tax .trust__info {
  max-width: unset;
  margin-left: 0;
}
.trust-tax .trust__info .section-text {
  text-align: left;
}
.trust-tax .trust__img {
  max-width: unset;
}
.trust-tax .trust__img::before {
  padding-top: 34.2%;
}
.trust-overview__container {
  display: flex;
  align-items: center;
  margin-top: -4.375rem;
}
.trust-overview__info .section-text {
  margin-bottom: 3.5rem;
}
.trust-overview .what-is__about {
  padding-top: 5rem;
  padding-bottom: 9.375rem;
}
.trust-overview .what-is__about .section-text-wrap {
  max-width: 41.25rem;
  margin: 0 auto;
}
.trust-planning .planning__img {
  position: relative;
}
.trust-planning .planning__img::before {
  padding-top: 149%;
}
.trust-planning .planning__info {
  width: 50.8%;
}
.trust-planning .planning-list {
  padding: 1.875rem 1.25rem 1.875rem 1.875rem;
  margin-bottom: -10.3125rem;
  max-width: 30rem;
}
.trust-planning .planning-list .section-text {
  padding-left: 0;
}
.trust-planning .planning-list .section-text p {
  color: #CDD3DB;
}
.trust-summary {
  padding-top: 9rem;
  background-color: var(--blue);
}
.trust-summary.trust-summary--similiar {
  margin-bottom: 9rem;
}
.trust-summary.trust-summary--similiar .planning-list {
  margin-bottom: -11.25rem;
}
.trust-summary .planning-list {
  background-color: #1A2A43;
  margin-bottom: -5.625rem;
  margin-left: 0;
  width: 100%;
}
.trust-nexxess {
  overflow: unset;
  padding: 0;
  padding-bottom: 70px;
  position: relative;
}
.trust-nexxess::after {
  content: "";
  position: absolute;
  background-color: var(--light);
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  height: 212px;
}
.trust-nexxess__container {
  display: flex;
  padding: 2.5rem 1.5rem;
  background-color: var(--white);
  position: relative;
  z-index: 1;
}
.trust-nexxess__container > * {
  flex-basis: 0;
  flex-grow: 1;
}
.trust-nexxess__container .card {
  height: auto;
  border-radius: 0;
  padding: 3.125rem 1.5rem 0.9375rem 1rem;
  counter-increment: number;
}
.trust-nexxess__container .card:not(:last-child) {
  border-right: 1px dashed var(--gold);
}
.trust-nexxess__container .card::after {
  left: 1rem;
  content: counter(number, decimal-leading-zero);
}
.trust-nexxess .what-is__about {
  padding: 5rem 0;
}
.trust-nexxess .what-is__about .section-text-wrap {
  max-width: 44.5rem;
  margin: 0 auto;
}
.trust-content {
  display: flex;
}
.trust-content .section-text p {
  color: var(--gray);
}
.trust-content .title-h1 > * {
  color: var(--blue);
}
.trust-content__block {
  margin-right: 1.875rem;
  flex-shrink: 0;
  max-width: 29.375rem;
}
.trust-content__block.trust-content__block-bg {
  margin-right: 1.375rem;
  position: relative;
}
.trust-content__block.trust-content__block-bg::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 22.25rem;
  height: 29rem;
  background-color: var(--blue);
  pointer-events: none;
  z-index: -1;
}
.trust-content__block.trust-content__block-bg .trust-content__img {
  margin-top: 2.6875rem;
  margin-bottom: 4.6875rem;
  margin-left: 1.5rem;
  width: unset;
  border-left: 5px solid var(--gold);
}
.trust-content__block.trust-content__block-bg .trust-content__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 83.4%;
}
.trust-content__block .section-text > :not(:first-child) {
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  border-top: 1px dashed var(--gold);
}
.trust-content__img {
  width: 100%;
  position: relative;
  border: 0.3125rem solid var(--blue);
  margin-bottom: 1.5rem;
}
.trust-content__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.trust-content__img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.trust-content__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 68%;
}
.trust-content__descr {
  position: relative;
}
.trust-content__logo {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.trust-content__logo > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.trust-spendthrift .trust__promo {
  align-items: unset;
}
.trust-spendthrift .trust__img::before {
  padding-top: 101%;
}
.trust-legalities {
  overflow: visible;
  margin-bottom: 25rem;
}
.trust-legalities .trust-main {
  margin-bottom: -33.5rem;
  margin-top: 8rem;
}
@media (max-width: 768px) {
  .trust {
    padding: 50px 0;
  }
  .trust-card__container {
    margin: 0;
    flex-direction: column;
  }
  .trust-card__container > * {
    width: 100%;
    margin: 0;
  }
  .trust-card__container > *:not(:last-child) {
    margin-bottom: 45px;
  }
  .trust-card {
    border-width: 4px;
  }
  .trust-card::after {
    top: -35px;
    width: 60px;
    height: 60px;
    padding-bottom: 10px;
    font-size: 35px;
    border-width: 4px;
  }
  .trust-card__img {
    max-height: 350px;
    overflow: hidden;
  }
  .trust-card__info {
    padding: 20px;
  }
  .trust-card__btns {
    margin-top: 20px;
  }
  .trust-answer {
    margin: 70px 0;
    padding: 60px 0;
  }
  .trust-answer .section-head:nth-child(even) {
    flex-direction: column;
  }
  .trust-answer .section-head:nth-child(even) .title-h1 {
    margin-right: 0;
    margin-left: 20px;
  }
  .trust-answer .section-head:not(:last-child) {
    margin-bottom: 40px;
  }
  .trust-answer .section-head .title-h1 {
    max-width: unset;
  }
  .trust-parties {
    padding-bottom: 60px;
  }
  .trust-parties__container {
    display: flex;
    margin: 0;
  }
  .trust-parties__container > * {
    width: 100%;
    margin: 0;
  }
  .trust-parties__container > *:not(:last-child) {
    margin-bottom: 30px;
  }
  .trust__promo {
    flex-direction: column;
  }
  .trust__promo.trust__promo--color .trust__descr {
    padding: 25px 16px 40px;
  }
  .trust__promo.trust__promo--offset + .trust-card__container {
    margin-top: 100px;
  }
  .trust__descr .btn {
    margin-top: 20px;
  }
  .trust__info {
    max-width: unset;
    margin-left: 0;
    margin-top: 30px;
    padding: 20px;
  }
  .trust__info-text {
    font-size: 17px;
    line-height: 25px;
  }
  .trust__info-text > *:not(:last-child) {
    margin-bottom: 16px;
  }
  .trust-main {
    flex-direction: column;
    padding: 25px 16px 40px;
  }
  .trust-main__block {
    max-width: unset;
    margin-right: 0;
    padding-bottom: 35px;
  }
  .trust-main__list {
    padding-left: 0;
    margin-top: 25px;
    margin-bottom: 40px;
    flex-direction: column;
  }
  .trust-main__list > * {
    width: 100%;
    max-width: unset;
  }
  .trust-main__list > :not(:last-child) {
    margin-right: 0;
  }
  .trust-main__list .category-card:not(:last-child) {
    margin-bottom: 20px;
  }
  .trust-main.trust-main--reverse {
    flex-direction: column;
    padding: 25px 16px 40px;
  }
  .trust-main.trust-main--reverse .trust__box {
    padding: 15px;
    border-width: 3px;
  }
  .trust-main.trust-main--reverse .trust-main__block {
    margin-left: 0;
  }
  .trust__box {
    width: 100%;
    padding: 25px;
  }
  .trust-category {
    margin-bottom: 80px;
  }
  .trust-category__card {
    border-width: 3px;
  }
  .trust-category__card-inner {
    flex-direction: column;
  }
  .trust-category__card-img {
    margin-right: 0;
    width: 100%;
    max-height: 400px;
    overflow: hidden;
  }
  .trust-category__card-info {
    padding: 20px 10px;
  }
  .trust-category__card-info .section-text-hide > *:not(:last-child) {
    margin-bottom: 16px;
  }
  .trust-category__card-title {
    margin-bottom: 14px;
  }
  .trust-category__card .section-text-hide {
    padding: 0 16px 25px;
  }
  .trust-category__card .section-text {
    margin-bottom: 20px;
  }
  .trust-law {
    margin-bottom: 80px;
  }
  .trust-law .trust__img {
    max-width: unset;
  }
  .trust-law .trust__img::before {
    padding-top: 68.2%;
  }
  .trust-tax .trust__img {
    max-width: unset;
  }
  .trust-tax .trust__img::before {
    padding-top: 68.2%;
  }
  .trust-tax .title-h1 {
    text-align: left;
  }
  .trust-tax .title-h1 > * {
    font-size: 30px;
    line-height: 40px;
  }
  .trust-overview__container {
    margin-top: -30px;
    flex-direction: column;
  }
  .trust-overview__container .category__wrapper {
    max-width: unset;
    margin-right: 0;
    margin-bottom: 25px;
    width: 90%;
  }
  .trust-overview__info .section-text {
    margin-bottom: 25px;
  }
  .trust-overview .what-is__about {
    padding-top: 25px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .trust-overview .what-is__about .section-text-wrap {
    max-width: unset;
  }
  .trust-planning .planning__info {
    width: 100%;
  }
  .trust-planning .planning-list {
    padding: 20px;
    margin-bottom: 0;
    max-width: 100%;
  }
  .trust-planning .planning-list .section-text {
    border-left: 0;
  }
  .trust-summary {
    padding: 50px 0;
  }
  .trust-summary .planning-list {
    margin-bottom: 0;
  }
  .trust-summary.trust-summary--similiar {
    margin-bottom: 7.5rem;
  }
  .trust-nexxess {
    padding-bottom: 0;
  }
  .trust-nexxess::after {
    content: none;
  }
  .trust-nexxess__container {
    padding: 0;
    background-color: unset;
    flex-direction: column;
  }
  .trust-nexxess .card {
    border-radius: 5px;
    width: 100%;
    max-width: unset;
  }
  .trust-nexxess .card:not(:last-child) {
    border-right: unset;
    margin-bottom: 20px;
  }
  .trust-nexxess .what-is__about {
    padding: 0;
  }
  .trust-content {
    flex-direction: column-reverse;
  }
  .trust-content__block {
    margin-right: 0;
    max-width: unset;
    margin-bottom: 20px;
  }
  .trust-content__block.trust-content__block-bg {
    margin-right: 0;
    margin-top: 20px;
  }
  .trust-content__block.trust-content__block-bg::after {
    content: none;
  }
  .trust-content__block.trust-content__block-bg .trust-content__img {
    margin-top: 0;
    margin-bottom: 24px;
    margin-left: 0;
  }
  .trust-content__block.trust-content__block-bg .trust-content__img::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 68%;
  }
  .trust.trust--color + .planning {
    padding-top: 50px;
  }
  .trust-legalities {
    margin-bottom: 400px;
  }
  .trust-legalities .trust-main {
    margin-bottom: -536px;
    margin-top: 35px;
  }
}

@media (max-width: 768px) {
  #trust-legalities {
    padding-bottom: 0;
  }
}
.teams {
  padding-bottom: 2.5rem;
  position: relative;
  overflow: hidden;
}
.teams.section-blur {
  margin-top: max(-20vh, -6.75rem);
}
.teams .section-blur__container {
  padding: 2.5rem 0 6.25rem;
}
.teams__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
}
.teams__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.teams__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -4.875rem;
}
.teams__container > * {
  width: calc(50% - 30px);
  margin: 0 15px 4.875rem;
}
.teams__container.teams__container--big {
  margin: 0 -15px -1.875rem;
  justify-content: center;
}
.teams__container.teams__container--big > * {
  width: calc(20% - 30px);
  margin: 0 15px 1.875rem;
}
.teams__container.teams__container--big .teams-card {
  padding-left: 0;
}
.teams__container.teams__container--big .teams-card__img {
  width: 100%;
}
.teams__container.teams__container--big .teams-card__img::before {
  padding-top: 123.9%;
}
.teams__container.teams__container--big .teams-card__img:hover .teams-card__block {
  transform: translateY(-1.5rem) !important;
}
.teams__container.teams__container--big .teams-card__block {
  flex-direction: column;
  text-align: center;
  align-items: center;
  top: unset;
  bottom: 0;
}
.teams__container.teams__container--big .teams-card__block span {
  color: var(--light) !important;
  margin-bottom: 0 !important;
  line-height: normal !important;
}
.teams__container.teams__container--big .teams-card__info {
  margin-bottom: 0.5rem;
}
.teams__container.teams__container--big .teams-card__title {
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--gold) !important;
  margin-bottom: 0.5rem !important;
}
.teams__container.teams__container--big .teams-card__social {
  width: 1.5rem;
  height: 1.5rem;
}
.teams__container.teams__container--big .teams-card::before {
  content: none;
}
.teams-card {
  display: flex;
  align-items: flex-end;
  padding-left: 2rem;
  position: relative;
}
.teams-card.teams-card--hover .teams-card__title {
  margin-bottom: 1.5625rem;
}
.teams-card.teams-card--hover:hover .teams-card__title {
  color: var(--blue);
}
.teams-card.teams-card--hover .teams-card__img:hover {
  transform: translateY(-10px);
}
.teams-card.teams-card--hover .teams-card__img:hover:after {
  top: 0;
  opacity: 1;
  visibility: visible;
}
.teams-card.teams-card--hover .teams-card__img:hover .teams-card__block {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
}
.teams-card:hover .teams-card__title {
  color: var(--gold);
}
.teams-card__img {
  width: 14.875rem;
  position: relative;
  margin-right: 1.875rem;
  flex-shrink: 0;
  overflow: hidden;
  transition: transform 0.3s ease;
}
.teams-card__img img:not(.teams-card__social img) {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.teams-card__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 140.3%;
}
.teams-card__img::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(13, 30, 55, 0) 0%, #0D1E37 100%);
  box-shadow: 4px 6px 12px 0px rgba(13, 30, 55, 0.3);
  transition: opacity 0.3s ease, top 0.3s ease, visibility 0.3s ease;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.teams-card__title {
  color: var(--blue);
  font-family: "Cormorant Garamond", serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.125rem; /* 104.167% */
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  transition: color 0.3s ease-in-out;
}
.teams-card__block {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-20%);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.teams-card__social {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  transition: transform 0.3s ease-in-out;
}
.teams-card__social:hover {
  cursor: pointer;
}
.teams-card__social:not(:last-child) {
  margin-bottom: 0.5rem;
}
.teams-card__social > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.teams-card span {
  display: block;
  margin-bottom: 0.75rem;
  color: var(--blue);
  font-size: max(0.75rem, 10px);
  font-weight: 400;
  line-height: 1.5rem;
}
.teams-card .section-text:not(:last-child) {
  margin-bottom: 2rem;
}
.teams-card .section-text p {
  color: var(--blue);
}
.teams__bottom {
  margin-top: 8rem;
  text-align: center;
}
.teams__bottom .page-numbers {
  color: var(--blue);
}
.teams__bottom .page-numbers svg path {
  stroke: var(--blue);
}
.teams__title {
  margin-bottom: 0.5rem !important;
}
.teams__title > * {
  color: var(--gold);
}
.teams__descr {
  margin-bottom: 2.5rem;
}
.teams__descr > * {
  color: var(--blue);
  font-size: max(0.875rem, 10px);
  font-weight: 400;
  line-height: 1.5rem;
}
.teams__form {
  display: flex;
  align-items: center;
  margin-bottom: 2.125rem;
  margin-top: 0 !important;
  width: 100% !important;
  flex-direction: row !important;
}
.teams__form > *:not(:last-child) {
  margin-right: 1.875rem;
}
.teams__form .btn {
  margin-top: auto;
  border-radius: 0.5rem;
  min-width: 10.625rem;
  text-transform: unset;
  min-height: 3.0625rem;
}
.teams__form .input {
  min-width: 16.875rem;
}
.teams__form .input:not(:last-child) {
  margin-bottom: 0 !important;
}
.teams__form .input input {
  border: 1px solid var(--gold);
  background-color: var(--white);
  color: var(--blue);
}
.teams__form .input input::placeholder {
  color: var(--blue) !important;
  opacity: 1;
}
.teams__form .input input:checked + span {
  color: var(--gold);
}
.teams__form .input .input__error {
  display: none;
}
.teams__form .input > label {
  margin-bottom: 0.5rem;
}
.teams__form .input__inner::after {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"%3E%3Cpath d="M16 10L12 14L8 10" stroke="%230D1E37" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
}
.teams__form .input__dropdown {
  background-color: var(--white);
}
.teams__form .input__dropdown li {
  background-color: var(--white);
  color: var(--blue);
}
.teams__form .input.input--select:nth-child(4) {
  flex-grow: 1;
}
.teams__form .input.input--select.has-checkbox .input__dropdown li {
  padding: 0;
}
.teams__form .input.input--select.has-checkbox span {
  padding: 0.5rem 2.4375rem 0.5rem 1.5rem;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.teams__form .input.input--select.has-checkbox span:hover {
  background-color: var(--gold);
  color: var(--white);
}
.teams__form .input.input--select.has-checkbox input:checked + span:hover {
  background-color: unset;
  color: var(--gold);
}
.teams__form .input.input--checkbox span::after {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"%3E%3Cpath d="M17.5 15.3333V5.66663V5.6639C17.5 4.7323 17.5 4.26621 17.3185 3.91003C17.1587 3.59643 16.9034 3.34144 16.5898 3.18166C16.2333 3 15.7669 3 14.8335 3H5.16683C4.23341 3 3.76635 3 3.40983 3.18166C3.09623 3.34144 2.84144 3.59643 2.68166 3.91003C2.5 4.26655 2.5 4.73321 2.5 5.66663V15.3333C2.5 16.2667 2.5 16.7335 2.68166 17.09C2.84144 17.4036 3.09623 17.6585 3.40983 17.8183C3.76635 18 4.23341 18 5.16683 18H14.8335C15.7669 18 16.2333 18 16.5898 17.8183C16.9034 17.6585 17.1587 17.4036 17.3185 17.09C17.5 16.7339 17.5 16.2676 17.5 15.336V15.3333Z" stroke="%230D1E37" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
}
.teams__form .input.input--checkbox span::before {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"%3E%3Cpath d="M13.3336 8L8.88916 13L6.66667 10.5M17.5 5.66663V15.3333C17.5 16.2667 17.5002 16.7335 17.3185 17.09C17.1587 17.4036 16.9034 17.6585 16.5898 17.8183C16.2333 18 15.7669 18 14.8335 18H5.16683C4.23341 18 3.76635 18 3.40983 17.8183C3.09623 17.6585 2.84144 17.4036 2.68166 17.09C2.5 16.7335 2.5 16.2667 2.5 15.3333V5.66663C2.5 4.73321 2.5 4.26655 2.68166 3.91003C2.84144 3.59643 3.09623 3.34144 3.40983 3.18166C3.76635 3 4.23341 3 5.16683 3H14.8335C15.7669 3 16.2333 3 16.5898 3.18166C16.9034 3.34144 17.1587 3.59643 17.3185 3.91003C17.5002 4.26655 17.5 4.73321 17.5 5.66663Z" stroke="%23C7B892" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
}
.teams__form .input.input--checkbox span::after, .teams__form .input.input--checkbox span::before {
  right: 0.9375rem;
}
.teams__form .input.is-error:not(:last-child) {
  margin-bottom: 0 !important;
}
.teams__input.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.teams__result {
  text-align: center;
}
.teams__result > * {
  font-size: 1.25rem;
  line-height: normal;
  color: var(--gold);
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .teams__container.teams__container--big {
    margin: 0 -15px -1.875rem;
  }
  .teams__container.teams__container--big > * {
    width: calc(25% - 30px);
    margin: 0 15px 1.875rem;
  }
}
@media (max-width: 768px) {
  .teams {
    padding-bottom: 0;
  }
  .teams.section-blur {
    margin-top: -90px !important;
  }
  .teams__container {
    margin: 0;
  }
  .teams__container > * {
    width: 100%;
    margin: 0;
  }
  .teams__container > *:not(:last-child) {
    margin-bottom: 35px;
  }
  .teams__container.teams__container--big {
    margin: 0 -10px -1.25rem;
  }
  .teams__container.teams__container--big > * {
    width: calc(50% - 20px);
    margin: 0 10px 1.25rem;
  }
  .teams__container.teams__container--big > *:not(:last-child) {
    margin-bottom: 20px;
  }
  .teams__container.teams__container--big .teams-card {
    padding: 0;
  }
  .teams__container.teams__container--big .teams-card__img {
    margin-bottom: 0;
  }
  .teams__container.teams__container--big .teams-card__title {
    font-size: 17px;
    line-height: normal;
    margin-bottom: 5px !important;
  }
  .teams__container.teams__container--big .teams-card__info {
    margin-bottom: 14px;
  }
  .teams__container.teams__container--big .teams-card span {
    font-size: 12px;
    line-height: normal;
  }
  .teams__bottom {
    margin-top: 50px;
  }
  .teams-card {
    padding-left: 0;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0.7;
    background: #C9D2DB;
    box-shadow: 4px 6px 12px 0px rgba(13, 30, 55, 0.3);
    padding: 10px;
  }
  .teams-card.teams-card--hover .teams-card__title {
    margin-bottom: 5px;
  }
  .teams-card::before {
    content: none;
    width: 100%;
    height: 425px;
  }
  .teams-card__info {
    width: 100%;
    text-align: center;
  }
  .teams-card__img {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 10px;
    max-height: 350px;
    overflow: hidden;
  }
  .teams-card__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .teams-card .section-text:not(:last-child) {
    margin-bottom: 16px;
  }
  .teams__descr {
    margin-bottom: 20px;
  }
  .teams__form {
    flex-direction: column !important;
  }
  .teams__form .btn {
    margin-left: unset;
    min-width: unset;
    width: 100%;
  }
  .teams__form .input {
    min-width: unset;
    width: 100%;
  }
  .teams__form .input:not(:last-child) {
    margin-bottom: 20px !important;
    margin-right: 0;
  }
}

.career {
  position: relative;
  overflow: hidden;
  background-color: var(--blue);
  padding: 11.5rem 0;
  margin-bottom: 11.5rem;
}
.career__inner {
  padding: 5rem 2.5rem;
  display: flex;
  flex-direction: column;
  background-color: #1A2A43;
  color: var(--white);
}
.career__inner .logo {
  margin: 0 auto 1.5rem;
  width: 14.375rem;
  height: 4.375rem;
}
.career__inner .title-h3 > * {
  font-size: 2.5rem;
}
.career__subtitle {
  margin-bottom: 1.5rem;
  text-align: center;
}
.career__list {
  margin-bottom: 1.25rem;
  font-size: max(0.875rem, 10px);
  font-weight: 400;
  line-height: 1.5rem;
}
.career__block {
  margin-top: 1.25rem;
}
.career__block .title-h4 {
  margin-bottom: 1.25rem;
}
.career__block .title-h4 > * {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5rem;
}
.career__block-item ol {
  margin-left: 1.25rem;
}
.career__block-item ol li:not(:last-child) {
  margin-bottom: 0.5rem;
}
.career__block-item:not(:last-child) {
  margin-bottom: 2.5rem;
}
.career .section-text p {
  color: var(--white);
}
.career__link {
  margin-top: 2.1875rem;
}
.career__link a {
  color: var(--white);
}
.career .swiper-pagination-bullet {
  width: 1.25rem;
  height: 1.25rem;
  opacity: 1;
  background-color: var(--gray);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.career .swiper-pagination-bullet-active {
  background-color: var(--white);
}
.career .swiper-pagination-bullets {
  bottom: 0;
}
.career .swiper {
  padding-bottom: 70px;
}
@media (max-width: 768px) {
  .career {
    padding: 50px 0;
    margin-bottom: 100px;
  }
  .career__inner {
    padding: 30px 15px;
  }
  .career__inner .title-h3 > * {
    font-size: 24px;
  }
  .career .career__block .title-h4 {
    margin-bottom: 14px;
  }
  .career .career__block .title-h4 > * {
    font-size: 20px;
  }
  .career .career__block-item:not(:last-child) {
    margin-bottom: 30px;
  }
}

.tree {
  width: 100%;
  overflow-y: auto;
  padding: 5.625rem;
}
.tree__list > li:first-child::after {
  content: none;
}
.tree__list li {
  position: relative;
  padding-top: 1.875rem;
}
.tree__list li:not(:last-child) {
  margin-right: 2rem;
}
.tree__list li:not(:last-child:first-child):first-child::before {
  content: "";
  left: 50%;
  right: -16px;
  height: 1px;
  background: #000;
  position: absolute;
  top: 0;
}
.tree__list li:not(:last-child:first-child):last-child::before {
  content: "";
  right: 50%;
  left: -16px;
  height: 1px;
  background: #000;
  position: absolute;
  top: 0;
}
.tree__list li:not(:last-child:first-child):not(:first-child):not(:last-child)::before {
  content: "";
  right: -16px;
  left: -16px;
  height: 1px;
  background: #000;
  position: absolute;
  top: 0;
}
.tree__list li::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  background-color: #7B7B7B;
  width: 1px;
  height: 1.875rem;
}
.tree__list li > .tree-item:not(:last-child) {
  margin-bottom: 30px;
  position: relative;
}
.tree__list li > .tree-item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -31px;
  width: 1px;
  background: #7B7B7B;
  height: 31px;
  transform: translateX(-50%);
}
.tree__list li ul {
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.tree__list li:first-child:not(:last-child) > ul {
  max-width: 1457px;
  overflow-y: auto;
  width: max-content;
}
.tree-item {
  width: 9.625rem;
  border-radius: 1.25rem;
  padding: 0.75rem 0;
  background-color: #F5F5FE;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  transition: border 0.3s ease-in-out;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: auto;
}
.tree-item:hover {
  border: 1px solid #5B66E3;
}
.tree-item__img {
  width: 2.5rem;
  height: 2.5rem;
}
.tree-item__img > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tree-item__name {
  color: var(--dark);
  font-size: max(0.75rem, 10px);
  font-weight: 500;
  line-height: 1.5rem;
}
.tree-item__price {
  margin-bottom: 0.25rem;
}
.tree-item__price span {
  color: var(--dark);
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5rem;
}
.tree-item__price span:first-child {
  color: #5B66E3;
}
.tree-item__people {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px -0.625rem;
}
.tree-item__people > * {
  margin: 0 2px 0.625rem;
}
.tree-item__people .tree-item__img {
  width: 1.75rem;
  height: 1.75rem;
}
.tree-item__link {
  display: block;
  margin-top: 4px;
  color: #5B66E3;
  font-size: 10px;
  font-weight: 400;
  line-height: 150%; /* 15px */
  text-decoration: underline !important;
  transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
}
.tree-item__link:hover {
  text-decoration: none !important;
  color: #5B66E3;
}

.file {
  background-color: gray;
  padding: 0.9375rem 0;
}
.file .container {
  background-color: var(--white);
  max-width: 70rem;
}
.file__block.file__block--offset {
  padding-top: 3.75rem;
}
.file__block:not(:last-child) {
  margin-bottom: 0.9375rem;
}
.file__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid var(--blue);
  padding: 1.25rem 0;
  margin-bottom: 1.875rem;
}
.file__header address {
  color: var(--blue);
  text-align: right;
}
.file__footer {
  background-color: var(--blue);
  padding: 0.9375rem 0;
  margin: 0 -1.875rem;
  color: var(--white);
  text-align: right;
}
.file__footer address {
  text-transform: uppercase;
  text-align: center;
}
.file__footer span {
  display: block;
  margin-right: 2.5rem;
}
.file__logo {
  width: 15.625rem;
  height: 6.25rem;
}
.file__logo > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.file .logo {
  width: 18.75rem;
  height: 6.25rem;
}
.file__container {
  max-width: 56.25rem;
  margin: 0 auto 4.375rem;
}
.file__title {
  text-align: center;
  margin-bottom: 1.25rem;
}
.file__title > * {
  font-size: 1.5625rem;
  line-height: 2.5rem;
  font-weight: 600;
}
.file__title.file__title--small {
  text-align: left;
}
.file__title.file__title--small > * {
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 600;
}
.file__title.file__title--line > * {
  text-transform: uppercase;
  text-decoration: underline;
}
.file__title--bg {
  background-color: var(--blue);
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.875rem;
}
.file__title--bg > * {
  font-size: 1.5625rem;
  line-height: 2.5rem;
}
.file .section-text > :not(:first-child) {
  margin-top: 1.875rem;
}
.file .section-text p {
  font-size: max(1rem, 10px);
  line-height: 170%;
}
.file .section-text .yellow {
  background-color: yellow;
}
.file .section-text:not(:last-child) {
  margin-bottom: 2.1875rem;
}
.file__item:not(:last-child) {
  margin-bottom: 2.5rem;
}
.file__list:not(:last-child) {
  margin-bottom: 2.5rem;
}
.file__list span {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  display: block;
  margin-bottom: 0.9375rem;
}
.file__caption {
  font-size: max(0.8125rem, 10px);
  font-weight: 600;
}
.file__caption-item:first-child {
  width: 69%;
}
.file__caption-item span {
  display: block;
}
.file__caption-item:not(:last-child) {
  margin-right: 2.5rem;
}
.file__caption .input-x input {
  width: 100%;
}
.file__row {
  display: flex;
}
.file ul {
  counter-reset: listStyle;
}
.file ul li {
  counter-increment: listStyle;
  position: relative;
}
.file ul li:not(:last-child) {
  margin-bottom: 1.25rem;
}
.file ul li::before {
  position: absolute;
  left: -2.5rem;
  top: 0;
  content: counter(listStyle, upper-alpha) ". ";
}
.file ul a {
  color: var(--black);
  text-decoration: underline;
  font-weight: 500;
}
.file ol:not(:last-child) {
  margin-bottom: 3.125rem;
}
.file ol li {
  font-size: max(0.875rem, 10px);
  font-weight: 400;
  line-height: 1.5625rem;
}
.file ol li:not(:last-child) {
  margin-bottom: 0.25rem;
}

.company__head {
  border-bottom: 1px solid var(--blue);
  margin-bottom: 1.25rem;
  padding-bottom: 0.625rem;
}
.company__form {
  margin-bottom: 1.875rem;
}
.company__form-row {
  display: flex;
  align-items: center;
}
.company__form-row:not(:last-child) {
  margin-bottom: 1.25rem;
}
.company__form-row span {
  display: block;
  margin-right: 0.625rem;
}
.company__form .line {
  width: 100%;
  margin-right: 0.3125rem;
}
.company__referral {
  padding: 1.25rem;
  margin-top: 1.875rem;
  background-color: #E3E8FF;
  border-radius: 1.25rem;
}
.company__referral .company__form-row span {
  flex-shrink: 0;
}
.company__referral .company__form-row .line {
  margin-right: 0.625rem;
  flex-grow: unset;
}
.company address {
  font-size: max(0.9375rem, 10px);
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
}
.company address span {
  color: var(--blue);
}
.company .file__title {
  margin: 2.1875rem auto;
  max-width: 50rem;
}
.company .file__title > * {
  font-size: 1.375rem;
}

.input-list {
  display: flex;
  flex-direction: column;
}

.input-x {
  position: relative;
  padding-left: 1.25rem;
}
.input-x::after {
  content: "X";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: red;
}

.line {
  min-height: 1.75rem;
  flex-grow: 1;
  background-color: #B2D8F9;
  border-bottom: 2px solid var(--blue);
  font-size: max(0.875rem, 10px);
  line-height: 1.25rem;
}
.line.line--color {
  background-color: yellow;
}
.line.line--small {
  width: 5rem;
}
.line:not(:last-child) {
  margin-bottom: 3px;
}

.columns {
  display: flex;
  align-items: flex-start;
  font-weight: 400;
  font-size: 14px;
}
.columns__block {
  width: 100%;
}
.columns__block-width .line {
  margin-left: 0 !important;
  width: 100% !important;
}
.columns__block:not(:last-child) {
  margin-right: 3.125rem;
  text-align: right;
}
.columns__block > span {
  font-weight: 500;
  display: block;
  margin-bottom: 0.625rem;
}
.columns__row {
  display: flex;
  align-items: center;
}
.columns__row .line {
  width: 20%;
  flex-grow: unset;
  margin-left: auto;
}
.columns__row:not(:last-child) {
  margin-bottom: 3px;
}
.columns__name {
  margin: 0 0.9375rem;
  flex-shrink: 0;
}

input[type=checkbox] {
  cursor: pointer;
}

.radio input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}
.radio span {
  position: relative;
  padding-right: 1.25rem;
}
.radio span::after {
  content: "✓";
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  right: 0.125rem;
  top: 50%;
  transform: translateY(-50%);
}
.radio span::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--blue);
}
.radio input[type=radio]:checked + span::after {
  opacity: 1;
}

.promo {
  position: relative;
  margin-top: -7.3125rem;
  padding-top: 6.25rem;
  padding-bottom: 9rem;
  min-height: clamp(37.5rem, 69.44vh, 46.875rem);
  display: flex;
  align-items: center;
  overflow: hidden;
}
.promo > .section-arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.5rem;
}
.promo--offset {
  padding-top: 9.375rem;
}
.promo__container {
  display: flex;
  align-items: center;
}
.promo__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.promo__bg::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(110deg, #0D1E37 15%, rgba(0, 0, 0, 0) 60%);
  background-color: rgba(13, 30, 55, 0.6);
}
.promo__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.promo__info {
  max-width: 35.625rem;
  z-index: 2;
  position: relative;
}
.promo__info.promo__info--big {
  max-width: 41.875rem;
}
.promo__info:not(:last-child) {
  margin-right: 6.25rem;
}
.promo__video {
  flex-shrink: 0;
  flex-grow: 1;
  position: relative;
  width: 54.7%;
}
.promo__video a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.promo__video a svg {
  width: 5.4375rem;
  height: 5.4375rem;
  object-fit: contain;
  margin-bottom: 1rem;
  transition: opacity 0.3s ease-in-out;
}
.promo__video a .btn {
  text-transform: none;
  border: 1px solid #C7B892;
  background-color: rgba(199, 184, 146, 0.3);
}
.promo__video a:hover svg {
  opacity: 0.7;
}
.promo__video a:hover .btn {
  background-color: var(--gold);
}
.promo.promo-main {
  min-height: min(80.093vh, 865px);
  margin-top: -117px;
  padding-top: 177px;
  padding-bottom: 135px;
  overflow: unset;
}
.promo.promo-main .promo__bg {
  background: var(--blue);
  display: flex;
  justify-content: center;
}
.promo.promo-main .promo__bg img {
  max-width: 106.875rem;
  opacity: 0.6;
}
.promo.promo-main .promo__bg::after {
  content: none;
}
.promo.promo-main .promo__bg::before {
  content: "";
  position: absolute;
  background-image: url("../img/gradient-bg.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  left: -1px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.promo.promo-main .promo__title > * {
  font-weight: 700;
}
.promo.promo-main .section-text {
  max-width: 22.875rem;
}
.promo.promo-main .section-text:not(:last-child) {
  margin-bottom: 3.5rem;
}
.promo.promo-main .promo__info {
  padding-bottom: 2rem;
}
.promo.promo-main .promo__info:not(:last-child) {
  margin-right: 1.5rem;
}
.promo.promo-main .section-text p {
  font-size: max(1rem, 12px);
}
.promo.promo-main .section-text {
  min-height: 68px;
}
.promo.promo-events {
  min-height: 31.9375rem;
  padding-top: 10.625rem;
}
.promo-about {
  padding-top: 7.5rem;
  background-color: var(--blue);
}
.promo-about__container {
  display: flex;
  align-items: center;
}
.promo-about__descr {
  max-width: 35.625rem;
  flex-shrink: 0;
  margin-right: 1.875rem;
}
.promo-about__descr .section-text {
  margin-bottom: 3.5rem;
}
.promo-about__info {
  padding: 3.5rem 2.5rem;
  position: relative;
  background-color: #1A2A43;
}
.promo-about__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0.5;
}
.promo-about__bg > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.promo-about__img {
  max-width: 26.875rem;
  position: relative;
  margin: 0 auto 1.5rem;
  flex-shrink: 0;
  border: 5px solid var(--blue);
  border-bottom: 5px solid #B8CEE2;
}
.promo-about__img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 100%);
}
.promo-about__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.promo-about__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 115.6%;
}
.promo-about__text {
  color: var(--white);
  text-align: center;
  font-size: 1.5rem;
  font-family: "Crimson Pro", serif;
  font-weight: 300;
  line-height: 2rem; /* 133.333% */
}
.promo-about__text span {
  color: var(--gold);
}
.promo-img {
  position: relative;
}
.promo-img + .what-is {
  padding-bottom: 9rem;
}
.promo-img + .what-is .what-is__block:first-child .what-is__info {
  align-items: center;
}
.promo-img + .what-is .what-is__block:first-child .what-is__title {
  max-width: 41.875rem;
  margin-right: 1.875rem;
}
.promo-img__logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 58.5%;
}
.promo-img__logo > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.promo-img__image {
  border: 0.3125rem solid var(--blue);
  border-bottom: 0.3125rem solid #B8CEE2;
  position: relative;
  width: 31.25rem;
  flex-shrink: 0;
}
.promo-img__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.promo-img__image::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 104.4%;
}
.promo-img .title-h1 > * {
  color: var(--blue);
}
.promo-img .promo__info:not(:last-child) {
  margin-right: 2.5rem;
  padding-left: 2.5rem;
}
.promo-img .promo__container {
  padding: 5rem 0;
  position: relative;
}
.promo-img .promo__container::before {
  content: "";
  position: absolute;
  width: 31.7%;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--blue);
}
.promo-img .promo__container::after {
  content: "";
  position: absolute;
  width: 68.3%;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: var(--light);
  z-index: -1;
}
.promo-img .section-text p {
  color: var(--gray);
}
.promo-medicaid__info {
  max-width: unset;
}
@media (max-width: 768px) {
  .promo {
    min-height: unset;
    padding-top: 130px;
  }
  .promo > .section-arrow {
    bottom: 20px;
  }
  .promo .promo__container {
    flex-direction: column;
  }
  .promo .promo__info:not(:last-child) {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .promo .promo__info.promo__info--big {
    max-width: unset;
  }
  .promo__video {
    width: 100%;
    margin-bottom: 30px;
  }
  .promo__video a svg {
    width: 60px;
    height: 60px;
    margin-bottom: 2px;
  }
  .promo__video a .btn {
    padding: 8px 20px;
    min-width: unset;
  }
  .promo.promo-main {
    padding-top: 149px;
    padding-bottom: 50px;
    min-height: unset;
  }
  .promo.promo-main + .section-blur {
    top: 0;
  }
  .promo.promo-main + .section-blur .section-blur__container {
    padding: 80px 0 0;
  }
  .promo.promo-main .promo__container {
    align-items: flex-start;
    flex-direction: row;
  }
  .promo.promo-main .promo__container .btn {
    width: 100%;
  }
  .promo.promo-main .promo__info {
    max-width: unset;
    width: 100%;
  }
  .promo.promo-main .promo__info:not(:last-child) {
    margin-right: 0;
  }
  .promo.promo-main .promo__bg::before {
    background-image: url("../img/gradient-bg-mobile.svg");
  }
  .promo.promo-main .section-text {
    max-width: 53%;
    padding-left: 16px;
    border-left: 1px dashed var(--gold);
  }
  .promo.promo-main .section-text:not(:last-child) {
    margin-bottom: 30px;
  }
  .promo.promo-events {
    padding-top: 130px;
    min-height: unset;
  }
  .promo-about {
    padding-bottom: 80px;
  }
  .promo-about__info {
    padding: 20px;
  }
  .promo-about__text {
    font-size: 16px;
    line-height: 26px;
  }
  .promo-about__container {
    flex-direction: column;
  }
  .promo-about__descr {
    max-width: unset;
    margin-right: 0;
    margin-bottom: 25px;
  }
  .promo-about__descr .section-text {
    margin-bottom: 20px;
  }
  .promo-img {
    padding-bottom: 60px;
  }
  .promo-img + .what-is .what-is__block {
    margin-bottom: 20px;
    padding-bottom: 30px;
  }
  .promo-img + .what-is .what-is__block:first-child .what-is__title {
    max-width: unset;
    margin-right: 0;
  }
  .promo-img__image {
    width: 90%;
    max-height: 400px;
    overflow: hidden;
  }
  .promo-img__logo {
    left: 50%;
    top: 15px;
    bottom: unset;
    width: 320px;
    transform: translateX(-50%);
  }
  .promo-img .promo__container {
    padding: 20px 10px;
  }
  .promo-img .promo__container::before {
    content: none;
  }
  .promo-img .promo__container::after {
    width: 100%;
  }
  .promo-img .promo__info:not(:last-child) {
    padding-left: 0;
  }
}

.about-us {
  padding-bottom: 9rem;
  padding-top: 3.125rem;
  position: relative;
  overflow: hidden;
}
.about-us__container {
  display: flex;
  min-height: min(81.482vh, 55rem);
  border-bottom: 0.4375rem solid #B8CEE2;
}
.about-us__container > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about-us__inner {
  display: flex;
  flex-direction: column;
  width: 37.7%;
  flex-shrink: 0;
  background-color: var(--blue);
  padding: 3.125rem 1.875rem;
  align-items: flex-start;
  padding: 2.8125rem 1.875rem 2.4375rem;
  position: relative;
  border-top: 0.3125rem solid var(--gold);
}
.about-us__inner-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0.5;
}
.about-us__inner-bg > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}
.about-us .section-text {
  margin-bottom: 3.625rem;
}
@media (max-width: 768px) {
  .about-us {
    padding: 40px 0;
    background-color: var(--light);
  }
  .about-us .logo-bg {
    top: 62%;
    width: 100%;
    height: 50%;
  }
  .about-us .slider-images {
    padding: 0;
  }
  .about-us .title-h1 > * {
    color: var(--blue);
  }
  .about-us__container {
    min-height: unset;
    border-bottom: unset;
  }
  .about-us__info {
    margin-top: 24px;
  }
  .about-us__info .section-text.section-text--border {
    padding-left: 16px;
  }
  .about-us__info .section-text {
    margin-bottom: 40px;
  }
  .about-us__info .section-text p, .about-us__info .section-text li {
    color: var(--gray);
  }
  .about-us__info .section-text li::after {
    background-color: var(--gray);
  }
}

.what-is {
  padding-top: 9rem;
  background-color: var(--blue);
}
.what-is + .section-blur.card-items {
  top: 0;
}
.what-is__content {
  overflow: hidden;
  margin-bottom: -4.25rem;
}
.what-is__block {
  margin-bottom: 9rem;
}
.what-is__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -1.875rem;
}
.what-is__container > * {
  width: calc(33.3333333333% - 30px);
  margin: 0 15px 1.875rem;
}
.what-is__container.what-is__container--similiar > * {
  width: calc(25% - 30px);
}
.what-is__container.what-is__container--similiar .what-is__card {
  border: 0.3125rem solid var(--gold);
  background-color: #E5E5E5;
  box-shadow: 4px 4px 16px 0px rgba(46, 85, 146, 0.3);
  display: flex;
  align-items: center;
  padding: 2.6875rem 2.0625rem 2.6875rem 2.5rem;
  border-radius: 0;
}
.what-is__container.what-is__container--similiar .what-is__card-img {
  margin-right: 1rem;
  margin-bottom: 0;
}
.what-is__container.what-is__container--similiar .what-is__card .section-text p {
  color: var(--gray);
}
.what-is__title {
  max-width: 36.125rem;
  flex-shrink: 0;
  margin-right: 2.375rem;
  margin-bottom: 0 !important;
}
.what-is__info {
  display: flex;
  margin-bottom: 3.5rem;
}
.what-is__info .section-text {
  margin-bottom: 1.25rem;
}
.what-is__card {
  padding: 1.5rem;
  border-radius: 0.375rem;
  background: #1A2A43;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.16);
}
.what-is__card-img {
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  margin-bottom: 1rem;
}
.what-is__card-img > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.what-is__about {
  position: relative;
  text-align: center;
  padding: 5.875rem 6.125rem 5rem;
}
.what-is__about-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.what-is__about-bg > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.what-is__about .what-is__title {
  max-width: unset;
  margin-right: 0;
  margin-bottom: 2rem !important;
}
.what-is__cards {
  position: relative;
  z-index: 2;
  bottom: -4.25rem;
}
.what-is__cards::before {
  content: "";
  position: absolute;
  top: 4.75rem;
  background-color: var(--light);
  left: 0;
  right: 0;
  bottom: 4.25rem;
}
.what-is__cards .what-is__container {
  margin-bottom: 0;
}
.what-is .block-single {
  display: flex;
  align-items: center;
}
.what-is .block-single > * {
  max-width: 35.625rem;
  width: 100%;
}
.what-is .block-single .what-is__info {
  flex-direction: column;
  margin-right: 1.875rem;
}
.what-is .block-single .what-is__title {
  margin-right: 0;
  margin-bottom: 2.5rem !important;
  max-width: unset;
}
.what-is__img {
  position: relative;
  overflow: hidden;
}
.what-is__img > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.what-is__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 95.6%;
}
@media (max-width: 768px) {
  .what-is {
    padding: 40px 0;
  }
  .what-is__info {
    flex-direction: column;
    margin-bottom: 24px;
  }
  .what-is__block {
    margin-bottom: 0;
    border-bottom: 3px solid var(--gold);
    padding-bottom: 43px;
  }
  .what-is__title {
    max-width: unset;
    margin-right: 0;
    margin-bottom: 24px !important;
  }
  .what-is .section-text:not(.what-is__card .section-text) {
    padding-left: 16px;
    border-left: 1px dashed var(--gold);
  }
  .what-is__container {
    margin: 0;
  }
  .what-is__container > * {
    width: 100%;
    margin: 0;
  }
  .what-is__container > *:not(:last-child) {
    margin-bottom: 24px;
  }
  .what-is__container.what-is__container--similiar > * {
    width: 100%;
  }
  .what-is__container.what-is__container--similiar .what-is__card {
    padding: 31px 40px 31px 40px;
  }
  .what-is__about {
    text-align: left;
    padding: 40px 0 0;
  }
  .what-is__about .what-is__title {
    margin-bottom: 24px !important;
  }
  .what-is__about .section-text-wrap {
    margin-bottom: 40px;
  }
  .what-is__cards {
    bottom: 0;
    margin-top: 45px;
  }
  .what-is__cards::before {
    top: 56px;
    bottom: -40px;
  }
  .what-is__img {
    max-height: 450px;
  }
  .what-is .block-single {
    flex-direction: column;
  }
  .what-is .block-single > * {
    max-width: unset;
  }
  .what-is .block-single .what-is__info {
    margin-right: 0;
  }
}

.services {
  background-color: var(--blue);
  padding: 3.125rem 0 9rem 0;
  overflow: hidden;
}
.services + .consult {
  margin-top: 11.4375rem;
}
.services__title {
  margin-bottom: 3.5rem;
}
.services__title > * {
  color: var(--white);
}
.services__tabs {
  display: flex;
  position: relative;
  border-bottom: 1px solid #2E5592;
}
.services__tabs > * {
  margin-bottom: -3px;
}
.services__tab {
  color: var(--light);
  line-height: 1.5625rem;
  transition: color 0.3s ease-in-out, border-bottom-color 0.3s ease-in-out;
  font-size: 1.25rem;
  font-weight: 400;
  border-bottom: 5px solid transparent;
  padding: 0 0.625rem 1.5rem;
  flex-grow: 1;
  max-width: 18.75rem;
}
.services__tab:hover {
  background-color: var(--white-bg);
  color: var(--gold);
}
.services__tab.active {
  color: var(--gold);
  border-bottom-color: var(--gold);
}
.services__content {
  margin-top: 2.5rem;
}
.services__wrapper:not(:first-child) {
  display: none;
}
.services__info {
  display: flex;
  align-items: flex-start;
}
.services__info-img {
  width: 35.625rem;
  position: relative;
  flex-shrink: 0;
  margin-left: 1.875rem;
}
.services__info-img > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services__info-img::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.42);
  pointer-events: none;
}
.services__info-img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 61.3%;
}
.services__info-title {
  margin-bottom: 1.5rem;
}
.services__info-descr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.services__info .section-text {
  margin-bottom: 1.25rem;
}
.services__info .section-link {
  margin-top: auto;
}
@media (max-width: 768px) {
  .services {
    padding: 40px 0;
  }
  .services + .consult {
    margin-top: 80px;
  }
  .services__title {
    margin-bottom: 20px;
  }
  .services__content {
    margin-top: 26px;
  }
  .services__info {
    flex-direction: column-reverse;
  }
  .services__info-img {
    width: 100%;
    margin-left: 0;
    margin-bottom: 24px;
    max-height: 300px;
    overflow: hidden;
  }
  .services__tabs {
    overflow-y: auto;
    border-bottom: unset;
  }
  .services__tabs > * {
    margin-bottom: 13px;
  }
  .services__tab {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    padding: 0;
    max-width: unset;
    flex-shrink: 0;
    padding-bottom: 6px;
    border-bottom: 3px solid transparent;
  }
  .services__tab:not(:last-child) {
    margin-right: 24px;
  }
  .services .services__info-title {
    margin-bottom: 15px;
  }
  .services .services__info .section-text {
    margin-bottom: 15px;
  }
  .services .services__info-img::before {
    padding-top: 66.4%;
  }
}

.planning {
  padding: 9rem 0;
  position: relative;
  overflow: hidden;
}
.planning .logo-bg {
  right: unset;
  left: 0;
}
.planning .logo-bg > * {
  object-position: left;
}
.planning__container {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 5rem;
  background-color: var(--light-opacity);
  padding-left: 2.5rem;
  padding-right: 4.125rem;
}
.planning__container::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--blue);
  width: 31.7%;
}
.planning__container .logo-bg {
  top: 2.875rem;
  left: 1.875rem;
  bottom: 5rem;
  right: unset;
  width: 78%;
  transform: translateY(0);
}
.planning__container .logo-bg > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.planning__info {
  margin-right: 1.875rem;
  padding-bottom: 5rem;
  width: 49.8%;
  flex-shrink: 0;
}
.planning__info .section-text {
  padding-left: 1.5625rem;
}
.planning__info .section-text p {
  color: var(--gray);
}
.planning__title > * {
  color: var(--blue);
}
.planning__img {
  position: relative;
  width: 100%;
  border-bottom: 0.4375rem solid #B8CEE2;
  overflow: hidden;
}
.planning__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.planning__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 119.8%;
}
.planning__img::after {
  border: 5px solid #0D1E37;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42));
  z-index: 1;
}
.planning-list {
  margin-top: 3rem;
  max-width: 29.375rem;
  background-color: var(--blue);
  border-right: 0.375rem solid var(--gold);
  padding: 2rem 3.6875rem;
  margin-left: -2.5rem;
}
.planning-list__inner {
  display: flex;
  align-items: center;
}
.planning-list__img {
  flex-shrink: 0;
  width: 14.8125rem;
  height: 9rem;
  position: relative;
  margin-right: 1rem;
}
.planning-list__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.planning-list__img::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.planning-list ul {
  width: max-content;
}
.planning-list ul li {
  display: flex;
  align-items: center;
  color: var(--light);
  font-size: max(0.875rem, 10px);
  line-height: 1.5rem;
  text-transform: uppercase;
}
.planning-list ul li:not(:last-child) {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px dashed var(--gold);
}
.planning-list.planning-list--offset .section-text {
  padding-left: 0;
}
.planning-list.planning-list--offset .section-text p {
  color: var(--light);
}
@media (max-width: 768px) {
  .planning {
    padding: 40px 0;
    background-color: var(--light-opacity);
    border-top: 3px solid var(--gold);
  }
  .planning__container {
    padding: 0;
    background-color: unset;
  }
  .planning__container::before {
    content: none;
  }
  .planning__info {
    margin-right: 0;
    padding-bottom: 0;
    width: 100%;
  }
  .planning__info .section-text {
    padding-left: 16px;
    border-left: 1px dashed var(--gold);
  }
  .planning__img {
    border-bottom: 4px solid var(--gold);
    margin-bottom: 24px;
    max-height: 450px;
  }
  .planning__img::before {
    padding-top: 117.2%;
  }
  .planning__img::after {
    border: unset;
  }
  .planning-list {
    margin-top: 24px;
    margin-left: 0;
    border-left: 0.375rem solid var(--gold);
    border-right: unset;
    padding: 34px;
  }
  .planning-list__inner {
    flex-direction: column;
  }
  .planning-list__img {
    width: 100%;
    height: 190px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .planning-list--offset .section-text {
    border-left: unset;
  }
  .planning-list ul li:not(:last-child) {
    margin-bottom: 16px;
  }
  .planning .logo-bg {
    left: 50%;
    transform: translate(-50%, -25%);
    width: 86.5%;
    height: 36%;
  }
  .planning .logo-bg > * {
    object-position: center;
  }
}

.consult {
  padding: 5.875rem 0;
  margin-bottom: 9rem;
  background-color: var(--blue);
  color: var(--white);
  position: relative;
  overflow: hidden;
}
.consult__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.consult__container .form {
  z-index: 5;
}
.consult__info {
  margin-right: 3.125rem;
  flex-grow: 1;
}
.consult__info .section-text > p {
  max-width: 30.875rem;
}
.consult__bg {
  position: absolute;
  left: var(--container-margin);
  right: 0;
  top: 0;
  bottom: 0;
}
.consult__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  opacity: 0.5;
}
.consult__subtitle {
  color: var(--white);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem; /* 120% */
  text-transform: uppercase;
  margin-top: 5rem;
  margin-bottom: 3.5rem;
  font-family: "Cormorant Garamond", serif;
}
.consult.consult-us__map {
  height: 28.125rem;
}
.consult.consult-us__map iframe {
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .consult {
    padding: 50px 0;
    margin-bottom: 80px;
  }
  .consult__container {
    flex-direction: column;
  }
  .consult__info {
    margin-right: 0;
    margin-bottom: 35px;
  }
}

.manager {
  margin-bottom: 5.625rem;
}
.manager + .services {
  padding: 5.625rem 0;
}
.manager__inner {
  display: flex;
  align-items: center;
  padding: 2.75rem 3.25rem;
  border: 5px solid var(--gold);
  border-radius: 1rem;
  background-color: var(--white);
}
.manager__inner .section-arrow {
  background-color: rgb(199, 184, 146);
}
.manager__img {
  position: relative;
  width: 19.875rem;
  flex-shrink: 0;
  margin-right: 1.875rem;
}
.manager__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.manager__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.manager__title {
  color: var(--blue);
  font-family: "Cormorant Garamond", serif;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 5rem; /* 222.222% */
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.manager__text p {
  color: var(--gray);
  font-size: max(0.875rem, 10px);
  font-weight: 400;
  line-height: 1.5rem;
}
.manager__text--big p {
  color: var(--blue);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 120%;
}
.manager__text span {
  margin-bottom: 0.625rem;
  display: block;
}
.manager__text .socials {
  margin: 6px 0;
}
.manager__video {
  display: flex;
  align-items: center;
  margin: 1rem 0 2.5rem;
}
.manager__video span {
  color: var(--gray);
  font-size: max(0.875rem, 10px);
  font-weight: 700;
  line-height: 1.5rem;
  margin-right: 2.125rem;
  display: block;
  text-transform: uppercase;
}
.manager__video .btn {
  text-transform: none;
  min-width: 8.9375rem;
}
.manager__video .btn:not(:last-child) {
  margin-right: 1rem;
}
.manager__button {
  margin-top: 1.25rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .manager {
    margin-bottom: 70px;
  }
  .manager + .services {
    padding: 50px 0;
  }
  .manager .manager__inner {
    padding: 20px 10px;
    border: 3px solid var(--gold);
    flex-direction: column;
  }
  .manager .manager__img {
    width: 230px;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .manager .manager__title {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .manager .manager__video {
    align-items: flex-start;
    margin: 1rem 0 16px;
    flex-direction: column;
  }
  .manager .manager__video span {
    margin-right: 0;
    margin-bottom: 6px;
  }
  .manager .manager__video .btn:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .manager .manager__text .socials {
    justify-content: flex-start;
  }
  .manager .manager__text--big p {
    font-size: 16px;
    line-height: 125%;
  }
  .manager .manager__content {
    width: 100%;
  }
}

.free-consult {
  position: relative;
  padding: 6.25rem 0;
}
.free-consult__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
}
.free-consult__bg > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.free-consult__container {
  text-align: center;
}
.free-consult__container .section-text {
  margin-bottom: 3.5rem;
}
@media (max-width: 768px) {
  .free-consult {
    padding: 40px 0;
  }
  .free-consult__container {
    text-align: left;
  }
  .free-consult__container .section-text {
    margin-bottom: 3.5rem;
    margin-bottom: 40px;
  }
  .free-consult__container .btn {
    max-width: 400px;
    width: 100%;
  }
}

.events {
  padding: 9rem 0;
  background-color: var(--blue);
  position: relative;
  border-bottom: 7px solid var(--gold);
}
.events__title {
  margin-bottom: 2.5rem;
}
.events__tabs {
  margin: 0 -8px;
}
.events__tabs > * {
  margin: 0 8px;
}
.events__content {
  margin-top: 2.5rem;
}
.events__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -1.875rem;
}
.events__container > * {
  width: calc(33.3333333333% - 30px);
  margin: 0 15px 1.875rem;
}
.events-card {
  display: flex;
  flex-direction: column;
}
.events-card__img {
  position: relative;
}
.events-card__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.events-card__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 75%;
}
.events-card__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.25rem;
  background-color: #1A2A43;
}
.events-card__inner .btn {
  margin-top: auto;
  width: 100%;
  text-transform: none;
  text-transform: uppercase;
}
.events-card__about {
  display: flex;
  margin-bottom: 1.5rem;
}
.events-card__about time {
  color: var(--light);
  font-size: max(1rem, 10px);
  font-weight: 400;
  line-height: 1.25rem;
  text-align: center;
  display: block;
  margin-bottom: 0.75rem;
}
.events-card__date {
  text-align: center;
  color: var(--gold);
  font-size: max(1rem, 10px);
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 0.75rem;
}
.events-card__date span {
  color: var(--light);
  margin-top: 4px;
  display: block;
}
.events-card__block:not(:last-child) {
  margin-right: 0.9375rem;
  padding-right: 1.25rem;
  border-right: 1px dashed var(--gold);
  min-width: 4.6875rem;
  text-align: center;
  flex-shrink: 0;
}
.events-card__title {
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5625rem;
  margin-bottom: 1rem;
}
.events-card__price {
  color: var(--white);
  font-weight: 800;
}
.events__bottom {
  margin-top: 3.5rem;
  text-align: center;
}
@media (max-width: 768px) {
  .events {
    padding: 50px 0 75px;
  }
  .events__tabs > * {
    margin: 0 5px;
    padding: 12px;
    min-width: unset;
  }
  .events__tabs {
    margin: 0 -5px;
  }
  .events__container {
    margin: 0;
  }
  .events__container > * {
    width: 100%;
    margin: 0;
  }
  .events__container > *:not(:last-child) {
    margin-bottom: 25px;
  }
  .events-card__img {
    max-height: 355px;
    overflow: hidden;
  }
  .events-card__inner {
    padding: 15px;
  }
  .events-card__title {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

/* main.css | http://localhost:3000/css/main.css */
.pictures {
  padding: 5.625rem 7.5rem;
  position: relative;
  overflow: hidden;
}
.pictures__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.pictures__bg > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pictures__slide {
  position: relative;
  border: 0.3125rem solid transparent;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.pictures__slide img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.pictures__slide::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 60%;
}
.pictures__slide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0.42;
  transition: opacity 0.3s ease-in-out;
  background-color: var(--black);
}
.pictures .section-slider__buttons {
  margin-top: 3.5rem;
}
.pictures .container {
  overflow: hidden;
}
.pictures .swiper {
  overflow: visible;
  width: 39.8%;
}
.pictures .swiper-slide-active .pictures__slide {
  border-color: #C7B892;
  box-shadow: 12px 12px 20px 0px rgba(11, 21, 32, 0.35);
}
.pictures .swiper-slide-active .pictures__slide::after {
  opacity: 0;
}
@media (max-width: 768px) {
  .pictures {
    padding: 60px 0;
  }
  .pictures .swiper {
    width: 100%;
    max-width: 400px;
  }
  .pictures .container {
    overflow: visible;
  }
  .pictures .section-slider__buttons {
    margin-top: 30px;
  }
}

.footer {
  background-color: var(--blue);
  padding: 2rem 0;
  position: relative;
}
.footer .scroll-top {
  position: fixed;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}
.footer .scroll-top svg {
  transform: rotate(180deg);
}
.footer .scroll-top.active {
  opacity: 1;
  visibility: visible;
}
.footer .logo {
  margin-right: 4.0625rem;
}
.footer address {
  margin-bottom: 2rem;
}
.footer__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__container > * {
  color: var(--light);
  font-size: max(0.875rem, 10px);
  font-weight: 400;
  line-height: 1.125rem;
}
.footer__info {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px dashed var(--gold);
  padding: 1.25rem 0 1.25rem 4.0625rem;
}
.footer-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4.625rem;
}
.footer__nav li:not(:last-child) {
  margin-bottom: 1.5rem;
}
.footer__nav a {
  color: var(--light);
  transition: color 0.3s ease-in-out;
}
.footer__nav a:hover {
  color: var(--gold);
}
@media (max-width: 768px) {
  .footer {
    padding: 40px 0 0;
  }
  .footer .logo {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .footer .footer__container {
    flex-direction: column;
  }
  .footer-block {
    margin-bottom: 30px;
    flex-direction: column;
  }
  .footer-block__item {
    width: 100%;
  }
  .footer-block__item:not(:last-child) {
    margin-bottom: 40px;
  }
  .footer-block .btn {
    width: 100%;
    max-width: 450px;
  }
  .footer__info {
    padding-left: 0;
    padding: 40px 0 24px 0;
    text-align: center;
    border-left: unset;
    border-top: 1px dashed var(--gold);
  }
  .footer__copy {
    margin-top: 60px;
  }
}

.section-text > *:not(:first-child) {
  margin-top: 1.875rem;
}
.section-text p {
  color: var(--light);
  font-size: max(0.875rem, 10px);
  font-weight: 400;
  line-height: 1.5rem;
  transition: color 0.3s ease-in-out;
}
.section-text--big p {
  font-size: max(1rem, 10px);
  color: var(--white);
  font-size: 1.25rem;
  line-height: 150%;
}
.section-text ul li {
  position: relative;
  color: var(--light);
  font-size: max(0.875rem, 10px);
  line-height: 150%;
  padding-left: 1.25rem;
}
.section-text ul li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0.625rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background-color: var(--light);
}
.section-text-hide {
  display: none;
}
.section-text.section-text--border {
  padding-left: 1.5rem;
  border-left: 1px dashed var(--gold);
}
.section-link {
  display: flex;
  align-items: center;
  z-index: 1;
  position: relative;
  background-color: var(--gold);
  padding: 0.5rem 1.5rem;
  padding-right: 1.9375rem;
  transition: background-color 0.3s ease-in-out;
}
.section-link:hover {
  background-color: var(--brown);
}
.section-link span {
  display: block;
  margin-right: 1.375rem;
  color: var(--white);
  font-size: 1.25rem;
  line-height: 1.125rem;
  font-size: max(0.875rem, 10px);
  font-weight: 400;
  text-transform: uppercase;
}
.section-link__img {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 3.25rem;
  border: 1px solid var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.section-link__img > * {
  width: 1.125rem;
  height: 1.125rem;
  object-fit: contain;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
}
.section-link__img > * path {
  fill: var(--white);
}
.section-link.is-show .section-link__img > * {
  transform: rotate(-85deg);
}
.section-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #C7B892;
  background-color: rgba(199, 184, 146, 0.3);
  width: 3.875rem;
  height: 3.875rem;
  transition: opacity 0.3s ease-in-out;
}
.section-arrow:hover {
  opacity: 0.7;
}
.section-arrow svg {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
}
.section-blur {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  margin-top: -5.875rem;
  backdrop-filter: blur(10px);
  position: relative;
}
.section-blur__container {
  padding: 2.75rem 0;
}
.section-blur__container .title-h1:not(.teams__title) > * {
  font-size: 2rem;
  line-height: normal;
}
.section-slider__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-slider__buttons.buttons--dark .section-slider__prev, .section-slider__buttons.buttons--dark .section-slider__next {
  border: 1px solid #E5E5E5;
  background-color: var(--blue);
}
.section-slider__buttons.buttons--dark .section-slider__prev svg path, .section-slider__buttons.buttons--dark .section-slider__next svg path {
  stroke: #E5E5E5;
}
.section-slider__buttons.buttons--dark .section-slider__prev:hover, .section-slider__buttons.buttons--dark .section-slider__next:hover {
  background-color: #E5E5E5;
}
.section-slider__buttons.buttons--dark .section-slider__prev:hover svg path, .section-slider__buttons.buttons--dark .section-slider__next:hover svg path {
  stroke: var(--blue);
}
.section-slider__prev, .section-slider__next {
  width: 6.3125rem;
  border: 1px solid var(--blue);
  background-color: var(--light);
  flex-shrink: 0;
  padding: 0.375rem 1rem;
  display: flex;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  height: 2.0625rem;
  align-items: center;
}
.section-slider__prev svg, .section-slider__next svg {
  position: relative;
  width: 1rem;
  height: 1rem;
  object-fit: contain;
}
.section-slider__prev svg path, .section-slider__next svg path {
  transition: stroke 0.3s ease-in-out;
}
.section-slider__prev:hover, .section-slider__next:hover {
  border-color: var(--gold);
}
.section-slider__prev:hover svg path, .section-slider__next:hover svg path {
  stroke: var(--gold);
}
.section-slider__prev.swiper-button-disabled, .section-slider__next.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.6;
}
.section-slider__next {
  justify-content: flex-end;
}
.section-head {
  display: flex;
  margin-bottom: 3.5rem;
}
.section-head .title-h1 {
  max-width: 41.875rem;
  width: 100%;
  flex-shrink: 0;
  margin-right: 1.875rem;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .section-text > *:not(:first-child) {
    margin-top: 15px;
  }
  .section-text p {
    font-size: 14px;
  }
  .section-text--big p {
    font-size: 16px;
  }
  .section-blur {
    overflow: hidden;
    background-color: unset;
  }
  .section-blur__container {
    padding: 40px 0 0;
  }
  .section-blur .swiper {
    overflow: visible;
    width: 70%;
  }
  .section-blur .card {
    max-width: unset;
  }
  .section-slider__prev, .section-slider__next {
    width: 50%;
  }
  .section-arrow {
    width: 45px;
    height: 45px;
  }
  .section-head {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .section-head .title-h1 {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.slider-images {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: var(--light-opacity);
  padding-left: 2.5rem;
  padding-top: 8.25rem;
  padding-bottom: 1.875rem;
}
.slider-images .swiper {
  width: calc(100% - 6rem);
  overflow: visible;
  margin-left: 0;
  margin-bottom: 10.5rem;
}
.slider-images .swiper .swiper-pagination {
  opacity: 0;
}
.slider-images__inner {
  overflow: hidden;
  margin-bottom: 3.5rem;
}
.slider-images__img {
  position: relative;
}
.slider-images__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider-images__img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 60.3%;
}
@media (max-width: 768px) {
  .slider-images {
    background-color: unset;
  }
  .slider-images .slider-images__img::before {
    padding-top: 60%;
  }
  .slider-images__inner {
    margin-bottom: 0;
  }
  .slider-images .slider-images__img {
    max-height: 400px;
  }
  .slider-images .swiper {
    width: 100%;
    overflow: hidden;
    margin-bottom: 0;
    padding-bottom: 56px;
  }
  .slider-images .swiper .swiper-pagination {
    opacity: 1;
  }
  .slider-images .swiper .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    transition: width 0.3s ease-in-out;
  }
  .slider-images .swiper .swiper-pagination-bullet-active {
    width: 20px;
    height: 6px;
    background: var(--blue);
  }
}

.form {
  width: 35.625rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-top: 3.125rem;
}
.form .btn {
  margin-left: auto;
  min-width: 8.9375rem;
}
.form .input:not(:last-child) {
  margin-bottom: 1rem;
}
.form .input input[type=tel]::placeholder {
  opacity: 1;
}
@media (max-width: 768px) {
  .form {
    width: 100%;
    margin-top: 0;
  }
}

.card-slider .swiper-slide {
  counter-increment: number;
  height: auto;
}
.card-slider .swiper-button-disabled {
  opacity: 0.3;
  pointer-events: none;
}
.card-slider .section-slider__buttons {
  margin-top: 1.25rem;
}
@media (max-width: 768px) {
  .card-slider .swiper-slide:not(:first-child).scroll-appearance.animate-top, .card-slider .swiper-slide:not(:first-child).scroll-appearance.animate-bottom {
    opacity: 1;
    transform: translateY(0);
  }
  .card-slider .section-slider__buttons {
    padding: 0 2px;
  }
}

.card {
  border-radius: 0.5rem;
  background-color: var(--white);
  padding: 3.75rem 1.5rem 1.5rem;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.card::after {
  position: absolute;
  left: 1.5rem;
  display: block;
  top: 0;
  content: counter(number);
  color: var(--brown);
  font-family: "Abhaya Libre", serif;
  font-size: 3.75rem;
  font-weight: 500;
  line-height: 4.5rem;
  text-transform: uppercase;
}
.card:hover {
  background-color: #E1DCCB;
  cursor: pointer;
}
.card:hover .section-text p {
  color: var(--blue);
}
.card .section-text {
  margin-top: 1.375rem;
}
.card .section-text p {
  color: var(--gray);
  font-size: max(0.875rem, 10px);
}
.card-container {
  margin: 0 -4px;
  display: flex;
  flex-wrap: wrap;
}
.card-container > * {
  width: calc(20% - 8px);
  margin: 0 4px;
}
.card-container .card {
  height: auto;
  counter-increment: number;
}
.card-items.section-blur {
  top: -6.875rem;
  margin-bottom: 4.625rem;
}
.card-items .section-blur__container {
  padding: 2rem 0;
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: 70%;
    margin: 0 auto;
    padding-top: 75px;
  }
  .card:not(:last-child) {
    margin-bottom: 16px;
  }
  .card__container {
    margin: 0;
    flex-direction: column;
  }
  .card-items.section-blur {
    top: -80px;
    margin-bottom: 0;
  }
}

.menu {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  height: 100%;
  background-color: var(--blue);
  transform: translateX(-100%);
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
}
.menu.is-active {
  transform: translateX(0);
}
.menu__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
}
.menu__close {
  width: 24px;
  height: 24px;
}
.menu__close > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.menu__inner {
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  overflow-y: auto;
}
.menu__inner .header__nav {
  margin-left: 0;
  margin-bottom: 30px;
  width: 100%;
}
.menu__inner .header__nav-list {
  align-items: unset;
  flex-direction: column;
}
.menu__inner .header__nav-list li {
  position: relative;
  padding: 0;
}
.menu__inner .header__nav-list li:not(:last-child) {
  margin-right: 0;
  margin-bottom: 24px;
}
.menu__inner .header__nav-list li.active a {
  color: var(--gold) !important;
}
.menu__inner .header__nav-list li a {
  color: var(--white) !important;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.menu__inner .header__nav-list li.has-child {
  position: relative;
}
.menu__inner .header__nav-list li.has-child > a {
  padding-right: 0 !important;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 100%;
}
.menu__inner .header__nav-list li.has-child > a::after {
  position: static;
  transform: none;
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  margin-left: 6px;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 7L7.5 10L10.5 7' stroke='white' stroke-linecap='square'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
}
.menu__inner .header__nav-list li.has-child ul {
  padding-top: 16px;
  padding-left: 16px;
  min-width: unset;
  border-radius: 0;
  text-align: left;
  display: none;
  transition: unset;
  padding-bottom: 0;
  width: 100%;
}
.menu__inner .header__nav-list li.has-child ul li {
  padding-bottom: 0;
  line-height: 0;
}
.menu__inner .header__nav-list li.has-child ul li:not(:last-child) {
  margin-bottom: 16px;
  padding-bottom: 16px;
}
.menu__inner .header__nav-list li.has-child ul li:not(:last-child)::after {
  display: none;
}
.menu__inner .header__nav-list li.has-child ul li:not(:last-child)::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  border-bottom: 1px dashed var(--gold);
}
.menu__inner .header__nav-list li.has-child ul li.active a {
  color: var(--gold) !important;
}
.menu__inner .header__nav-list li.has-child ul li a {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  text-transform: unset;
}
.menu__inner .header__nav-list li.has-child ul li:last-child::after {
  display: none !important;
}
.menu__inner .header__nav-list li.has-child.is-open > a::after {
  transform: rotate(180deg) !important;
}
.menu__inner .header__nav-list li.has-child.is-open > *:not(ul) {
  color: #2755F3;
  color: var(--gold) !important;
}
.menu .header__btns {
  margin-left: 0;
  margin-top: auto;
  flex-direction: column;
}
.menu .header__btns > *:not(:last-child) {
  margin-bottom: 20px;
}