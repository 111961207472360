.header{
    position: relative;
    z-index: 999;
    padding: rem(33) 0;
    background-color: transparent;
    &__container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__nav{
        margin-left: rem(10);
        &-list{
            display: flex;
            align-items: center;
            li{
                position: relative;
                &:not(:last-child){
                    margin-right: rem(32);
                }
                &.active{
                    > a {
                        color: var(--gold) !important;
                    }
                }
                @media(min-width: 769px){
                    &:hover{
                        > .header__dropdown{
                            opacity: 1 ;
                            top: unset;
                            bottom: 0;
                            visibility: visible;
                        }
                    }
                }
                &>a:not(.header__dropdown a){
                    overflow: hidden;
                    position: relative;
                    color: var(--white);
                    text-transform: uppercase;
                }
                a{
                    display: block;
                    color: var(--white);
                    font-size: Max(rem(13), 10px);
                    font-weight: 400;
                    line-height: rem(18);
                    white-space: nowrap;
                    transition: color 0.3s ease-in-out;
                    @media(min-width: 769px){
                        &:hover{
                            color: var(--gold) !important;
                        }
                    }
                }
                &.has-child {
                    padding-bottom: rem(8);
                    margin-bottom: rem(-8);
                    
                     > a{
                        padding-right: rem(22) !important;
                        cursor: context-menu;
                        &::after{
                            content: '';
                            position: absolute;
                            right: 1px;
                            top: 50%;
                            transform: translateY(-50%);
                            width:  rem(15);
                            height: rem(15);
                            background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 7L7.5 10L10.5 7' stroke='white' stroke-linecap='square'/%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            pointer-events: none;
                            transition: transform 0.3s ease-in-out;
                        }
                    }
                    @media(min-width: 769px){
                        &:hover > a::after{
                            transform: rotate(180deg) translateY(50%);
                        }
                    }
                }                
            }
        }
    }
    &__dropdown{
        min-width: rem(128);
        text-align: left;
        position: absolute;
        left: 0;
        // overflow: hidden;
        opacity: 0;
        visibility: hidden;
        padding: rem(12) 0;
        border: rem(5) solid var(--gold);
        background: #E5E5E5;
        box-shadow: rem(4) rem(4) rem(16) 0px rgba(46, 85, 146, 0.30);

        top: unset;
        transition: opacity 0.3s ease-in-out, bottom 0.3s ease-in-out, visibility 0.3s ease-in-out;
        bottom: rem(20);
        transform: translateY(100%);
        &.header__dropdown--big{
            min-width: rem(295);
            .has-child a::after{
                background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"%3E%3Cpath d="M4.5 7L7.5 10L10.5 7" stroke="%23686974" stroke-linecap="square"/%3E%3C/svg%3E');
            }
            .header__dropdown{
                border-top: 0;
                left: rem(-5);
                box-shadow: unset;
                padding-left: rem(16);
                padding-top: rem(8);
                min-width: rem(295);
                a{
                    color: var(--gray); 
                }
            }
        }
        li{
            padding: 0;
            &:not(:last-child){
                margin-right: 0;
                margin-bottom: rem(16);
            }
            a{
                display: block;
                padding: 0 rem(16);
                font-size: Max(rem(14), 10px);
                color: var(--blue);
                color: var(--gray);
                font-weight: 400;
            }
            @media(min-width: 769px){
                &:hover{
                    a{
                        color: var(--gold);
                    }
                }
            }
        }
        .has-child{
            a{
                width: max-content;
                position: relative;
                max-width: 100%;
            }
        }
    }
    &__btns{
        display: flex;
        margin-left: rem(23);
    }
    .logo{
        flex-shrink: 0;
    }
    &.header--black{
        background-color: var(--blue);
    }

    @media(max-width: 768px){
        &__nav{
            &-list{
                padding: 0;
                li{
                    &.has-child > a{
                        padding-right: 10px;
                        &::after{
                            display: none;
                        }
                    }
                    a{
                        color: var(--blue) !important;
                    }
                }
            }
        }
        &__dropdown{
            opacity: 1;
            visibility: visible;
        }
        &__nav-list li.has-child {
            margin-bottom: 0;
        }
    }
}


.header__nav-list li.active > a::after{
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 7L7.5 10L10.5 7' stroke='%23C7B892' stroke-linecap='square'/%3E%3C/svg%3E");
}



  

  