.teams{
    padding-bottom: rem(40);
    position: relative;
    overflow: hidden;
    &.section-blur{
        margin-top: max(-20vh, rem(-108));
    }
    .section-blur__container{
        padding: rem(40) 0 rem(100);
    }
    &__bg{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: -1;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
        }
    }
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px rem(-78);
        >*{
            width: calc(100% / 2 - 30px);
            margin: 0 15px rem(78);
        }
        &.teams__container--big{
            margin: 0 -15px rem(-30);
            justify-content: center;
            >*{
                width: calc(100% / 5 - 30px);
                margin: 0 15px rem(30);
            }
            .teams-card{
                padding-left: 0;
                &__img{
                    width: 100%;
                    &::before{
                        padding-top: 123.9%;
                    }
                    &:hover .teams-card__block{
                        transform: translateY(rem(-24)) !important;
                    }
                }
                &__block{
                    flex-direction: column;
                    text-align: center;
                    align-items: center;
                    top: unset;
                    bottom: 0;
                    span{
                        color: var(--light) !important;
                        margin-bottom: 0 !important;
                        line-height: normal !important;
                    }
                }
                &__info{
                    margin-bottom: rem(8);
                }
                &__title{
                    font-size: rem(24);
                    line-height: rem(28);
                    color: var(--gold) !important; 
                    margin-bottom: rem(8) !important;
                }
                &__social{
                    width: rem(24);
                    height: rem(24);
                }
                &::before{
                    content: none;
                }
            }
        }
    }
    &-card{
        display: flex;
        align-items: flex-end;
        padding-left: rem(32);
        position: relative;
        &.teams-card--hover{
            .teams-card__title {
                margin-bottom: rem(25);
            }
            &:hover{
                .teams-card__title{
                    color: var(--blue);
                }
            }
            .teams-card__img:hover{
                transform: translateY(-10px);
                &:after{
                    top: 0;
                    opacity: 1;
                    visibility: visible;  
                }
                .teams-card__block{
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(-50%);
                }
            }
        }
        &:hover{
            .teams-card__title{
                color: var(--gold);
            }
        }
        &__img{
            width: rem(238);
            position: relative;
            margin-right: rem(30);
            flex-shrink: 0;
            overflow: hidden;
            transition: transform 0.3s ease;
            img:not(.teams-card__social img){
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &::before{
                content: '';
                display: block;
                width: 100%;
                padding-top: 140.3%;
            }
            &::after{
                position: absolute;
                content: "";
                top: 100%;
                left: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(180deg, rgba(13, 30, 55, 0.00) 0%, #0D1E37 100%);
                box-shadow: 4px 6px 12px 0px rgba(13, 30, 55, 0.30);
                transition: opacity 0.3s ease, top 0.3s ease, visibility 0.3s ease;
                z-index: 1;
                opacity: 0;
                visibility: hidden;
            }
        }
        &__title{
            color: var(--blue);
            font-family: 'Cormorant Garamond', serif;
            font-size: rem(48);
            font-weight: 700;
            line-height: rem(50); /* 104.167% */
            text-transform: uppercase;
            margin-bottom: rem(24);
            transition: color 0.3s ease-in-out;
        }
        &__block{
            display: flex;
            justify-content: center;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-20%);
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
        &__social{
            display: block;
            width: rem(40);
            height: rem(40);
            transition: transform 0.3s ease-in-out;
            &:hover{
                cursor: pointer;
            }
            &:not(:last-child){
                margin-bottom: rem(8);
            }
            >*{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        span{
            display: block;
            margin-bottom: rem(12);
            color: var(--blue);
            font-size: Max(rem(12), 10px);
            font-weight: 400;
            line-height: rem(24); 
        }
        .section-text{
            &:not(:last-child){
                margin-bottom: rem(32);
            }
            p{
                color: var(--blue);
            }
        }
    }
    &__bottom{
        margin-top: rem(128);
        text-align: center;
        .page-numbers{
            color: var(--blue);
            svg path{
                stroke: var(--blue)
            }
        }
    }
    &__title{
        margin-bottom: rem(8) !important;
        & >*{
            color: var(--gold);
        }
    }
    &__descr{
        margin-bottom: rem(40);
        >*{
            color: var(--blue);
            font-size: Max(rem(14), 10px);
            font-weight: 400;
            line-height: rem(24);
        }
    }
    &__form{
        display: flex;
        align-items: center;
        margin-bottom: rem(34);
        margin-top: 0 !important;
        width: 100% !important; 
        flex-direction: row !important;
        >*{
            &:not(:last-child){
                margin-right: rem(30);
            }
        }
        .btn{
            margin-top: auto;
            border-radius: rem(8);
            min-width: rem(170);
            text-transform: unset;
            min-height: rem(49);
        }
        .input{
            min-width: rem(270);
            &:not(:last-child){
                margin-bottom: 0 !important; 
            }
            input{
                border: 1px solid var(--gold);
                background-color: var(--white);
                color: var(--blue);
                &::placeholder{
                    color: var(--blue) !important;
                    opacity: 1;
                }
                &:checked + span {
                    color: var(--gold);
                }
            }
            .input__error{
                display: none;
            }
            & > label{
                margin-bottom: rem(8);
            }
            &__inner::after{
                background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"%3E%3Cpath d="M16 10L12 14L8 10" stroke="%230D1E37" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
            }
            &__dropdown{
                background-color: var(--white);
                li{
                    background-color: var(--white);
                    color: var(--blue);
                }
            }
            &.input--select{
                &:nth-child(4){
                    flex-grow: 1;
                }                
                &.has-checkbox{
                    .input__dropdown{
                        li{
                            padding: 0;
                        }
                    }
                    span{
                        padding: rem(8) rem(39) rem(8) rem(24);
                        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
                        &:hover{
                            background-color: var(--gold);
                            color: var(--white);
                        }
                    }
                    
                    input:checked + span:hover {
                        background-color: unset;
                        color: var(--gold);
                    }
                }  
            }
            &.input--checkbox{
                span{
                    &::after{
                        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"%3E%3Cpath d="M17.5 15.3333V5.66663V5.6639C17.5 4.7323 17.5 4.26621 17.3185 3.91003C17.1587 3.59643 16.9034 3.34144 16.5898 3.18166C16.2333 3 15.7669 3 14.8335 3H5.16683C4.23341 3 3.76635 3 3.40983 3.18166C3.09623 3.34144 2.84144 3.59643 2.68166 3.91003C2.5 4.26655 2.5 4.73321 2.5 5.66663V15.3333C2.5 16.2667 2.5 16.7335 2.68166 17.09C2.84144 17.4036 3.09623 17.6585 3.40983 17.8183C3.76635 18 4.23341 18 5.16683 18H14.8335C15.7669 18 16.2333 18 16.5898 17.8183C16.9034 17.6585 17.1587 17.4036 17.3185 17.09C17.5 16.7339 17.5 16.2676 17.5 15.336V15.3333Z" stroke="%230D1E37" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
                    }
                    &::before{
                        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"%3E%3Cpath d="M13.3336 8L8.88916 13L6.66667 10.5M17.5 5.66663V15.3333C17.5 16.2667 17.5002 16.7335 17.3185 17.09C17.1587 17.4036 16.9034 17.6585 16.5898 17.8183C16.2333 18 15.7669 18 14.8335 18H5.16683C4.23341 18 3.76635 18 3.40983 17.8183C3.09623 17.6585 2.84144 17.4036 2.68166 17.09C2.5 16.7335 2.5 16.2667 2.5 15.3333V5.66663C2.5 4.73321 2.5 4.26655 2.68166 3.91003C2.84144 3.59643 3.09623 3.34144 3.40983 3.18166C3.76635 3 4.23341 3 5.16683 3H14.8335C15.7669 3 16.2333 3 16.5898 3.18166C16.9034 3.34144 17.1587 3.59643 17.3185 3.91003C17.5002 4.26655 17.5 4.73321 17.5 5.66663Z" stroke="%23C7B892" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
                    }
                    &::after, &::before{
                        right: rem(15);
                    }
                }
            }
            &.is-error:not(:last-child) {
                margin-bottom: 0 !important;
            }
        }
    }
    &__input{
        &.is-disabled{
            pointer-events: none;
            opacity: 0.5;
        }
    }
    &__result{
        text-align: center;
        >*{
            font-size: rem(20);
            line-height: normal;
            color: var(--gold);
            text-transform: uppercase;
        }
    }

    @media(max-width: 1024px){
        &__container{
            &.teams__container--big{
                margin: 0 -15px rem(-30);
                >*{
                    width: calc(100% / 4 - 30px);
                    margin: 0 15px rem(30);
                }
            }
        }
    }
    @media(max-width: 768px){
        padding-bottom: 0;
        &.section-blur {
            margin-top: -90px !important;
        }
        &__container{
            margin: 0;
            >*{
                width: 100%;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 35px;
                }
            }
            &.teams__container--big{
                margin: 0 -10px rem(-20);
                >*{
                    width: calc(100% / 2 - 20px);
                    margin: 0 10px rem(20);
                    &:not(:last-child){
                        margin-bottom: 20px;
                    }
                }
                .teams-card{
                    padding: 0;
                    &__img{
                        margin-bottom: 0;
                    }
                    &__title{
                        font-size: 17px;
                        line-height: normal;
                        margin-bottom: 5px !important;
                    }
                    &__info{
                        margin-bottom: 14px;
                    }
                    span{
                        font-size: 12px;
                        line-height: normal;
                    }
                }
            }
        }
        &__bottom{
            margin-top: 50px;
        }
        &-card{
            padding-left: 0;
            flex-direction: column;
            align-items: flex-start;
            opacity: 0.7;
            background: #C9D2DB;
            box-shadow: 4px 6px 12px 0px rgba(13, 30, 55, 0.30);
            padding: 10px;
            &.teams-card--hover{
                .teams-card__title {
                    margin-bottom: 5px;
                }
            }
            &::before{
                content: none;
                width: 100%;
                height: 425px;
            }
            &__info{
                width: 100%;
                text-align: center;
            }
            &__img{
                width: 80%;
                margin: 0 auto;
                margin-bottom: 10px;
                max-height: 350px;
                overflow: hidden;
            }
            &__title{
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 16px;
            }
            .section-text:not(:last-child){
                margin-bottom: 16px;
            }
        }
        &__descr{
            margin-bottom: 20px;
        }
        &__form{
            flex-direction: column !important;
            .btn {
                margin-left: unset;
                min-width: unset;
                width: 100%;
            }
            .input{
                min-width: unset;
                width: 100%;
                &:not(:last-child) {
                    margin-bottom: 20px !important;
                    margin-right: 0;
                }
            }
        }
    }
}