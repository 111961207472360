.consult{
    padding: rem(94) 0;
    margin-bottom: rem(144);
    background-color: var(--blue);
    color: var(--white);
    position: relative;
    overflow: hidden;
    &__container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .form{
            z-index: 5;
        }
    }
    &__info{
        margin-right: rem(50);
        flex-grow: 1;
        .section-text > p{
            max-width: rem(494);
        }

    }
    &__bg{
        position: absolute;
        left: var(--container-margin);
        right: 0;
        top: 0;
        bottom: 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left;
            opacity: 0.5;
        }
    }
    &__subtitle{
        color: var(--white);
        font-size: rem(40);
        font-weight: 700;
        line-height: rem(48); /* 120% */
        text-transform: uppercase;
        margin-top: rem(80);
        margin-bottom: rem(56);
        font-family: 'Cormorant Garamond', serif;
    }
    &.consult-us__map{
        height: rem(450);
        iframe{
            width: 100%;
            height: 100%;
        }
    }
        
    
    @media(max-width: 768px){
        padding: 50px 0;
        margin-bottom: 80px;
        &__container{
            flex-direction: column;
        }
        &__info{
            margin-right: 0;
            margin-bottom: 35px;
        }
    }
}

  