.career{
    position: relative;
    overflow: hidden;
    background-color: var(--blue);
    padding: rem(184) 0;
    margin-bottom: rem(184);
    &__inner{
        padding: rem(80) rem(40);
        display: flex;
        flex-direction: column;
        background-color: #1A2A43;
        color: var(--white);
        .logo{
            margin: 0 auto rem(24);
            width: rem(230);
            height: rem(70);
        }
        .title-h3 > *{
            font-size: rem(40);
        }
    }
    &__subtitle{
        margin-bottom: rem(24);
        text-align: center;
    }
    &__list{
        margin-bottom: rem(20);
        font-size: Max(rem(14), 10px);
        font-weight: 400;
        line-height: rem(24);
    }
    &__block{
        margin-top: rem(20);
        .title-h4{
            margin-bottom: rem(20);
            >*{
                font-size: rem(24);
                font-weight: 700;
                line-height: rem(24);
            }
        }
        &-item{
            ol{
                margin-left: rem(20);
                li:not(:last-child){
                    margin-bottom: rem(8);
                }
            }
            &:not(:last-child){
                margin-bottom: rem(40);
            }
        }
    }
    .section-text p{
        color: var(--white);
    }
    &__link{
        margin-top: rem(35);
        a{
            color: var(--white);
        }
    }
    .swiper-pagination-bullet {
        width: rem(20);
        height: rem(20);
        opacity: 1;
        background-color: var(--gray);
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }
  
    .swiper-pagination-bullet-active {
        background-color: var(--white);
    }
    .swiper-pagination-bullets{
        bottom: 0;
    }
    .swiper {
        padding-bottom: 70px;
    }
    @media(max-width: 768px){
        padding: 50px 0;
        margin-bottom: 100px;
        &__inner{
            padding: 30px 15px;
            .title-h3 > *{
                font-size: 24px;
            }
        }
        .career__block .title-h4 {
            margin-bottom: 14px;
            >*{
                font-size: 20px;
            }
            
        }
        .career__block-item:not(:last-child) {
            margin-bottom: 30px;
        }
    }

}