// если вы хотите использовать sass-переменные - удалите root
// colors
:root {

  // colors
  --white: #fff;
  --black: #000;



  --gold: #C7B892;
  --blue: #0D1E37;
  --light: #CDD3DB;
  --gray: #686974;
  --brown: #A9996A;
  --light-opacity: #A1BEDB38;

  --container-margin: calc((100vw - 1170px) / 2);

}

@media (max-width: 1280px){
  :root{
    --container-margin: calc((100vw - 1020px) / 2);
  }
}

@media (max-width: 1080px){
  :root{
    --container-margin: calc((100vw - 945px) / 2);
  }
}

@media (max-width: 991px){
  :root{
    --container-margin: calc((100vw - 720px) / 2);
  }
}