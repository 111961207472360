.planning{
    padding: rem(144) 0;
    position: relative;
    overflow: hidden;
    .logo-bg{
        right: unset;
        left: 0;
        >*{
            object-position: left;
        }
    }
    &__container{
        position: relative;
        display: flex;
        align-items: flex-start;
        padding-top: rem(80);
        background-color: var(--light-opacity);
        padding-left: rem(40);
        padding-right: rem(66);
        &::before{
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: var(--blue);
            width: 31.7%;
        }
        .logo-bg{
            top: rem(46);
            left: rem(30);
            bottom: rem(80);
            right: unset;
            width: 78%;
            transform: translateY(0);
            >*{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    &__info{
        margin-right: rem(30);
        padding-bottom: rem(80);
        width: 49.8%;
        flex-shrink: 0;
        .section-text {
            padding-left: rem(25);
            p{
                color: var(--gray);
            }
            
        }
    }
    &__title{
        >*{
            color: var(--blue);
        }
    }
    &__img{
        position: relative;
        width: 100%;
        border-bottom: rem(7) solid #B8CEE2;
        overflow: hidden;
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 119.8%;
        }
        &::after{
            border: 5px solid #0D1E37;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42));
            z-index: 1;
        }
    }

    &-list{
        margin-top: rem(48);
        max-width: rem(470);
        background-color: var(--blue);
        border-right: rem(6) solid var(--gold);
        padding: rem(32) rem(59);
        margin-left: rem(-40);
        &__inner{
            display: flex;
            align-items: center;
        }
        &__img{
            flex-shrink: 0;
            width: rem(237);
            height: rem(144);
            position: relative;
            margin-right: rem(16);
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &::after{
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(#000, 0.4);
            }
        } 
        ul{
            width: max-content;
            li{
                display: flex;
                align-items: center;
                color: var(--light);
                font-size: Max(rem(14), 10px);
                line-height: rem(24);
                text-transform: uppercase;
                &:not(:last-child){
                    padding-bottom: rem(16);
                    margin-bottom: rem(16);
                    border-bottom: 1px dashed var(--gold);
                }
            }
        }
        &.planning-list--offset{
            .section-text{
                padding-left: 0;
                p{
                    color: var(--light);
                }
            }
        }
    }


    @media(max-width: 768px){
        padding: 40px 0;
        background-color: var(--light-opacity);
        border-top: 3px solid var(--gold);
        &__container{
            padding: 0;
            background-color: unset;
            &::before{
                content: none;
            }
        }
        &__info{
            margin-right: 0;
            padding-bottom: 0;
            width: 100%;
            .section-text{
                padding-left: 16px;
                border-left: 1px dashed var(--gold);
            }
        }
        &__img{
            border-bottom: 4px solid var(--gold);
            margin-bottom: 24px;
            max-height: 450px;
            &::before {
                padding-top: 117.2%;
            }
            &::after{
                border: unset;
            }
        }
        &-list{
            margin-top: 24px;
            margin-left: 0;
            border-left: rem(6) solid var(--gold);
            border-right: unset;
            padding: 34px ;
            &__inner{
                flex-direction: column;
            }
            &__img{
                width: 100%;
                height: 190px;
                margin-right: 0;
                margin-bottom: 20px;
            }
            &--offset{
                .section-text{
                    border-left: unset;
                }
            }
            ul{
                li{
                    &:not(:last-child){
                        margin-bottom: 16px;
                    }
                }
            }
        }
        .logo-bg{
            left: 50%;
            transform: translate(-50%, -25%);
            width: 86.5%;
            height: 36%;
            >*{
                object-position: center;
            }
        }
    }   
}

