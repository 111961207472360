
.card-slider{
    .swiper-slide{
        counter-increment: number;
        height: auto;        
    }
    .swiper-button-disabled {
        opacity: 0.3;
        pointer-events: none;
    }
    .section-slider__buttons{
        margin-top: rem(20);
    }
    @media(max-width: 768px){
        .swiper-slide:not(:first-child){
            &.scroll-appearance.animate-top, &.scroll-appearance.animate-bottom{
                opacity: 1;
                transform: translateY(0);
            }
        }
        .section-slider__buttons{
            padding: 0 2px;
        }
    }
}

.card{
    border-radius: rem(8);
    background-color: var(--white);
    padding: rem(60) rem(24) rem(24);
    position: relative;
    transition: background-color 0.3s ease-in-out;
    height: 100%;
    display: flex;
    flex-direction: column;

    &::after{
        position: absolute;
        left: rem(24);
        display: block;
        top: 0;
        content: counter(number);
        color: var(--brown);
        font-family: 'Abhaya Libre', serif;
        font-size: rem(60);
        font-weight: 500;
        line-height: rem(72);
        text-transform: uppercase;
    }
    &:hover{
        background-color:  #E1DCCB;
        cursor: pointer;
        .section-text{
            p{
                color: var(--blue);
            }
        }
    }
    .section-text{
        margin-top: rem(22);
        p{
            color: var(--gray);
            font-size: Max(rem(14),10px);
        }
    }
    &-container{
        margin: 0 -4px;
        display: flex;
        flex-wrap: wrap;
        >*{
            width: calc(100% / 5 - 8px);
            margin: 0 4px;
        }
        .card{
            height: auto;
            counter-increment: number;
        }
    }
    &-items{
        &.section-blur{
            top: rem(-110);
            margin-bottom: rem(74);
        }
        .section-blur__container{
            padding: rem(32) 0;
        }
    }
    
    @media(max-width: 768px){
        width: 100%;
        max-width: 70%;
        margin: 0 auto;
        padding-top: 75px;
        &:not(:last-child){
            margin-bottom: 16px;
        }
        &__container {
            margin: 0;
            flex-direction: column;
        }
        &-items{
            &.section-blur{
                top: -80px;
                margin-bottom: 0;
            }
        }
  
    }
}