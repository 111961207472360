.services{
    background-color: var(--blue);
    padding: rem(50) 0 rem(144) 0;
    overflow: hidden;
    & + .consult{
        margin-top: rem(183);
    }
    &__title {
        margin-bottom: rem(56);
        >*{
            color: var(--white);
        }
    }
    &__tabs{
        display: flex;
        position: relative;
        border-bottom: 1px solid #2E5592;
        >*{
            margin-bottom: -3px;         
        }
    }
    &__tab{
        color: var(--light);
        line-height: rem(25);
        transition: color 0.3s ease-in-out, border-bottom-color 0.3s ease-in-out;
        font-size: rem(20);
        font-weight: 400;
        border-bottom: 5px solid transparent;
        padding: 0 rem(10) rem(24);
        flex-grow: 1;
        max-width: rem(300);
        &:hover{
            background-color: var(--white-bg);
            color: var(--gold);
        }
        &.active{
            color: var(--gold);
            border-bottom-color:  var(--gold);
        }
    }

    &__content{
        margin-top: rem(40);
    }
    &__wrapper{
        &:not(:first-child){
            display: none;
        }
    }
    &__info{
        display: flex;
        align-items: flex-start;
        &-img{
            width: rem(570);
            position: relative;
            flex-shrink: 0;
            margin-left: rem(30);
            >*{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &::after{
                position: absolute;left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                content: '';
                background-color: rgba(0, 0, 0, 0.42);
                pointer-events: none;
            }
            &::before{
                content: '';
                display: block;
                width: 100%;
                padding-top: 61.3%;
            }
        }
        &-title{
            margin-bottom: rem(24);
        }
        &-descr{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .section-text{
            margin-bottom: rem(20);
        }
        .section-link{
            margin-top: auto;
        }
    }

    @media(max-width: 768px){
        padding: 40px 0;
        & + .consult{
            margin-top: 80px;
        }
        &__title{
            margin-bottom: 20px;
        }
        &__content{
            margin-top: 26px;
        }
        &__info {
            flex-direction: column-reverse;
            &-img{
                width: 100%;
                margin-left: 0;
                margin-bottom: 24px;
                max-height: 300px;
                overflow: hidden;
            }
        }
        &__tabs{
            overflow-y: auto;
            border-bottom: unset;
            >*{
                margin-bottom: 13px;
            }
        }
        &__tab{
            font-size: 18px;
            font-weight: 600;
            line-height: 23px;
            padding: 0;
            max-width: unset;
            flex-shrink: 0;
            padding-bottom: 6px;
            border-bottom: 3px solid transparent;

            &:not(:last-child){
                margin-right: 24px;
            }
        }
        .services__info-title {
            margin-bottom: 15px;
        }
        
        .services__info .section-text {
            margin-bottom: 15px;
        }
        
        
        .services__info-img::before {
            padding-top: 66.4%;
        }
  
  
    }
}

  