.what-is{
    padding-top: rem(144);
    background-color: var(--blue);
    & + .section-blur.card-items{
        top: 0;
    }
    &__content{
        overflow: hidden;
        margin-bottom: rem(-68);
    }
    &__block{
        margin-bottom: rem(144);
    }
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px rem(-30);
        >*{
            width: calc(100% / 3 - 30px);
            margin: 0 15px rem(30);
        }
        &.what-is__container--similiar{
            >*{
                width: calc(100% / 4 - 30px);
            }
            .what-is__card{
                border: rem(5) solid var(--gold);
                background-color: #E5E5E5;
                box-shadow: 4px 4px 16px 0px rgba(46, 85, 146, 0.30);
                display: flex;
                align-items: center;
                padding: rem(43) rem(33) rem(43) rem(40);
                border-radius: 0;
                &-img{
                    margin-right: rem(16);
                    margin-bottom: 0;
                }
                .section-text p{
                    color: var(--gray);
                }
            }
        }
    }
    &__title{
        max-width: rem(578);
        flex-shrink: 0;
        margin-right: rem(38);
        margin-bottom: 0 !important;
    }
    &__info{
        display: flex;
        margin-bottom: rem(56);
        .section-text{
            margin-bottom: rem(20);
        }
    }
    &__card{
        padding: rem(24);
        border-radius: rem(6);
        background: #1A2A43;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.16);
        &-img{
            width: rem(48);
            height: rem(48);
            flex-shrink: 0;
            margin-bottom: rem(16);
            >*{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    &__about{
        position: relative;
        text-align: center;
        padding: rem(94) rem(98) rem(80);
        &-bg{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            >*{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .what-is__title{
            max-width: unset;
            margin-right: 0;
            margin-bottom: rem(32) !important;
        }
    }
    &__cards{
        position: relative;
        z-index: 2;
        bottom: rem(-68);
        &::before{
            content: '';
            position: absolute;
            top: rem(76);
            background-color: var(--light);
            left: 0;
            right: 0;
            bottom: rem(68);
        }
        .what-is__container{
            margin-bottom: 0;
        }
    }
    .block-single{
        display: flex;
        align-items: center;
        >*{
            max-width: rem(570);
            width: 100%;
        }
        .what-is__info{
            flex-direction: column;
            margin-right: rem(30);
        }
        .what-is__title {
            margin-right: 0;
            margin-bottom: rem(40) !important;
            max-width: unset;
        }
    }
    &__img{
        position: relative;
        overflow: hidden;
        >*{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 95.6%;
        }
    }

    @media(max-width: 768px){
        padding: 40px 0;
        &__info{
            flex-direction: column;
            margin-bottom: 24px;
        }
        &__block{
            margin-bottom: 0;
            border-bottom: 3px solid var(--gold);
            padding-bottom: 43px;
        }
        &__title{
            max-width: unset;
            margin-right: 0;
            margin-bottom: 24px !important;
        }
        .section-text:not(.what-is__card .section-text){
            padding-left: 16px;
            border-left: 1px dashed var(--gold);
        }
        &__container{
            margin: 0;
            >*{
                width: 100%;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 24px;
                }
            }
            &.what-is__container--similiar{
                >*{
                    width: 100%;
                }
                .what-is__card{
                    padding: 31px 40px 31px 40px;
                }
            }
        }
        &__about{
            text-align: left;
            padding: 40px 0 0;
            .what-is__title{
                margin-bottom: 24px !important;
            }
            .section-text-wrap{
                margin-bottom: 40px;
            }
        }
        &__cards{
            &::before {
                top: 56px;
                bottom: -40px;
            }
            bottom: 0;
            margin-top: 45px;
        }
        &__img{
            max-height: 450px;
            
        }
        .block-single{
            flex-direction: column;
            >*{
                max-width: unset;
            }
            .what-is__info{
                margin-right: 0;
            }
        }
    }
}


  