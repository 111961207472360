.footer{
    background-color: var(--blue);
    padding: rem(32) 0;
    position: relative;
    .scroll-top{
        position: fixed;
        right: rem(20);
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
        svg{
            transform: rotate(180deg);
        }
        &.active{
            opacity: 1;
            visibility: visible;
        }
    }
    .logo{
        margin-right: rem(65);
    }
    address{
        margin-bottom: rem(32);
    }
    &__container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >*{
            color: var(--light);
            font-size: Max(rem(14),10px);
            font-weight: 400;
            line-height: rem(18);
        }
    }
    &__info{
        display: flex;
        flex-direction: column;
        width: 100%;
        border-left: 1px dashed var(--gold);
        padding: rem(20) 0 rem(20) rem(65);
    }
    &-block{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: rem(74);
    }
    &__nav{
        li{
            &:not(:last-child){
                margin-bottom: rem(24);
            }
        }
        a{
            color: var(--light);
            transition: color 0.3s ease-in-out;
            &:hover{
                color: var(--gold);
            }
        }
    }
    @media(max-width: 768px){

        padding: 40px 0 0;
        .logo{
            margin-right: 0;
            margin-bottom: 40px;
        }
        .footer__container {
            flex-direction: column;
        }
        &-block{
            margin-bottom: 30px;
            flex-direction: column;
            &__item{
                width: 100%;
                &:not(:last-child){
                    margin-bottom: 40px;
                }
            }
            .btn{
                width: 100%;
                max-width: 450px;
            }
        }
        &__info{
            padding-left: 0;
            padding: 40px 0 24px 0;
            text-align: center;
            border-left: unset;
            border-top: 1px dashed var(--gold);
        }
        &__copy{
            margin-top: 60px;
        }
    }
}



  

  

  
  