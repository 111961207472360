.pictures{
    padding: rem(90) rem(120);
    position: relative;
    overflow: hidden;
    &__bg{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        >*{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__slide{
        position: relative;
        border: rem(5) solid transparent;
        
        transition: border-color .3s ease-in-out, box-shadow .3s ease-in-out;
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 60%;   
        }
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            opacity: 0.42;
            transition: opacity .3s ease-in-out;
            background-color: var(--black);
        }
    }
    .section-slider__buttons{
        margin-top: rem(56);
    }
    .container{
        overflow: hidden;
    }
    .swiper {
        overflow: visible;
        width: 39.8%;
        &-slide{
            &-active{
                .pictures__slide{
                    border-color: #C7B892;
                    box-shadow: 12px 12px 20px 0px rgba(11, 21, 32, 0.35);
                    &::after{
                        opacity: 0;
                    }
                }
            }
        }
    }
  

    @media (max-width: 768px){
        padding: 60px 0;
        .swiper {
            width: 100%;
            max-width: 400px;
        }
        
        .container {
            overflow: visible;
        }
        .section-slider__buttons {
            margin-top: 30px;
        }
  
    }
}