.category{
    background-color: var(--blue);
    padding-top: rem(144);
    overflow: hidden;
    & + .what-is .what-is__about .section-text{
        max-width: rem(660);
        margin: 0 auto; 
    }
    &__container{
        display: flex;
    }
    &__wrapper{
        max-width: rem(670);
        margin-right: rem(30);
        flex-shrink: 0;
    }
    &__info{
        .section-text{
            margin-bottom: rem(56);
        }
    }
    &-card{
        border-radius: rem(6);
        background: #1A2A43;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.16);
        padding: rem(24);
        position: relative;
        counter-increment: number;
        &:not(:last-child){
            margin-bottom: rem(22);
        }
        &__title{
            color: var(--white);
            font-size: rem(20);
            font-weight: 400;
            line-height: rem(25);
            margin-bottom: rem(16);
            position: relative;
            padding-left: rem(40);
            &::after{
                position: absolute;
                left: 0;
                top: 0;
                content: counter(number);
                color: var(--white);
                font-size: rem(20);
                font-weight: 400;
            }
        }
    }

    @media(max-width: 768px){

        padding-top: 40px;
        .category__container {
            flex-direction: column;
        }

        .category__wrapper {
            max-width: unset;
            margin-right: 0;
            margin-bottom: 35px;
        }

        .category__info .section-text {
            margin-bottom: 30px;
        }
  
    }
}