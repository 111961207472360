.form{
    width: rem(570);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    margin-top: rem(50);
    .btn{
        margin-left: auto;
        min-width: rem(143);
    }
    .input{
        &:not(:last-child){
            margin-bottom: rem(16);
        }
        input[type="tel"]::placeholder{
            opacity: 1;
        }
    }

    @media(max-width: 768px){
        width: 100%;
        margin-top: 0;
    }
}