.section{
    &-text{
        &>*:not(:first-child){
            margin-top: rem(30);
        }
        p{
            color: var(--light);
            font-size: Max(rem(14), 10px);
            font-weight: 400;
            line-height: rem(24);
            transition: color 0.3s ease-in-out;
        }
        &--big{
            p{
                font-size: Max(rem(16), 10px);
                color: var(--white);
                font-size: rem(20);
                line-height: 150%;
            }
        }
        ul{
            li{
                position: relative;
                color: var(--light);
                font-size: Max(rem(14), 10px);
                line-height: 150%;
                padding-left: rem(20);
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: rem(10);
                    width: rem(8);
                    height: rem(8);
                    border-radius: 100%;
                    background-color: var(--light);
                }
            }
        }
        // ol{
        //     list-style-type: none;
        //     li{
        //         color: var(--light);
        //         font-size: Max(rem(14), 10px);
        //         line-height: 150%;
        //         counter-increment: number;
        //         position: relative;
        //         padding-left: rem(57);
        //         &::after{
        //             content: counter(number, decimal-leading-zero);
        //             position: absolute;
        //             left: 0;
        //             top: 50%;
        //             transform: translateY(-50%);
        //             color: var(--blue);
        //             font-size: rem(32);
        //             font-family: 'e-UkraineHead' , sans-serif;
        //             font-weight: 500;
        //             line-height: 120%;
        //         }
        //         &:not(:last-child){
        //             margin-bottom: rem(28);
        //         }
        //     }
        // }
        &-hide{
            display: none;
        }
        &.section-text--border{
            padding-left: rem(24);
            border-left: 1px dashed var(--gold);
        }
    }
    &-link{
        display: flex;
        align-items: center;
        z-index: 1;
        position: relative;
        background-color: var(--gold);
        padding: rem(8) rem(24);
        padding-right: rem(31);
        transition: background-color 0.3s ease-in-out;
        // &:not(:hover) .section-link__img {
        //     transform: rotate(-90deg);
        // }
        &:hover{
            background-color: var(--brown);
        }
        span{
            display: block;
            margin-right: rem(22);
            color: var(--white);
            font-size: rem(20);
            line-height: rem(18);
            font-size: Max(rem(14), 10px);
            font-weight: 400;
            text-transform: uppercase;
        }
        &__img{
            width: rem(28);
            height: rem(28);
            border-radius: rem(52);
            border: 1px solid var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
            >*{
                width: rem(18);
                height: rem(18);
                object-fit: contain;
                z-index: 1;
                transition: transform 0.3s ease-in-out;
                path{
                    fill: var(--white);
                }
            }
        }
        &.is-show{
            .section-link__img>*{
                transform: rotate(-85deg);
            }
        }
    }
    &-arrow{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #C7B892;
        background-color: rgba(199, 184, 146, 0.30);
        width: rem(62);
        height: rem(62);
        transition: opacity 0.3s ease-in-out;
        &:hover{
            opacity: 0.7;
        }
        svg{
            width: rem(24);
            height: rem(24);
            object-fit: contain;
        }
    }
    &-blur{
        background: rgba(255, 255, 255, 0.20);
        border-radius: 8px;
        margin-top: rem(-94);
        backdrop-filter: blur(10px);
        position: relative;
        &__container{
            padding: rem(44) 0;
            .title-h1:not(.teams__title)>*{
                font-size: rem(32);
                line-height: normal;
            }
        }
    }
    &-slider__buttons{
        display: flex;
        align-items: center;
        justify-content: center;
        &.buttons--dark .section-slider__prev,
        &.buttons--dark .section-slider__next{
            border: 1px solid #E5E5E5;
            background-color: var(--blue);
            svg path{
                stroke: #E5E5E5;
            }
            &:hover{
                background-color: #E5E5E5;
                svg path{
                    stroke: var(--blue);
                }
            }
        }
    }
    &-slider__prev,&-slider__next{
        width: rem(101);
        border: 1px solid var(--blue);
        background-color: var(--light);
        flex-shrink: 0;
        padding: rem(6) rem(16);
        display: flex;
        transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
        height: rem(33);
        align-items: center;
        svg{
            position: relative;
            width: rem(16);
            height: rem(16);
            object-fit: contain;
            path{
                transition: stroke 0.3s ease-in-out;
            }
        }

        &:hover{
            border-color: var(--gold);
            svg path{
                stroke: var(--gold);
            }
        }
        &.swiper-button-disabled{
            pointer-events: none;
            opacity: 0.6;
        }
    }
    &-slider__next{
        justify-content: flex-end;
    }
    &-head{
        display: flex;
        margin-bottom: rem(56);
        .title-h1{
            max-width: rem(670);
            width: 100%;
            flex-shrink: 0;
            margin-right: rem(30);
            margin-bottom: 0;
        }
    }
    @media(max-width: 768px){
        &-text{
            &>*:not(:first-child){
                margin-top: 15px;
            }
            p{
                font-size: 14px;
            }
            &--big{
                p{
                    font-size: 16px;
                }
            }
            // ul{
            //     li{
            //         font-size: 14px;
            //         &:not(:last-child){
            //             margin-bottom: 5px;
            //         }
            //     }
            // }
            // ol{
            //     li{
            //         padding-left: 45px;
            //         font-size: 14px;
            //         &:not(:last-child){
            //             margin-bottom: 15px;
            //         }
            //         &::after{
            //             font-size: 25px;
            //         }
            //     }
            // }
        }
        &-blur{
            overflow: hidden;
            background-color: unset;
            &__container{
                padding: 40px 0 0;
            }
            .swiper {
                overflow: visible;
                width: 70%;
            }
            .card {
                max-width: unset;
            }
        }
        &-slider__prev,&-slider__next{
            width: 50%;
        }
        &-arrow{
            width: 45px;
            height: 45px;
        }
        &-head{
            flex-direction: column;
            margin-bottom: 30px;
            .title-h1{
                margin-right: 0;
                margin-bottom: 16px;
            }
        }
    }

}





  