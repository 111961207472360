.file{
    background-color: gray;
    padding: rem(15) 0;
    .container{
        background-color: var(--white);
        max-width: rem(1120);   
    }
    &__block{
        &.file__block--offset{
            padding-top: rem(60);
        }
        &:not(:last-child){
            margin-bottom: rem(15);
        }
    }
    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 3px solid var(--blue);
        padding: rem(20) 0;
        margin-bottom: rem(30);
        address{
            color: var(--blue);
            text-align: right;
        }
    }
    &__footer{
        background-color: var(--blue);
        padding: rem(15) 0;
        margin: 0 rem(-30);
        color: var(--white);
        text-align: right;
        address{
            text-transform: uppercase;
            text-align: center;
        }
        span{
            display: block;
            margin-right: rem(40);
        }
    }
    &__logo{
        width: rem(250);
        height: rem(100);
        >*{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .logo{
        width: rem(300);
        height: rem(100);
    }
    &__container{
        max-width: rem(900);
        margin: 0 auto rem(70);
    }
    &__title{
        text-align: center;
        margin-bottom: rem(20);
        >*{
            font-size: rem(25);
            line-height: rem(40);
            font-weight: 600;
        }
        &.file__title{
            &--small{
                text-align: left;
                >*{
                    font-size: rem(18);
                    line-height: rem(22);
                    font-weight: 600;
                }
            }
            &--line >*{
                text-transform: uppercase;
                text-decoration: underline;
            }
        }
        
    }
    &__title--bg{
        background-color: var(--blue);
        color: var(--white);
        text-transform: uppercase;
        text-align: center;
        margin-bottom: rem(30);
        >*{
            font-size: rem(25);
            line-height: rem(40);
        }
    }
    .section-text{
        > :not(:first-child) {
            margin-top: rem(30);
        }
        p{
            font-size: Max(rem(16), 10px);
            line-height: 170%;
        }
        .yellow{
            background-color: yellow;
        }
        &:not(:last-child){
            margin-bottom: rem(35);
        }
    }
    &__item{
        &:not(:last-child){
            margin-bottom: rem(40);
        }
    }
    &__list{
        &:not(:last-child){
            margin-bottom: rem(40);
        }
        span{
            font-size: rem(18);
            line-height: rem(24);
            font-weight: 500;
            display: block;
            margin-bottom: rem(15);
        }
    }
    &__caption{
        font-size: Max(rem(13),10px);
        font-weight: 600;
        &-item{
            &:first-child{
                width: 69%;
            }
            span{
                display: block;
            }
            &:not(:last-child){
                margin-right: rem(40);
            }
        }
        .input-x input{
            width: 100%;
        }
    }
    &__row{
        display: flex;
    }
    ul{
        counter-reset: listStyle;
        li{
            counter-increment: listStyle;
            position: relative;
            &:not(:last-child){
                margin-bottom: rem(20);
            }
            &::before{
                position: absolute;
                left: rem(-40);
                top: 0;
                content: counter(listStyle, upper-alpha)  ". ";
            }
        }
        a{
            color: var(--black);
            text-decoration: underline;
            font-weight: 500;
        }
    }
    ol{
        &:not(:last-child){
            margin-bottom: rem(50);
        }
        li{
            font-size: Max(rem(14), 10px);
            font-weight: 400;
            line-height: rem(25);
            &:not(:last-child){
                margin-bottom: rem(4);
            }
        }
    }
}

.company{
    &__head{
        border-bottom: 1px solid var(--blue);
        margin-bottom: rem(20);
        padding-bottom: rem(10);
    }
    &__form{
        margin-bottom: rem(30);
        &-row{
            display: flex;
            align-items: center;
            &:not(:last-child){
                margin-bottom: rem(20);
            }
            span{
                display: block;
                margin-right: rem(10);
            }
        }
        .line{
            width: 100%;
            margin-right: rem(5);
        }
    }
    &__referral{
        padding: rem(20);
        margin-top: rem(30);
        background-color: #E3E8FF;
        border-radius: rem(20);
        .company__form-row{
            span{
                flex-shrink: 0;
            }
            .line{
                margin-right: rem(10);
                flex-grow: unset;
            }
        }
    }
    address{
        font-size: Max(rem(15),10px);
        max-width: 500px;
        margin: 0 auto;
        text-align: center;
        font-weight: 500;
        span{
            color: var(--blue);
        }
    }
    .file__title{
        margin: rem(35) auto;
        max-width: rem(800);
        >*{
            font-size: rem(22);
        }
    }
}
.input-list{
    display: flex;
    flex-direction: column;
}
.input-x{
    position: relative;
    padding-left: rem(20);
    &::after{
        content: 'X';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        color: red;
    }
}
.line{
    min-height: rem(28);
    flex-grow: 1;
    background-color: #B2D8F9;;
    border-bottom: 2px solid var(--blue);
    font-size: Max(rem(14), 10px);
    line-height: rem(20);
    &.line--color{
        background-color: yellow;
    }
    &.line--small{
        width: rem(80);
    }
    &:not(:last-child){
        margin-bottom: 3px;
    }
}
.columns{
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 14px;
    &__block{
        width: 100%;
        &-width{
            .line{
                margin-left: 0 !important;
                width: 100% !important;
            }
        }
        &:not(:last-child){
            margin-right: rem(50);
            text-align: right;
        }
        >span{
            font-weight: 500;
            display: block;
            margin-bottom: rem(10);
        }
    }
    &__row{
        display: flex;
        align-items: center;
        .line{
            width: 20%;
            flex-grow: unset;
            margin-left: auto;
        }
        &:not(:last-child){
            margin-bottom: 3px;
        }
    }
    &__name{
        margin: 0 rem(15);
        flex-shrink: 0;
    }
}
input[type="checkbox"]{
    cursor: pointer;
}
.radio{
    input{
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
    }
    span{
        position: relative;
        padding-right: rem(20);
        &::after{
            content: "\2713";
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            position: absolute;
            right: rem(2);
            top: 50%;
            transform: translateY(-50%);
        }
        &::before{
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: rem(16);
            height: rem(16);
            border: 1px solid var(--blue);
        }
    }
    input[type=radio]:checked +  span::after {
        opacity: 1;
    }
}
