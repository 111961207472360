.contact-us{
    padding-bottom: rem(144);
    overflow: hidden;
    &__container{
        display: flex;
    }
    &__map{
        max-width: rem(600);
        width: 100%;
        >*{
            height: 100%;
        }
    }
    &__inner{
        background-color: #C9D2DB;
        padding: rem(80) rem(30);
        width: 100%;
        .title-h1>*{
            color: var(--blue);
        }
    }
    &__info{
        border-left: 1px dashed var(--gold);
        padding-left: rem(24);
    }
    &__block{
        &:not(:last-child){
            margin-bottom: rem(40);
        }
        span{
            color: var(--gray);
            font-size: rem(20);
            font-weight: 600;
            line-height: rem(24); /* 120% */
            margin-bottom: rem(24);
            display: block;
        }
        svg, img{
            width: rem(24);
            height: rem(24);
            object-fit: contain;
            margin-right: rem(12);
        }
        .socials{
            svg path{
                fill: var(--gray);
            }
        }
    }
    &__row{
        display: flex;
        align-items: center;
        color: var(--gray);
        font-size: Max(rem(14), 10px);
        font-weight: 400;
        line-height: rem(24);
        &:not(:last-child){
            margin-bottom: rem(15);
        }
        a{
            color: var(--gray);  
        }
    }

    @media(max-width: 768px){
        padding-bottom: 50px;
        &__container{
            flex-direction: column-reverse;
        }
        &__inner{
            padding: 50px 20px;
            .socials{
                justify-content: left;
            }
        }
        &__info{
            padding-left: 12px;
        }
        &__block{
            span{
                margin-bottom: 12px;
            }
            &:not(:last-child){
                margin-bottom: 20px;
            }
        }
        &__map{
            margin-top: 40px;
            max-width: unset;
            height: 450px;
            >*{
                width: 100%;
            }
        }
    }   
}

  