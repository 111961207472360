.tax{
    padding-top: rem(144);
    background-color: var(--blue);
    padding-bottom: rem(426);
    overflow: hidden;
    &-section{
        overflow: hidden;
        padding-top: rem(144);
        padding-bottom: rem(144);
    }
    &__bottom{
        position: relative;
    }
    &__bg{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: rem(90);
        >*{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__cards{
        background-color: #E5E5E5;
        &-container{
            top: rem(-240);
            position: relative;
            display: flex;
            margin: 0 -15px;
            >*{
                width: calc(100% / 2 - 30px);
                margin: 0 15px;
            }
        }
    }
    &-card{
        position: relative;
        padding: rem(60) rem(40);
        background-color: #C9D2DB;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        &__logo{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            >*{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &__title{
            color: var(--blue);
            font-family: 'Cormorant Garamond', serif;
            font-size: rem(40);
            font-weight: 700;
            line-height: rem(48); /* 120% */
            text-transform: uppercase;
            margin-bottom: rem(24);
        }
        .section-text {
            margin-bottom: rem(32);
            p{
                color: var(--gray);
            }            
        }
        &.tax-card--white{
            background-color: var(--white);
        }
    }
    @media(max-width: 768px){
        padding-top: 40px;
        padding-bottom: 80px;
        &__cards-container {
            padding: 40px 0;
            top: 0;
            margin: 0;
            flex-direction: column;
            >*{
                width: 100%;
                margin: 0;
                margin-bottom: 25px;
            }
        }
        &-card{
            padding: 20px;
            &__title{
                font-size: 22px;
                line-height: 26px;
                margin-bottom: 16px;
            }
        }
        &-section{
            padding: 80px 0;
        }
    }
}