.events{
    padding: rem(144) 0;
    background-color: var(--blue);
    position: relative;

    &__title{
        margin-bottom: rem(40);
    }
    &__tabs{
        margin: 0 -8px;
        >*{
            margin: 0 8px;
        }
    }
    &__content{
        margin-top: rem(40);
    }
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px rem(-30);
        >*{
            width: calc(100% / 3 - 30px);
            margin: 0 15px rem(30);
        }
    }
    &-card{
        display: flex;
        flex-direction: column;
        &__img{
            position: relative;
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &::before{
                content: '';
                display: block;
                width: 100%;
                padding-top: 75%;
            }
        }
        &__inner{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: rem(20);
            background-color: #1A2A43;
            .btn{
                margin-top: auto;
                width: 100%;
                text-transform: none;
                text-transform: uppercase;
            }
        }
        &__about{
            display: flex;
            margin-bottom: rem(24);
            time{
                color: var(--light);
                font-size: Max(rem(16), 10px);
                font-weight: 400;
                line-height: rem(20);
                text-align: center;
                display: block;
                margin-bottom: rem(12);
            }
        }
        &__date{
            text-align: center;
            color: var(--gold);
            font-size: Max(rem(16), 10px);
            font-weight: 400;
            line-height: rem(20);
            margin-bottom: rem(12);
            span{
                color: var(--light);
                margin-top: 4px;
                display: block;
            }
        }
        &__block{
            &:not(:last-child){
                margin-right: rem(15);
                padding-right: rem(20);
                border-right: 1px  dashed var(--gold);
                min-width: rem(75);
                text-align: center;
                flex-shrink: 0;
            }
        }
        &__title{
            color: var(--white);
            font-size: rem(20);
            font-weight: 400;
            line-height: rem(25);
            margin-bottom: rem(16);
        }
        &__price{
            color: var(--white);
            font-weight: 800;
        }
    }
    &__bottom{
        margin-top: rem(56);
        text-align: center;
    }
    border-bottom: 7px solid var(--gold);

    @media(max-width: 768px){
        padding: 50px 0 75px;
        &__tabs > * {
            margin: 0 5px;
            padding:  12px;
            min-width: unset;
        }
        &__tabs {
            margin: 0 -5px;
        }
        &__container{
            margin: 0;
            >*{
                width: 100%;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 25px;
                }
            }
        }
        &-card{
            &__img{
                max-height: 355px;
                overflow: hidden;
            }
            &__inner{
                padding: 15px;
            }
            &__title{
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
    }
}


/* main.css | http://localhost:3000/css/main.css */

.events-card__block:not(:last-child) {

  }

  


  