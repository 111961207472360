.slider-images{
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: var(--light-opacity);
    padding-left: rem(40);
    padding-top: rem(132);
    padding-bottom: rem(30);
    .swiper{
        width: calc(100% - rem(96));
        overflow: visible;
        margin-left: 0;
        margin-bottom: rem(168);
        .swiper-pagination{
            opacity: 0;
        }
    }
    &__inner{
        overflow: hidden;
        margin-bottom: rem(56);
    }

    &__img{
        position: relative;
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 60.3%;
        }
    }
    // &__info{
    //     display: flex;
    //     flex-direction: column;
    //     align-items: flex-start;
    //     margin-top: rem(20);
    //     >*{
    //         display: block;
    //         &:first-child{
    //             font-size: max(rem(16), 10px);
    //             font-weight: 700;
    //             line-height: rem(20);
    //             margin-bottom: rem(10);
    //         }
    //         &:last-child{
    //             font-size: max(rem(14), 10px);
    //             font-weight: 500;
    //             line-height: rem(18);
    //             color: var(--blue);
    //         }
    //     }
    // }


    @media(max-width: 768px){
        background-color: unset;
        .slider-images__img::before {
            padding-top: 60%;
        }
        &__inner{
            margin-bottom: 0;
        }
        .slider-images__img {
            max-height: 400px;
        }
        .swiper{
            width: 100%;
            overflow: hidden;
            margin-bottom: 0;
            padding-bottom: 56px;
            .swiper-pagination{
                opacity: 1;
            }
            .swiper-pagination-bullet {
                width: 6px;
                height: 6px;
                transition: width 0.3s ease-in-out;
            }
            .swiper-pagination-bullet-active {
                width: 20px;
                height: 6px;
                background: var(--blue);
            }
        }
  
    }
}