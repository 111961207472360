.tree{
    width: 100%;
    overflow-y: auto;
    padding: rem(90);
    &__list{
        &>li:first-child::after{
            content: none;
        }
        li{
            position: relative;
            padding-top: rem(30);
            &:not(:last-child){
                margin-right: rem(32);
            }
            &:not(:last-child:first-child){
                &:first-child::before {
                    content: '';
                    left: 50%;
                    right: -16px;
                    height: 1px;
                    background: #000;
                    position: absolute;
                    top: 0;
                }
                &:last-child::before {
                    content: '';
                    right: 50%;
                    left: -16px;
                    height: 1px;
                    background: #000;
                    position: absolute;
                    top: 0;
                }
                &:not(:first-child):not(:last-child)::before {
                    content: '';
                    right: -16px;
                    left: -16px;
                    height: 1px;
                    background: #000;
                    position: absolute;
                    top: 0;
                }
            }
            &::after{
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 0;
                background-color: #7B7B7B;
                width: 1px;
                height: rem(30);
    
            }
            & > .tree-item:not(:last-child) {
                margin-bottom: 30px;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: -31px;
                    width: 1px;
                    background: #7B7B7B;
                    height: 31px;
                    transform: translateX(-50%);
                  }
            }
            ul{
                display: flex;
                position: relative;
                margin-left: auto;
                margin-right: auto;
                
            }
            &:first-child:not(:last-child)>ul{
                max-width: 1457px;
                overflow-y: auto;
                width: max-content;
            }
        }
    }
    &-item{
        width: rem(154);
        border-radius: rem(20);
        padding: rem(12) 0;
        background-color: #F5F5FE;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid transparent;
        transition: border 0.3s ease-in-out;
        flex-shrink: 0;
        margin-left: auto;
        margin-right: auto;
        &:hover{
            border: 1px solid #5B66E3;
        }
        &__img{
            width: rem(40);
            height: rem(40);
            >*{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__name{
            color: var(--dark);
            font-size: Max(rem(12), 10px);
            font-weight: 500;
            line-height: rem(24);
        }
        &__price{
            margin-bottom: rem(4);
            span{
                color: var(--dark);
                font-size: 10px;
                font-weight: 400;
                line-height: rem(24);
                &:first-child{
                    color:  #5B66E3;

                }
            }
        }
        &__people{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -2px rem(-10);
            >*{
                margin: 0 2px rem(10);
            }
            .tree-item__img{
                width: rem(28);
                height: rem(28);
            }
        }
        &__link{
            display: block;
            margin-top: 4px;
            color: #5B66E3;
            font-size: 10px;
            font-weight: 400;
            line-height: 150%; /* 15px */
            text-decoration: underline !important;
            transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
            &:hover{
                text-decoration: none !important;
                color: #5B66E3;
            }
        }
    }

}


