.free-consult{
    position: relative;
    padding: rem(100) 0;
    &__bg{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: -1;
        >*{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__container{
        text-align: center;
        .section-text{
            margin-bottom: rem(56);
        }
    }

    @media(max-width: 768px){
        padding: 40px 0;
        &__container {
            text-align: left;
            .section-text {
                margin-bottom: 3.5rem;
                margin-bottom: 40px;
                
            }
            .btn{
                max-width: 400px;
                width: 100%;
            }
        }
    }
}