.promo{
    position: relative;
    margin-top:  rem(-117);
    padding-top: rem(100);
    padding-bottom: rem(144);
    min-height: clamp(rem(600), 69.44vh, 46.875rem);
    display: flex;
    align-items: center;
    overflow: hidden;    
    >  .section-arrow{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: rem(40);
    }
    &--offset{
        padding-top: rem(150);
    }
    &__container{
        display: flex;
        align-items: center;
    }
    &__bg{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(110deg, #0D1E37 15%, rgba(0, 0, 0, 0.00) 60%);
            background-color: #0D1E3799;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__info{
        max-width: rem(570);
        z-index: 2;
        position: relative;
        &.promo__info{
            &--big{
                max-width: rem(670);
            }
        }
        &:not(:last-child){
            margin-right: rem(100);
        }
    }
    &__video{
        flex-shrink: 0;
        flex-grow: 1;
        position: relative;
        width: 54.7%;
        a{
            display: flex;
            flex-direction: column;
            align-items: center;
            svg{
                width: rem(87);
                height: rem(87);
                object-fit: contain;
                margin-bottom: rem(16);
                transition: opacity 0.3s ease-in-out;
            }
            .btn{
                text-transform: none;
                border: 1px solid #C7B892;
                background-color: rgba(199, 184, 146, 0.30);
            }
            &:hover{
                svg{
                    opacity: 0.7;
                }
                .btn{
                    background-color: var(--gold);
                }
            }
        }
        // &-poster{
        //     position: relative;
        //     z-index: -1;
        //     img{
        //         position: absolute;
        //         left: 0;
        //         top: 0;
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //     }
        //     &::before{
        //         content: '';
        //         display: block;
        //         width: 100%;
        //         padding-top: 56.1%;
        //     }
        // }
    }
    &.promo-main{
        min-height: min(80.093vh, 865px);
        margin-top: -117px;
        padding-top: 177px;
        padding-bottom: 135px;
        overflow: unset;
        .promo__bg{
            background: var(--blue);
            display: flex;
            justify-content: center;

            img {
                max-width: rem(1710);
                opacity: 0.6;
            }
            &::after{
                content: none;
            }
            &::before{
                content: '';
                position: absolute;
                background-image: url("../img/gradient-bg.svg");
                background-repeat: no-repeat;
                background-position: left;
                background-size: cover;
                left: -1px;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
            }
        }
        .promo__title{
            >*{
                font-weight: 700;
            }
        }
        .section-text{
            max-width: rem(366);
            &:not(:last-child){
                margin-bottom: rem(56);
            }
        }
        .promo__info {
            padding-bottom: rem(32);
            &:not(:last-child) {
                margin-right: rem(24);
            }
        }
        .section-text p {
            font-size: max(1rem, 12px);
        }
        .section-text {
            min-height: 68px;
        }
    }
    &.promo-events{
        min-height: rem(511);
        padding-top: rem(170);
    }
    &-about{
        padding-top: rem(120);
        background-color: var(--blue);
        
        &__container{
            display: flex;
            align-items: center;
        }
        &__descr{
            max-width: rem(570);
            flex-shrink: 0;
            margin-right: rem(30);
            .section-text{
                margin-bottom: rem(56);
            }
        }
        &__info{
            padding: rem(56) rem(40);
            position: relative;
            background-color: #1A2A43;
        }
        &__bg{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            opacity: 0.5;
            >*{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__img{
            max-width: rem(430);
            position: relative;
            margin: 0 auto rem(24);
            flex-shrink: 0;
            border: 5px solid var(--blue);
            border-bottom: 5px solid #B8CEE2;
            &::after{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                pointer-events: none;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 100%);
            }
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &::before{
                content: '';
                display: block;
                width: 100%;
                padding-top: 115.6%;
            }
        }
        &__text{
            color: var(--white);
            text-align: center;
            font-size: rem(24);
            font-family: 'Crimson Pro', serif;
            font-weight: 300;
            line-height: rem(32); /* 133.333% */
            span{
                color: var(--gold);
            }
        }
    }
    &-img{
        position: relative;
        & + .what-is {
            padding-bottom: rem(144);
        }
        & + .what-is .what-is__block:first-child{
            .what-is__info{
                align-items: center;
            }
            .what-is__title{
                max-width: rem(670);
                margin-right: rem(30);
            }
        }  

        &__logo{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            width: 58.5%;
            >*{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &__image{
            border: rem(5) solid var(--blue);
            border-bottom: rem(5) solid #B8CEE2;
            position: relative;
            width: rem(500);
            flex-shrink: 0;
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &::before{
                content: '';
                display: block;
                width: 100%;
                padding-top: 104.4%;
            }
        }
        .title-h1>*{
            color: var(--blue);
        }
        .promo__info:not(:last-child){
            margin-right: rem(40);
            padding-left: rem(40);
        }
        .promo__container{
            padding: rem(80) 0;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                width: 31.7%;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: var(--blue);
            }
            &::after{
                content: '';
                position: absolute;
                width: calc(100% - 31.7%);
                left: 0;
                top: 0;
                bottom: 0;
                background-color: var(--light);
                z-index: -1;
            }
        }
        .section-text p{
            color: var(--gray);
        }
        
    }
    &-medicaid{
        &__info{
            max-width: unset;
        }
    }


    @media(max-width:768px){
        min-height: unset;
        padding-top: 130px;
        > .section-arrow {
            bottom: 20px;
        }
        .promo__container {
            flex-direction: column;
        }

        .promo__info:not(:last-child) {
            margin-right: 0;
            margin-bottom: 25px;
        }

        .promo__info.promo__info--big {
            max-width: unset;
        }
        &__video{
            width: 100%;
            margin-bottom: 30px;
            a {
                svg{
                    width: 60px;
                    height: 60px;
                    margin-bottom: 2px;
                }
                .btn{
                    padding: 8px 20px;
                    min-width: unset;
                }
            }
        }
        &.promo-main{
            padding-top: 149px;
            padding-bottom: 50px;
            min-height: unset;
            & + .section-blur{
                top: 0;
                .section-blur__container{
                    padding: 80px 0 0;;
                }
            }
            .promo__container{
                align-items: flex-start;
                flex-direction: row;
                .btn{
                    width: 100%;
                }
            }
            .promo__info{
                max-width: unset;
                width: 100%;
                &:not(:last-child) {
                    margin-right: 0;
                }
            }
            .promo__bg::before{
                background-image: url("../img/gradient-bg-mobile.svg");
            }
            .section-text {
                max-width: 53%;
                padding-left: 16px;
                border-left: 1px dashed var(--gold);
                &:not(:last-child){
                    margin-bottom: 30px;
                }
            }
        }
        &.promo-events{
            padding-top: 130px;
            min-height: unset;
        }
        &-about{
            padding-bottom: 80px;
            &__info{
                padding: 20px;
            }
            &__text{
                font-size: 16px;
                line-height: 26px;
            }
            &__container{
                flex-direction: column;
            }
            &__descr{
                max-width: unset;
                margin-right: 0;
                margin-bottom: 25px;
                .section-text{
                    margin-bottom: 20px;
                }
            }
        }
        &-img{
            padding-bottom: 60px;
            & + .what-is .what-is__block {
                margin-bottom: 20px;
                padding-bottom: 30px;
            }
            & + .what-is .what-is__block:first-child{
                .what-is__title{
                    max-width: unset;
                    margin-right: 0;
                }
            } 
            &__image{
                width: 90%;
                max-height: 400px;
                overflow: hidden;
            }
            &__logo{
                left: 50%;
                top: 15px;
                bottom: unset;
                width: 320px;
                transform: translateX(-50%);
            }
            .promo__container{
                padding: 20px 10px;
                &::before{
                    content: none;
                }
                &::after{
                    width: 100%;
                }
            }
            .promo__info:not(:last-child){
                padding-left: 0;
            }
        }
    }
}




