.menu{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    height: 100%;
    background-color: var(--blue);
    transform: translateX(-100%);
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    &.is-active{
        transform: translateX(0);
    }
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        width: 100%;
    }
    &__close{
        width: 24px;
        height: 24px;
        >*{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__inner{
        position: relative;
        padding: 24px;
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        overflow-y: auto;
        .header__nav{
            margin-left: 0;
            margin-bottom: 30px;
            width: 100%;
            &-list{
                align-items: unset;
                flex-direction: column;
                li{
                    position: relative;
                    padding: 0;
                    &:not(:last-child){
                        margin-right: 0;
                        margin-bottom: 24px;
                    }
                    &.active a{
                        color: var(--gold) !important;
                    }
                    a{
                        color: var(--white) !important;
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: normal;
                    }
                    &.has-child{
                        position: relative;
                        // margin-bottom: 0;
                        &>a{
                            padding-right: 0 !important;
                            display: flex;
                            align-items: center;
                            width: max-content;
                            max-width: 100%;
                            &::after{
                                position: static;
                                transform: none;
                                content: '';
                                display: block;
                                width: 15px;
                                height: 15px;
                                flex-shrink: 0;
                                margin-left: 6px;
                                background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 7L7.5 10L10.5 7' stroke='white' stroke-linecap='square'/%3E%3C/svg%3E%0A");
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-position: center;
                                pointer-events: none;
                                transition: transform 0.3s ease-in-out;
                            }
                        }
                        ul{
                            padding-top: 16px;
                            padding-left: 16px;
                            min-width: unset;
                            border-radius: 0;
                            text-align: left;
                            display: none;
                            transition: unset;
                            padding-bottom: 0;
                            width: 100%;
                            li{
                                &:not(:last-child){
                                    margin-bottom: 16px;
                                    padding-bottom: 16px;
                                    &::after{
                                        display: none;
                                    }
                                    &::before{
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        bottom: 0;
                                        width: 60px;
                                        border-bottom: 1px dashed var(--gold);
                                    }
                                }
                                padding-bottom: 0;
                                line-height: 0;
                                &.active a{
                                    color: var(--gold) !important;
                                }
                                a{
                                    padding: 0;
                                    font-size: 14px ;
                                    font-weight: 400 ;
                                    text-transform: unset;
                                }
                                &:last-child::after{
                                    display: none !important;
                                }
                            }
                        }
                        &.is-open{
                            &>a::after{
                                transform: rotate(180deg)!important;
                            }
                            &>*:not(ul){
                                color: #2755F3;
                                color: var(--gold) !important;
                            }
                        }
                    } 
                }
            }
        }
    }
    .header__btns{
        margin-left: 0;
        margin-top: auto;
        flex-direction: column;
        >*{
            &:not(:last-child){
                margin-bottom: 20px;
            }
        }
    }

}









  

  
 
