.about-us{
    padding-bottom: rem(144);
    padding-top: rem(50);
    position: relative;
    overflow: hidden;
    &__container{
        display: flex;
        min-height: min(81.482vh, rem(880));
        border-bottom: rem(7) solid #B8CEE2;
        >*{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    &__inner{
        display: flex;
        flex-direction: column;
        width: 37.7%;
        flex-shrink: 0;
        background-color: var(--blue);
        padding: rem(50) rem(30);
        align-items: flex-start;
        padding: rem(45) 1.875rem rem(39);
        position: relative;
        border-top: rem(5) solid var(--gold);
        &-bg{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            opacity: 0.5;
            >*{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: left;
            }
        }
    }
    .section-text{
        margin-bottom: rem(58);
    }

    @media(max-width: 768px){
        padding: 40px 0;
        background-color: var(--light);
        .logo-bg{
            top: 62%;
            width: 100%;
            height: 50%;
        }
        .slider-images{
            padding: 0;
        }
        .title-h1>*{
            color: var(--blue);
        }
        &__container{
            min-height: unset;
            border-bottom: unset;
        }
        &__info{
            margin-top: 24px;
            .section-text.section-text--border{
                padding-left: 16px;
            }
            .section-text{
                margin-bottom: 40px;
                p, li{
                    color: var(--gray);
                }
                li::after{
                    background-color: var(--gray);
                }
            }
        }
    }
}






  



  
  
  
