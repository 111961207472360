@import "fonts";

@function rem($size) {
  $remSize: $size / $base-value;

  @return #{$remSize}rem;
}


// $header-height: 93px;
$base-value: 16;

::-moz-selection{
  background-color: var(--gold);
  color: var(--white);
}

::selection{
  background-color: var(--gold);
  color: var(--white);
}

// font-family: 'Manrope', sans-serif;


html {
  box-sizing: border-box;
  // scroll-padding-top: $header-height;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: rem(1200);
  padding: 0 15px;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: Max(rem(16), 12px);
  font-family: 'Geologica', sans-serif;
  font-weight: 400;
  color: var(--dark);
  line-height: 150%;
  background-color: #E5E5E5;
  transition: background-color 0.3s ease-in-out;
  will-change: background-color;
  &.menu-open{
    overflow: hidden;
    // .header{
    //   background-color: #fff;
    // }
  }
}

.title-h1 {
  &:not(:last-child){
    margin-bottom: rem(40);
  }
  >*{
    color: var(--white);
    font-size: rem(60);
    font-family: 'Cormorant Garamond', serif;
    font-weight: 700;
    line-height: rem(80);
    text-transform: uppercase;
  }
  span{
    color: var(--gold);
  }  
}
.font-family{
  font-family: 'Geologica', sans-serif;
  font-weight: 300;
}
// .title-h2 {
//   margin-bottom: rem(40);
//   >*{
//     color: var(--blue);
//     font-size: rem(54);
//     font-family: 'Cormorant Garamond', serif;
//     font-weight: 500;
//     line-height: 150%;
//     text-transform: capitalize;
//   }
// }

.title-h3 {
  margin-bottom: rem(12);
  >*{
    color: var(--white);
    font-size: rem(36);
    font-weight: 400;
    line-height: rem(48);
    text-transform: uppercase;
  }
}

.title-h4{
  margin-bottom: rem(24);
  >*{
    color: var(--white);
    font-size: rem(32);
    font-weight: 400;
    line-height: rem(28);
  }
}

.btn{
  min-width: rem(155);
  display: inline-flex;
  outline: 0;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: var(--gold);
  color: var(--white);
  font-size: Max(rem(14), 10px);
  line-height: rem(18);
  padding: rem(12) rem(24);
  text-transform: uppercase;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out; 
  cursor: pointer;
  &:hover{
    background-color: var(--brown);
    color: var(--white);
  }
  &.btn--fill{
    background-color: transparent;
    border: 1px solid var(--gold);
    color: var(--gold);
    &:hover{
      color: var(--white);
      background-color: var(--gold);
    }

  }
  &:disabled, &.disabled{
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.logo{
  height: rem(51);
  width: rem(170);
  display: flex;
  position: relative;
  flex-shrink: 0;
  >*{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }


}

.logo-bg{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 46.4%;
  height: 69.4%;
  >*{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.input{
  position: relative;
  display: flex;
  flex-direction: column;
  input,textarea{
    font-size: Max(rem(14), 12px);
    border-radius: rem(8);
    width: 100%;
    height: 100%;
    outline: none;
    padding: rem(12);
    color: var(--light);
    font-weight: 400;
    line-height: rem(24);
    border: 1px solid var(--gold);
    background-color: #1A2A43;
    &::placeholder{
      color: var(--light) !important;
      opacity: 1;
    }
  }
  &--select{
    position: relative;
    display: flex;
    >label{
      cursor: pointer;
      position: relative;
      
    }
    &.is-open{
      &:first-child{
        border-bottom-left-radius: 0;
      }
      .input__inner::after{
        transform: rotate(180deg) translateY(50%);
      }
      .input__dropdown{
        box-shadow: 0px 1px 23px rgba(30, 33, 36, 0.13);  
      }
    }
    &:not(.has-checkbox){
      .input__dropdown{
        li{
          &::after{
            content: '';
            display: block;
            width: rem(24);
            height: rem(24);
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"%3E%3Cpath d="M6 12L10.2426 16.2426L18.7275 7.75732" stroke="%23C7B892" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');background-size: contain;background-repeat: no-repeat;background-position: center;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
          }
          &:not(.is-selected):hover{
            background-color: var(--gold);
          }
          &.is-selected{
            color: var(--gold);
            cursor: default;
            &::after{
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &__inner{
    position: relative;
    cursor: pointer;
    >*{
      cursor: pointer;
    }
    &::after{
      content: '';
      position: absolute;
      right: rem(12);
      top: 50%;
      transform: translateY(-50%);
      width: rem(25);
      height: rem(25);
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 8L7.5 5L10.5 8' stroke='white' stroke-linecap='square'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: transform 0.3s ease-in-out;
      pointer-events: none;
      z-index: 1;
    }
  }
  &__dropdown{
    display: none;
    position: absolute;
    left: 0;
    bottom: rem(-10);
    border-radius: rem(8);
    padding: rem(8) 0;
    right: 0;
    z-index: 9;
    transform: translateY(100%);
    text-align: left;
    max-height: rem(200);
    overflow-y: auto;
    border: 1px solid var(--gold);
    background-color: #1A2A43;
    cursor: pointer;
    li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: rem(8) rem(16) rem(8) rem(24);
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
      font-size: Max(rem(14), 10px);
      background-color: #1A2A43;

    }
  }
  &__error{
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(-2);
    transform: translateY(100%);
    font-size: Max(rem(12),10px);
    line-height: rem(14);
    color: red;
  }
  label{
    display: block;
    font-size: Max(rem(14), 12px);
    font-weight: 400;
    line-height: rem(24);
    margin-bottom: 2px;
  }
  .output_text{
    padding-right: rem(37);
  }
  &.is-error{
    &:not(:last-child){
      margin-bottom: rem(35) !important;
    }
    input, textarea{
      border-color: red;
    }
  }
  &.input--checkbox{
    width: 100%;
    input {
      position: absolute;
      width: 1px;
      height: 1px;
      opacity: 0;
      &:checked + span::before {
        opacity: 1;
      }
      &:checked + span::after {
        opacity: 0;
      }
    }
    span{
      display: block;
      &::after,&::before{
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: rem(24);
        height: rem(24);
        transition: opacity 0.3s ease-in-out;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      &::after {
        background-image: url(./../img/checkbox_unchecked.svg);
      }
      &::before{
        background-image: url(./../img/checkbox_check.svg);
        opacity: 0;
      }
    }

  } 
}

textarea{
  width: 100% !important;
}


.socials{
  display: flex;
  align-items: center;
  &__title{
    margin-bottom: rem(24);
    display: block;
  }
  li{
    line-height: 0;
    &:not(:last-child){
      margin-right: rem(12);
    }
    a{
      display: inline-block;
      width: rem(24);
      height: rem(24);
      transition: transform 0.3s ease-in-out;
      >*{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:hover{
        transform: scale(1.1);
      }
    }
  }
}

.burger{
  display: block;
  width: 28px;
  height: 28px;
  >*{
    width: 100%;
    height: 100%;
    object-fit: contain;
    path{ 
      stroke: var(--white);
    }
  }
}

.scroll-appearance{
  &.animate-opacity{
    opacity: 0;
    transition: opacity 1.8s ease-in-out;
    &.scroll-appearance-shown{
      opacity: 1;
    }
  }
  &.animate-top{
    transform: translateY(-90%);
    opacity: 0;
    transition: opacity 1.8s ease-in-out, transform 0.9s ease-in-out;
    &.scroll-appearance-shown{
      opacity: 1;
      transform: translateY(0);
    }
  }
  &.animate-bottom{
    transform: translateY(90%);
    opacity: 0;
    transition: opacity 1.8s ease-in-out, transform 0.9s ease-in-out;
    &.scroll-appearance-shown{
      opacity: 1;
      transform: translateY(0);
    }
  }
  &.animate-left{
    transform: translateX(-90%);
    opacity: 0;
    transition: opacity 1.8s ease-in-out, transform 0.8s ease-in-out;
    &.scroll-appearance-shown{
      opacity: 1;
      transform: translateX(0);
    }
  }
  &.animate-right{
    transform: translateX(90%);
    opacity: 0;
    transition: opacity 1.8s ease-in-out, transform 0.8s ease-in-out;
    &.scroll-appearance-shown{
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.fancybox__container {
  --fancybox-bg: rgba(0, 0, 0, 0.8);
}

.btn-hover{
  position: absolute;
  border-radius: 50%;
  transition: transform 1s;
  transform: scale(0);
  background-color: var(--white-bg);
}

.tag{
  position: absolute;
  left: rem(10);
  top: rem(10);
  min-width: unset;
  z-index: 1;
  color: var(--white);
  font-size: Max(rem(16), 10px);
  font-style: normal;
  font-weight: 800;
  pointer-events: none; 

}

.nav-links{
  margin: rem(40) auto 0;
  padding-top: rem(40);
  border-top: 1px solid var(--light);
  max-width: rem(370);
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-numbers{
  color: var(--light);
  font-size: Max(rem(14), 10px);
  font-weight: 400;
  line-height: rem(24);
  transition: color 0.3s ease-in-out;
  &[href]:hover{
    color: var(--gold);
  }
  &.current{
    color: var(--gold) !important;
  }
  &.next{
    display: flex;
    svg{
      width: rem(24);
      height: rem(24);
      object-fit: contain;
      path{
        transition: stroke 0.3s ease-in-out;
      }
      &:hover{
        path{
          stroke: var(--gold);
        }
      }
    }
  }
  &:not(:last-child){
    margin-right: rem(10);
  } 
}

.tooltip{
  position: relative;
  z-index: 1;
  width: rem(20);
  height: rem(20);
  &:hover{
    span {
      opacity: 1;
      visibility: visible;
    }
  }
  span {
    z-index: 10;
    max-width: rem(250);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    color: #CDD3DB;
    text-align: center;
    font-size: max(rem(14), 12px);
    font-weight: 400;
    line-height: rem(21);
    padding: rem(12);
    display: block;
    border: 2px solid var(--gold);
    background-color: var(--blue);
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    right: 0;
    top: 0;
    z-index: 1;
    transform: translate(100%, -100%);
    width: max-content;
    text-transform: none;
  }
  svg{
    margin-left: rem(8);
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media(max-width: 768px){
    span{
      max-width: 177px;
    }
  }
}

.popup{
  padding: 0;
  width: 50%;
  &__inner{
    background-color: #13243D;
    padding: rem(40) rem(56);
    color: var(--white);
  }
  &__title{
    font-size: rem(20);
    line-height: rem(30);
    &:not(:last-child){
      margin-bottom: rem(20);
    }
  }
  @media(max-width: 768px){
    width: 100%;
    &__inner{
      padding: 24px 12px;
    }
    &__title{
      font-size: 17px;
      line-height: 28px;
    }
  }
}
.mobile-hide{
  @media (max-width: 768px){
    display: none !important;
  }
}
.mobile-show{
  @media (min-width: 769px){
    display: none !important;
  }
}




@media(max-width: 768px){
  .title-h1{
    &:not(:last-child){
      margin-bottom: 24px;
    }
    >*{
      font-size: 40px;
      line-height: 48px;
    }
  }
  .title-h2{
    margin-bottom: 15px;
    > * {
      font-size: 30px;
      line-height: 48px;
    }
  }
  .title-h3 >*{
    font-size: 24px;
    line-height: 35px;
  }
  .title-h4 >*{
    font-size: 20px;
    line-height: 24px;
  }  
  .socials{
    justify-content: center;
  }

  .mobile-phone {
    color: #c9cfd7 !important;
  }
  .mobile-phone:link {
    color: #c9cfd7 !important;
  }

}


@media (max-width: 1280px){
  html{
    font-size: 14px;
  }
}
@media (max-width: 1080px){
  html{
    font-size: 13px;
  }
}
@media (max-width: 991px){
  html{
    font-size: 10px;
  }
}
@media (max-width: 768px){
  html{
    font-size: 16px;
  }
}













*,
*::before,
*::after {
  box-sizing: inherit;
}

img, svg {
	max-width: 100%;
	height: auto;
  object-fit: cover;
}


/* Reset and base styles  */
* {
	padding: 0px;
	margin: 0px;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}



/* Links */

a, a:link, a:visited  {
    // color: inherit;
    text-decoration: none;
    // display: inline-block;
}


a:hover  {
    color: inherit;
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
	display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
	font-weight: inherit;
}

address {
  font-style: normal;
}

ul {
    list-style: none;
}

/* Form */

input, textarea, button, select {
	font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}





// @media(max-width: 768px){
//   .page__body{
//     font-size: 16px ;
//   }
//   .main__title >*{
//     font-size: 40px;
//     line-height: 52px;
//   }
//   .title-1 >*{
//     font-size: 35px;
//     line-height: 47px;
//   }
//   .title-2 >*{
//     font-size: 30px;
//     line-height: 40px;
//   }
//   .title-3 >*{
//     font-size: 26px;
//     line-height: 38px;
//   }
//   .title-4 >*{
//     font-size: 22px;
//     line-height: 34px;
//   }

// }