.trust{
    background-color: var(--blue);
    padding: rem(144) 0;
    overflow: hidden;
    &.trust--color{
        background-color: transparent;
        & + .planning{
            padding-top: 0;
        }
    }
    &-card__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px rem(-90);
        >*{
            width: calc(100% / 2 - 30px);
            margin: 0 15px rem(90);
        }
    }
    &-card{
        border: rem(5) solid var(--gold);
        background-color: #1A2A43;
        position: relative;
        counter-increment: number;
        &::after{
            content: '';
            position: absolute;
            left: 50%;
            top: rem(-56);
            transform: translateX(-50%);
            width: rem(112);
            height: rem(112);
            border: rem(5) solid var(--gold);
            border-radius: 50%;
            background-color: #1A2A43;
            padding-bottom: rem(18);
            content: counter(number, decimal-leading-zero);
            color: var(--gold);
            font-family: 'Cormorant Garamond', serif;
            font-size: rem(60);
            font-weight: 500;
            line-height: rem(24);
            display: flex;
            align-items: center;
            justify-content: center; 
        }
        &__info{
            padding: rem(40);
        }
        &__title{
            margin-bottom: rem(24);
            color: var(--white);
            font-size: rem(20);
            font-weight: 400;
            line-height: rem(25);
        }
        &__img{
            position: relative;
            overflow: hidden;
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &::before{
                content: '';
                padding-top: 68.4%;
                display: block;
                width: 100%;
            }
            &::after{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.25);
            }
        }
        &__btns{
            display: flex;
            flex-wrap: wrap;
            margin: rem(56) -10px rem(-10);
            >*{
                margin: 0 10px rem(10);
            }
        }
    }
    &-answer{
        overflow: hidden;
        position: relative;
        background-color: var(--blue);
        margin: rem(144) 0;
        padding: rem(144) 0;
        z-index: 1;
        &__bg{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 0.2;
            z-index: -1;

            >*{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .section-head {
            &:nth-child(even){
                display: flex;
                flex-direction: row-reverse;
                .title-h1{
                    margin-right: 0;
                    margin-left: rem(30);
                }
            }
            &:not(:last-child){
                margin-bottom: rem(144);
            }
            .title-h1{
                max-width: rem(570);
            }
        }
        &.trust-answer--offset{
            margin: 0;
        }
    }
    &-parties{
        padding-bottom: rem(144);
        overflow: hidden;
        &__title{
            text-align: center;
            >*{
                color: var(--blue);
            }
        }
        &__container{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
            >*{
                width: calc(100% / 3 - 30px);
                margin: 0 15px;
            }
        }
        &__card{
            background-color: #C9D2DB;
            padding: rem(60) rem(40);
        }
    }
    &__promo{
        display: flex;
        align-items: flex-start;
        &.trust__promo--color{
            align-items: unset;
            .trust__descr{
                padding: rem(80) rem(40) ;
                background-color: #C9D2DB;
                .title-h1 >*{
                    color: var(--blue);
                }
                .section-text p{
                    color: var(--gray);
                }
            }
            .trust__info{
                margin-left: 0;
            }
        }
        &.trust__promo--offset{
            .trust__descr{
                padding: rem(56) rem(30) ;
            }
            .trust__info{
                display: flex;
                flex-direction: column;
            }
            .trust__img{
                flex-grow: 1;
            }
            & + .trust-card__container{
                margin-top: rem(144);
            }
        }
    }
    &__info{
        max-width: rem(570);
        flex-shrink: 0;
        margin-left: rem(30);
        position: relative;
        background-color: #1A2A43;
        padding: rem(56) rem(40);
        text-align: center;
        &-text{
            color: var(--white);
            font-size: rem(24);
            line-height: rem(32);
            font-family: 'Crimson Pro', serif;
            font-weight: 300;
            >*{
                &:not(:last-child){
                    margin-bottom: rem(24);
                }
            }
            span{
                color: var(--gold);
            }
        }
    }
    &__img{
        position: relative;
        max-width: rem(430);
        width: 100%;
        flex-shrink: 0;
        overflow: hidden;
        margin: 0 auto rem(24);
        border: rem(5) solid #0D1E37;
        border-bottom: rem(5) solid #B8CEE2;
        overflow: hidden;
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-top: 86%;
        }
        &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(#000, 0.42);
            pointer-events: none;
        }
        &.trust__img--big::before{
            padding-top: 107%;
        }
    }
    &__descr{
        .btn{
            margin-top: rem(56);
        }
    }
    &__box{
        width: rem(437);
        padding: rem(64) rem(62) rem(64) rem(40);
        border: rem(5) solid #0D1E37;
        background: rgba(255, 255, 255, 0.80);
        border-bottom: rem(5) solid #B8CEE2;
        flex-shrink: 0;
        z-index: 1;
    }
    &-main{
        position: relative;
        display: flex;
        align-items: flex-start;
        background-color: #C9D2DB;
        padding-top: rem(80);
        padding-left: rem(30);
        &.trust-main--reverse{
            flex-direction: row-reverse;
            padding-left: 0;
            padding-right: rem(30);
            .trust-main__block{
                margin-right: 0;
                margin-left: rem(30);
            }
            .trust-main__bg{
                left: 0;
                right: unset;
            }
            .trust__box{
                padding: rem(45) rem(40);
            }
        }
        &__list{
            display: flex;
            padding-left: rem(54);
            margin-top: rem(-80);
            margin-bottom: rem(144);
            .category-card:not(:last-child){
                margin-bottom: 0;
            }
            .category-card__title{
                padding-left: 0;
                &::after{
                    content: none;
                }
            }
            >*{
                max-width: rem(293);
                width: 100%;
                &:not(:last-child){
                    margin-right: rem(30);
                }
            }
        }
        &__bg{
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: rem(370);
            &::after{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                pointer-events: none;
                background-color: rgba(#000, 0.40);
            }
            >*{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__block{
            max-width: rem(635);
            margin-right: rem(35);
            padding-bottom: rem(120);
            .btn{
                margin-top: rem(30);
            }
        }
        .title-h1 >*{
            color: var(--blue);
        }
        .section-text:not(.category-card .section-text) p{
            color: var(--gray);
        }
    }
    &-category{
        margin-bottom: rem(144);
        &__card{
            border: rem(5) solid var(--gold);
            background-color: #1A2A43;
            &-inner{
                display: flex;
                align-items: flex-start;
            }
            &:not(:last-child){
                margin-bottom: rem(30);
            }
            &-info{
                padding: rem(40) 0;
            }
            &-img{
                position: relative;
                margin-right: rem(30);
                flex-shrink: 0;
                width: rem(464);
                img{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &::before{
                    content: '';
                    display: block;
                    width: 100%;
                    padding-top: 83%;
                }
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.25);
                }
            }
            &-title{
                color: var(--white);
                font-size: rem(20);
                font-weight: 400;
                line-height: rem(25);
                margin-bottom: rem(24);
            }
            .section-text-hide{
                padding: rem(24)  rem(40);
                .section-link__close{
                    margin: 0 auto;
                }
                &>*{
                    color: #CDD3DB;
                    font-size: Max(rem(14), 10px);
                    font-weight: 400;
                    line-height: rem(24);
                    &:not(:last-child){
                        margin-bottom: rem(30);
                    }
                }
            }
            .section-text{
                margin-bottom: rem(44);
            }
        }
        .section-head .title-h1{
            max-width: rem(570);
        }
    }
    &-law{
        margin-bottom: rem(144);
        .trust__info {
            max-width: unset;
            margin-left: 0;
        }
        .trust__img {
            max-width: unset;
            &::before {
                padding-top: 34.2%;
            }
        }  
    }
    &-tax{
        .title-h1{
            text-align: center;
        }
        .trust__info {
            max-width: unset;
            margin-left: 0;
            .section-text{
                text-align: left;
            }
        }
        .trust__img {
            max-width: unset;
            &::before {
                padding-top: 34.2%;
            }
        }  
    }
    &-overview{
        &__container{
            display: flex;
            align-items: center;
            margin-top: rem(-70);
        }
        &__info{
            .section-text{
                margin-bottom: rem(56);
            }
        }
        .what-is__about{
            padding-top: rem(80);
            padding-bottom: rem(150);
            .section-text-wrap{
                max-width: rem(660);
                margin: 0 auto;
            }
        }
    }
    &-planning{
        .planning__img{
            position: relative;
            &::before{
                padding-top: 149%;
            }
        }
        .planning__info {
            width: 50.8%;
        }
        .planning-list{
            padding: rem(30) rem(20) rem(30) rem(30);
            margin-bottom: rem(-165);
            max-width: rem(480);
            .section-text{
                padding-left: 0;
                p{
                    color: #CDD3DB;
                }
            }
        }
    }
    &-summary{
        padding-top: rem(144);
        background-color: var(--blue);
        &.trust-summary--similiar{
            margin-bottom: rem(144);
            .planning-list{
                margin-bottom: rem(-180);
            }
        }
        .planning-list{
            background-color: #1A2A43;
            margin-bottom: rem(-90);
            margin-left: 0;
            width: 100%;
        }
    }
    &-nexxess{
        overflow: unset;
        padding: 0;
        padding-bottom: 70px;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            background-color: var(--light);
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            height: 212px;
        }
        &__container{
            display: flex;
            padding: rem(40) rem(24);
            background-color: var(--white);
            position: relative;
            z-index: 1;
            >*{
                flex-basis: 0;
                flex-grow: 1;
            }
            .card{
                height: auto;
                border-radius: 0;
                padding: rem(50) rem(24) rem(15) rem(16);
                counter-increment: number;
                &:not(:last-child){
                    border-right: 1px dashed var(--gold);
                }
                &::after{
                    left: rem(16);
                    content: counter(number, decimal-leading-zero);;
                }
            }
        }
        .what-is__about{
            padding: rem(80) 0;
            .section-text-wrap{
                max-width: rem(712);
                margin: 0 auto;
            }
        }
    }
    &-content{
        display: flex;
        .section-text p{
            color: var(--gray);
        }
        .title-h1>*{
            color: var(--blue);
        }
        &__block{
            margin-right: rem(30);
            flex-shrink: 0;
            max-width: rem(470);
            &.trust-content__block-bg{
                margin-right: rem(22);
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    width: rem(356);
                    height: rem(464);
                    background-color: var(--blue);
                    pointer-events: none;
                    z-index: -1;
                }
                .trust-content__img{
                    margin-top: rem(43);
                    margin-bottom: rem(75);
                    margin-left: rem(24);
                    width: unset;
                    border-left: 5px solid var(--gold);
                    &::before{
                        content: '';
                        display: block;
                        width: 100%;
                        padding-top: 83.4%;
                    }
                }
            }
            .section-text > :not(:first-child){
                padding-top: rem(24);
                margin-top: rem(24);
                border-top: 1px dashed var(--gold);
            }
        }
        &__img{
            width: 100%;
            position: relative;
            border: rem(5) solid var(--blue);
            margin-bottom: rem(24);
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &::after{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(#000, 0.4);
            }
            &::before{
                content: '';
                display: block;
                width: 100%;
                padding-top: 68%;
            }
        }
        &__descr{
            position: relative;
        }
        &__logo{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            >*{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    &-spendthrift{
        .trust__promo{
            align-items: unset;
        }
        .trust__img::before{
            padding-top: 101%;
        }
    }
    &-legalities{
        overflow: visible;
        margin-bottom: rem(400);

        .trust-main {
            margin-bottom: rem(-536);
            margin-top: rem(128);
        }

    }

    @media(max-width: 768px){
        padding: 50px 0;
        &-card__container {
            margin: 0;
            flex-direction: column;
            >*{
                width: 100%;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 45px;
                }
            }
        }
        &-card{
            border-width: 4px;
            &::after{
                top: -35px;
                width: 60px;
                height: 60px;
                padding-bottom: 10px;
                font-size: 35px;
                border-width: 4px;
            }
            &__img{
                max-height: 350px;
                overflow: hidden;
            }
            &__info{
                padding: 20px;
            }
            &__btns{
                margin-top: 20px;
            }
        }
        &-answer{
            margin: 70px 0;
            padding: 60px 0;
            .section-head {
                &:nth-child(even){
                    flex-direction: column;
                    .title-h1{
                        margin-right: 0;
                        margin-left: 20px;
                    }
                }
                &:not(:last-child){
                    margin-bottom: 40px;
                }
                .title-h1{
                    max-width: unset;
                }
            }
        }
        &-parties{
            padding-bottom: 60px;
            &__container{
                display: flex;
                margin: 0;
                >*{
                    width: 100%;
                    margin: 0;
                    &:not(:last-child){
                        margin-bottom: 30px;
                    }
                }
            }
        }
        &__promo{
            flex-direction: column;
            &.trust__promo--color{
                .trust__descr{
                    padding: 25px 16px 40px;
                }
            }
            &.trust__promo--offset + .trust-card__container{
                margin-top: 100px;
            }
        }
        &__descr .btn {
            margin-top: 20px;
        }
        &__info {
            max-width: unset;
            margin-left: 0;
            margin-top: 30px;
            padding: 20px;
            &-text{
                font-size: 17px;
                line-height: 25px;
                >*{
                    &:not(:last-child){
                        margin-bottom: 16px;
                    }
                }
            }
        }
        &-main{
            flex-direction: column;
            padding: 25px 16px 40px;
            &__block{
                max-width: unset;
                margin-right: 0;
                padding-bottom: 35px;
            }
            &__list{
                padding-left: 0;
                margin-top: 25px;
                margin-bottom: 40px;
                flex-direction: column;
                >*{
                    width: 100%;
                    max-width: unset;
                }
                & > :not(:last-child) {
                    margin-right: 0;
                }
                .category-card:not(:last-child){
                    margin-bottom: 20px;
                }
            }
            &.trust-main--reverse{
                flex-direction: column;
                padding: 25px 16px 40px;
                .trust__box{
                    padding: 15px;
                    border-width: 3px;
                }
                .trust-main__block{
                    margin-left: 0;
                }
            }
        }
        &__box{
            width: 100%;
            padding: 25px;
        }
        &-category{
            margin-bottom: 80px;
            &__card {
                border-width: 3px;
                &-inner{
                    flex-direction: column;
                }
                &-img {
                    margin-right: 0;
                    width: 100%;
                    max-height: 400px;
                    overflow: hidden;
                }
                &-info {
                    padding: 20px 10px;
                    .section-text-hide{
                        &>*{
                            &:not(:last-child){
                                margin-bottom: 16px;
                            }
                        }
                    }
                }
                &-title {
                    margin-bottom: 14px;
                }
                .section-text-hide{
                    padding: 0 16px 25px;
                }
                .section-text {
                    margin-bottom: 20px;
                }
            }
  
        }
        &-law{
            margin-bottom: 80px;
            .trust__img {
                max-width: unset;
                &::before {
                    padding-top: 68.2%;
                }
            } 
        }
        &-tax{
            .trust__img {
                max-width: unset;
                &::before {
                    padding-top: 68.2%;
                }
            }
            .title-h1{
                text-align: left;
                >*{
                    font-size: 30px;
                    line-height: 40px;
                }
            } 
        }
        &-overview{
            &__container{
                margin-top: -30px;
                flex-direction: column;
                .category__wrapper {
                    max-width: unset;
                    margin-right: 0;
                    margin-bottom: 25px;
                    width: 90%;
                }
            }
            &__info{
                .section-text{
                    margin-bottom: 25px;
                }
            }
            .what-is__about{
                padding-top: 25px;
                padding-bottom: 60px;
                padding-left: 20px;
                padding-right: 20px;
                .section-text-wrap{
                    max-width: unset;
                }
            }
        }
        &-planning{
            .planning__info{
                width: 100%;

            }
            .planning-list{
                padding: 20px;
                margin-bottom: 0;
                max-width: 100%;
                .section-text{
                    border-left: 0;
                }
            }
        }
        &-summary{
            padding: 50px 0;
            .planning-list {
                margin-bottom: 0;
            }
            &.trust-summary--similiar{
                margin-bottom: rem(120);
            }
  
        }
        &-nexxess{
            padding-bottom: 0;
            &::after{
                content: none;
            }
            &__container{
                padding: 0;
                background-color: unset;
                flex-direction: column;
            }
            .card{
                border-radius: 5px;
                width: 100%;
                max-width: unset;
                &:not(:last-child){
                    border-right: unset;
                    margin-bottom: 20px;
                }
            }
            .what-is__about{
                padding: 0;
            }
        }
        &-content{
            flex-direction: column-reverse;
            &__block {
                margin-right: 0;
                max-width: unset;
                margin-bottom: 20px;
                &.trust-content__block-bg{
                    margin-right: 0;
                    margin-top: 20px;
                    &::after{
                        content: none;
                    }
                    .trust-content__img{
                        margin-top: 0;
                        margin-bottom: 24px;
                        margin-left: 0;
                        &::before{
                            content: '';
                            display: block;
                            width: 100%;
                            padding-top: 68%;
                        }
                    }
                }
            }
        }
        &.trust--color{
            & + .planning{
                padding-top: 50px;
            }
        }
        &-legalities{
            margin-bottom: 400px;
    
            .trust-main {
                margin-bottom: -536px;
                margin-top: 35px;
            }
    
        }
    }
}



@media(max-width: 768px){
    #trust-legalities{
        padding-bottom: 0;
    }
}

  



